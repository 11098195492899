import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnotationViewPromissoryNote, ViewPromissoryNoteRequest } from '../models/view-promissory-note.model';
import { StorageService } from './storage.service';
import { FormStudyJsonModel, SPep, StrJArray, TransaccionesExtranjeria } from '../models/form-study.model';
import { HelperService } from '../utils/helper.service';
import { CityDepartmentModel, SelectsResponse } from '../models/selects.model';
import { SubjectPromissoryService } from './subject.service';
import { GetInfoResponse, GetInfoResponseModel } from '../models/get-info.model';
import { CurrencyPipe } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class PromissoryNotePreviewService {
  public viewPromissoryNoteRequest: ViewPromissoryNoteRequest = new ViewPromissoryNoteRequest();
  private storage: StorageService = new StorageService(true);
  public selectsResponse: SelectsResponse = new SelectsResponse();
  public infoAfiliate: GetInfoResponse = new GetInfoResponse();

  constructor(
    private helper: HelperService,
    private subjectService: SubjectPromissoryService,
    private currencyPipe: CurrencyPipe
  ) {
    this.subjectService.citiesResponse$.subscribe(this.handleGetCitiesSubs.bind(this))
    this.subjectService.afiliateInfoResponse$.subscribe(this.handleGetAfiliateInfo.bind(this))
  }

  handleGetCitiesSubs(response: SelectsResponse) {
    this.selectsResponse = response;
  }

  handleGetAfiliateInfo(response: GetInfoResponse) {
    this.infoAfiliate = response;
  }

  findCityAndDeparmentById(id: string): string[] {
    const cities: CityDepartmentModel[] = this.selectsResponse["CIUDAD-DEPARTAMENTO"] || [];
    const selectedCity: CityDepartmentModel = cities.find((city: CityDepartmentModel) => city && city.ID && city.ID === Number(id));
    if (!selectedCity) return ['', ''];

    const splitedValue: string[] = selectedCity.LABEL.replace(')', '').split('(');
    return [splitedValue[0] || '', splitedValue[1] || ''];
  }

  saveDataRequest(dataRequest: FormStudyJsonModel): void {
    console.log('Almacenando data de los formularios...', dataRequest);
    this.storage.setStorage('preDataAdapater', dataRequest);
  }

  transformDataToRequest(dataRequest: FormStudyJsonModel): ViewPromissoryNoteRequest {
    const initialSection: AnotationViewPromissoryNote[] = this.buildSectionInitial(dataRequest);
    const basicInformationSection: AnotationViewPromissoryNote[] = this.buildSectionBasicInformation(dataRequest);
    const buyerInformationSection: AnotationViewPromissoryNote[] = this.buildSectionBuyerInformation(dataRequest);
    const legalRepresentativeSection: AnotationViewPromissoryNote[] = this.buildSectionLegalRepresentative(dataRequest);
    const commercialReferenceSection: AnotationViewPromissoryNote[] = this.buildSectionCommercialReference(dataRequest);
    // const partnersSection: AnotationViewPromissoryNote[] = this.buildSectionPartners(dataRequest);
    // const foreignOperationsSection: AnotationViewPromissoryNote[] = this.buildSectionForeignOperations(dataRequest);
    // const foreignProductsSection: AnotationViewPromissoryNote[] = this.buildSectionForeignProducts(dataRequest);
    // const legalPepSection: AnotationViewPromissoryNote[] = this.buildSectionLegalPep(dataRequest);
    // const pepsSection: AnotationViewPromissoryNote[] = this.buildSectionPepLinks(dataRequest);
    // const asSignatorySection: AnotationViewPromissoryNote[] = this.buildSectionAsSignatory(dataRequest);
    const signatorySection: AnotationViewPromissoryNote[] = this.buildSectionSignature(dataRequest);

    const request: ViewPromissoryNoteRequest = {
      idProcesoClienteExterno: '-1', // Este valor se actualiza durante el flujo del servicio startprocess
      plantillas: [
        {
          idTipoDocumento: 2,
          serialPlantilla: environment.idTemplateFAU,
          anotaciones: [
            //  SECCIÓN INICIAL
            ...initialSection,

            // INFORMACIÓN BÁSICA DE LA SOLICITUD
            ...basicInformationSection,

            // INFORMACION DEL COMPRADOR
            ...buyerInformationSection,

            // INFORMACIÓN REPRESENTANTE LEGAL
            ...legalRepresentativeSection,

            // REFERENCIA COMERCIAL 
            ...commercialReferenceSection,

            // SOCIOS
            // ...partnersSection,

            // MANIFIESTO & ACTIVIDAD EN OPERACIONES INTERNACIONALES
            // ...foreignOperationsSection,

            // PRODUCTOS FINANCIEROS EN MONEDA EXTRANJERA
            // ...foreignProductsSection,

            // PERSONA EXPUESTA POLÍTICAMENTE (PEP)
            // ...legalPepSection,

            // PERSONAS - PEP
            // ...pepsSection,

            // CALIDAD FIRMANTE
            // ...asSignatorySection,

            // FIRMA
            ...signatorySection
          ]
        }
      ]
    };

    return request;
  }

  getDataRequest(): ViewPromissoryNoteRequest {
    // const request: ViewPromissoryNoteRequest = (this.storage.getStorage('dataAdapter') || this.dataMock({})) as ViewPromissoryNoteRequest;

    const request: ViewPromissoryNoteRequest = this.transformDataToRequest(this.storage.getStorage('preDataAdapater'));
    request.idProcesoClienteExterno = this.storage.getStorage('idProcesoClienteExterno');

    const requestWithoutEmptyValue = this.clearEmptyFieldsFromRequest(request);
    return requestWithoutEmptyValue;
  }

  clearEmptyFieldsFromRequest(request: ViewPromissoryNoteRequest) {
    if (!request.plantillas || !request.plantillas[0].anotaciones) return request;
    const newAnotaciones: AnotationViewPromissoryNote[] = request.plantillas[0].anotaciones.filter((field) => field.Valor !== '' && field.Valor !== null && field.Valor !== undefined);

    const requestWithoutEmptyValue: ViewPromissoryNoteRequest = {
      ...request,
      plantillas: [
        {
          ...request.plantillas[0],
          anotaciones: newAnotaciones
        }
      ]
    };
    return requestWithoutEmptyValue;
  }

  updateExternalCustomerProcessInDataRequest(): void {
    const id: string = this.storage.getStorage('idProcesoClienteExterno');
    const data = this.storage.getStorage('dataAdapter') || {};
    this.storage.setStorage('dataAdapter', { ...data, idProcesoClienteExterno: id });
  }

  getDataPreRequest(): FormStudyJsonModel {
    const preData: FormStudyJsonModel = (this.storage.getStorage('preDataAdapater') || {}) as FormStudyJsonModel;
    return preData;
  }

  private getFullNameRep(data: FormStudyJsonModel): string {
    const names: string = data["nombres_rep"] || '';
    const firstLastName: string = data["apel1_rep"] || '';
    const secondLastName: string = data["apel2_rep"] || '';
    return (names + ' ' + firstLastName + ' ' + secondLastName).trim();
  }

  private hasValueInProperty(data: FormStudyJsonModel, prop: keyof FormStudyJsonModel, comparativeValue: string | number): string {
    return (data[prop] && data[prop] === comparativeValue) ? 'x' : '';
  }

  private buildSectionInitial(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const quotaValue: number = data['valor_cupo'] ? Number(data['valor_cupo']) : 0;
    const quotaValueString: string = this.currencyPipe.transform(quotaValue, 'COP', '') || '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "fecha", Valor: new Date().toLocaleDateString() },
      { Nombre: "checkbox_covifactura", Valor: 'x' },
      { Nombre: "checkbox_pagos", Valor: '' },
      { Nombre: "monto_cupo", Valor: quotaValueString },
    ];

    return fields;
  }

  private buildSectionBasicInformation(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const { contrato, nombreAfiliado, municipio, celular } = this.infoAfiliate.data || {} as GetInfoResponseModel;
    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "nombre_afiliado", Valor: nombreAfiliado },
      { Nombre: "ciudad_municipio", Valor: municipio },
      { Nombre: "codigo_contrato", Valor: contrato },
      { Nombre: "celular", Valor: celular },
    ];

    return fields;
  }

  private buildSectionPartners(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const partners: StrJArray[] = data.StrJArray || [];
    let fields: AnotationViewPromissoryNote[] = [];

    partners.forEach((partner: StrJArray, index: number) => {
      const fullNamePartner: string = `${partner.nom1_socio} ${partner.nom2_socio} ${partner.apel1_socio} ${partner.apel2_socio}`.trim();
      fields.push({ Nombre: `nombre_socio_${index + 1}`, Valor: fullNamePartner });
      fields.push({ Nombre: `tipo_doc_socio_${index + 1}`, Valor: partner.tipo_documento_socio });
      fields.push({ Nombre: `num_doc_socio_${index + 1}`, Valor: partner.documento_socio });
    });

    return fields;
  }

  private buildSectionPepLinks(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const peps: SPep[] = data.vinculos_PEP || [];
    let fields: AnotationViewPromissoryNote[] = [];

    peps.forEach((pep: SPep, index: number) => {
      if (pep.nombres !== '' && pep.documento !== '') {
        fields.push({ Nombre: `pep_nombre_${index + 1}`, Valor: pep.nombres });
        fields.push({ Nombre: `pep_tipo_doc_${index + 1}`, Valor: pep.tipo_documento });
        fields.push({ Nombre: `pep_num_doc_${index + 1}`, Valor: pep.documento });
        fields.push({ Nombre: `pep_cotiza_bolsa_${index + 1}`, Valor: `${pep.cotiza_bolsa}` });
      }
    });

    return fields;
  }

  private buildSectionForeignOperations(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const hasTransactionsWithForeignCurrencies: boolean = (data.transacciones_extranjeria && data.transacciones_extranjeria.descripcion && data.transacciones_extranjeria.tipo_operacion !== '');
    const isImport: boolean = (hasTransactionsWithForeignCurrencies && data.transacciones_extranjeria.tipo_operacion === 'importaciones');
    const isExport: boolean = (hasTransactionsWithForeignCurrencies && data.transacciones_extranjeria.tipo_operacion === 'exportaciones');
    const isInvestment: boolean = (hasTransactionsWithForeignCurrencies && data.transacciones_extranjeria.tipo_operacion === 'inversion');
    const isTransfer: boolean = (hasTransactionsWithForeignCurrencies && data.transacciones_extranjeria.tipo_operacion === 'transferencia');
    const otherOperation: string = (hasTransactionsWithForeignCurrencies && !isImport && !isExport && !isInvestment && !isTransfer) ? data.transacciones_extranjeria.tipo_operacion : '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "manifiesto_autoriza", Valor: '' },
      { Nombre: "checkbox_posee_activos_virtuales_si", Valor: '' },
      { Nombre: "checkbox_posee_activos_virtuales_no", Valor: '' },
      { Nombre: "checkbox_transaccion_moneda_extran_si", Valor: hasTransactionsWithForeignCurrencies ? 'x' : '' },
      { Nombre: "checkbox_checkbox_transaccion_moneda_extran_no", Valor: !hasTransactionsWithForeignCurrencies ? 'x' : '' },
      { Nombre: "checkbox_tipo_operacion_importacion", Valor: isImport ? 'x' : '' },
      { Nombre: "checkbox_tipo_operacion_exportacion", Valor: isExport ? 'x' : '' },
      { Nombre: "checkbox_tipo_operacion_inversion", Valor: isInvestment ? 'x' : '' },
      { Nombre: "checkbox_tipo_operacion_transferencia", Valor: isTransfer ? 'x' : '' },
      { Nombre: "text_tipo_operacion_cual", Valor: otherOperation },
    ];

    return fields;
  }

  private buildSectionCommercialReference(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const city: string = this.findCityAndDeparmentById(data['lista_cuidad_ref_1'] || '')[0] || '';
    const referenceName: string = `${data['nombre1_ref_1']} ${data['apellido1_ref_1'] || ''}`.trim();

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "checkbox_persona_natural", Valor: this.hasValueInProperty(data, 'tipo_ref_1', 'PERSONAL') },
      { Nombre: "checkbox_persona_juridica", Valor: this.hasValueInProperty(data, 'tipo_ref_1', 'COMERCIAL') },
      { Nombre: "nombre_razon_social_comercial", Valor: referenceName },
      { Nombre: "ciudad_municipio_comercial", Valor: city },
      { Nombre: "email_comercial", Valor: data['correo_ref_1'] || '' },
      { Nombre: "checkbox_contacto_fijo_comercial", Valor: this.hasValueInProperty(data, 'typeContactRef', 'tellphone') },
      { Nombre: "checkbox_contacto_celular_comercial", Valor: this.hasValueInProperty(data, 'typeContactRef', 'cellphone') },
      { Nombre: "num_telefonico_comercial", Valor: data['telefono_ref_1'] || '' },
      { Nombre: "comercial_cupo_aprobado", Valor: '' },
      { Nombre: "comercial_plazo_mes", Valor: '' },
    ];

    return fields;
  }

  private buildSectionAsSignatory(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const isNaturalPerson: boolean = data["clientType"] && data["clientType"] === 'natural';
    const naturalDocType: string = isNaturalPerson && data['comprador_tipo_documento'] ? data['comprador_tipo_documento'] : '';
    const naturalDoc: string = isNaturalPerson && data["comprador_documento"] ? data["comprador_documento"] : '';

    const isLegalPerson: boolean = data["clientType"] && data["clientType"] === 'legal';
    const legalNameCompany: string = isLegalPerson && data['nombre_empresa'] ? data['nombre_empresa'] : '';
    const legalNitNum: string = isLegalPerson && data['comprador_documento'] ? data['comprador_documento'] : '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "checkbox_firmante_persona_natural", Valor: isNaturalPerson ? 'x' : '' },
      { Nombre: "firmante_natural_tipo_doc", Valor: naturalDocType },
      { Nombre: "firmante_natural_num_doc ", Valor: naturalDoc },

      { Nombre: "checkbox_firmante_persona_juridica", Valor: !isNaturalPerson ? 'x' : '' },
      { Nombre: "firmante_nombre_representacion", Valor: legalNameCompany },
      { Nombre: "firmante_num_nit", Valor: legalNitNum },
    ];

    return fields;
  }

  private buildSectionForeignProducts(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const transactions: TransaccionesExtranjeria = data.transacciones_extranjeria || {} as TransaccionesExtranjeria;

    if (!transactions) return [];

    const averageValue: string = transactions.descripcion.monto_promedio ? transactions.descripcion.monto_promedio.toString() : '';
    const moneyType: string = transactions.descripcion.moneda ? transactions.descripcion.moneda : '';
    const foreignCity: string = transactions.descripcion.ciudad ? transactions.descripcion.ciudad : '';
    const foreignCountry: string = transactions.descripcion.pais ? transactions.descripcion.pais : '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: 'nombre_entidad_prod_finan_extranjero', Valor: transactions.descripcion.nombre_entidad || '' },

      { Nombre: 'cuenta_ahorro_prod_finan_extranjero', Valor: 'PENDIENTE' },
      { Nombre: 'tipo_prod_finan_extranjero', Valor: 'PENDIENTE' },
      { Nombre: 'otro_prod_finan_extranjero', Valor: 'PENDIENTE' },
      { Nombre: 'cuenta_corriente_prod_finan_extranjero', Valor: 'PENDIENTE' },
      { Nombre: 'cual_prod_finan_extranjero', Valor: 'PENDIENTE' },

      { Nombre: 'monto_mens_promed_prod_finan_extranjero', Valor: averageValue },
      { Nombre: 'moneda_prod_finan_extranjero', Valor: moneyType },
      { Nombre: 'ciudad_prod_finan_extranjero', Valor: foreignCity },
      { Nombre: 'pais_prod_finan_extranjero', Valor: foreignCountry },
    ];
    return fields;
  }

  private buildSectionLegalPep(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const hasAdminPep: boolean = data.administradores_PEP && data.administradores_PEP.length > 0 && data.administradores_PEP[0].nombres !== '';
    const hasMemberPep: boolean = data.vinculos_PEP && data.vinculos_PEP.length > 0 && data.vinculos_PEP[0].nombres !== '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "checkbox_pep_recursos_publicos_si", Valor: data['recursos_publicos'] ? 'x' : '' },
      { Nombre: "checkbox_pep_recursos_publicos_no", Valor: !data['recursos_publicos'] ? 'x' : '' },
      { Nombre: "checkbox_pep_recono_politico_si", Valor: data['reconocimiento_politico'] ? 'x' : '' },
      { Nombre: "checkbox_pep_recono_politico_no", Valor: !data['reconocimiento_politico'] ? 'x' : '' },
      { Nombre: "checkbox_pep_grado_poder_publico_si", Valor: data['poder_publico'] ? 'x' : '' },
      { Nombre: "checkbox_pep_grado_poder_publico_no", Valor: !data['poder_publico'] ? 'x' : '' },
      { Nombre: "checkbox_espuesta_politica_si", Valor: hasAdminPep ? 'x' : '' },
      { Nombre: "checkbox_espuesta_politica_no", Valor: !hasAdminPep ? 'x' : '' },
      { Nombre: "checkbox_vinculo_persona_expuesta_si", Valor: hasMemberPep ? 'x' : '' },
      { Nombre: "checkbox_vinculo_persona_expuesta_no", Valor: !hasMemberPep ? 'x' : '' },
    ];

    return fields;
  }

  private buildSectionSignature(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const isLegalPerson: boolean = data["clientType"] && data["clientType"] === 'legal';
    const signatoryDocumentType: string = isLegalPerson ? data["tipo_doc_rep"] : data["comprador_tipo_documento"];
    const signatoryDocument: string = isLegalPerson && data['doc_rep'] ? data['doc_rep'] : (data["comprador_documento"] || '');

    const fields: AnotationViewPromissoryNote[] = [
      // { Nombre: "firma_fau", Valor: dataRequest[''] || '' },
      { Nombre: "firmante_nombre_apellido", Valor: this.getFullNameRep(data) || '' },
      { Nombre: "checkbox_firmante_tipo_doc_cc", Valor: signatoryDocumentType === 'C' ? 'x' : '' },
      { Nombre: "checkbox_firmante_tipo_doc_ce", Valor: signatoryDocumentType === 'E' ? 'x' : '' },
      { Nombre: "firmante_num_doc", Valor: signatoryDocument },
      { Nombre: "checkbox_firmante_tipo_doc_pasaporte", Valor: signatoryDocumentType === 'P' ? 'x' : '' },
      { Nombre: "firmante_direccion", Valor: data['direccion'] || '' },
      { Nombre: "firmante_email", Valor: data['correo'] || '' },
      { Nombre: "firmante_num_cel", Valor: data['celular'] || '' }
    ];

    return fields;
  }

  private buildSectionLegalRepresentative(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const isLegalPerson: boolean = data.clientType && data.clientType === 'legal';

    if (!isLegalPerson) return [];

    // const contactFullName: string = `${data["nom1_contacto"]}  ${data["nom2_contacto"]}  ${data["apel1_contacto"]}  ${data["apel2_contacto"]}
    const contactFullName: string = `${data["nom1_contacto"]} ${data["apel1_contacto"]}`.trim();
    const repLegalFullName: string = `${data["nombres_rep"]}  ${data["apel1_rep"]}  ${data["apel2_rep"]}`.trim() || '';
    const repLegalDoc: string = data["doc_rep"] || '';
    const repLegalCheckCc: string = data["tipo_doc_rep"] === 'C' ? 'x' : '';
    const repLegalCheckCe: string = data["tipo_doc_rep"] === 'E' ? 'x' : '';
    const repLegalExpirateDate: string = data["fecha_expedicion"] ? this.helper.parseStringToStringDate(data['fecha_expedicion']) : '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "nombre_apellido_representante", Valor: repLegalFullName },
      { Nombre: "checkbox_representante_tipo_cc", Valor: repLegalCheckCc },
      { Nombre: "checkbox_representante_tipo_ce", Valor: repLegalCheckCe },
      { Nombre: "checkbox_representante_tipo_nit", Valor: data[''] || '' },
      { Nombre: "checkbox_representante_tipo_pasaporte", Valor: data[''] || '' },
      { Nombre: "num_doc_representante", Valor: repLegalDoc },
      { Nombre: "fecha_exp_representante", Valor: repLegalExpirateDate },
      { Nombre: "email_representante", Valor: data["correo"] || '' },
      { Nombre: "celular_representante", Valor: data["celular"] || '' },


      { Nombre: "checkbox_contacto_empresarial", Valor: data[''] || '' },
      { Nombre: "nombre_apellido_empresarial", Valor: contactFullName || '' },
      { Nombre: "email_empresarial", Valor: data['correo_contacto'] || '' },
      { Nombre: "celular_empresarial", Valor: data['cel_contacto'] || '' },
      { Nombre: "cargo_empresarial", Valor: data['cargo_contacto'] || '' },
    ];

    return fields;
  }

  private buildSectionBuyerInformation(data: FormStudyJsonModel): AnotationViewPromissoryNote[] {
    const isLegalPerson: boolean = data["clientType"] && data["clientType"] === 'legal';
    const repFullName: string = `${data["nombres_rep"]} ${data["apel1_rep"]} ${data["apel2_rep"]}`.trim();
    const nameOrReason: string = isLegalPerson ? data["nombre_empresa"] : repFullName;
    const documentType: string = isLegalPerson ? 'N' : data["comprador_tipo_documento"];
    const stringExpireDate: string = data['fecha_expedicion'] ? this.helper.parseStringToStringDate(data['fecha_expedicion']) : '';
    const expireDate: string = isLegalPerson ? '' : stringExpireDate;
    const email: string = isLegalPerson ? '' : data['correo'];
    const phone: string = isLegalPerson ? '' : data["celular"];
    const city: string = this.findCityAndDeparmentById(data['lista_municipio'] || '')[0] || '';
    const department: string = this.findCityAndDeparmentById(data['lista_municipio'] || '')[1] || '';

    const fields: AnotationViewPromissoryNote[] = [
      { Nombre: "nombre_razon_social", Valor: nameOrReason },
      { Nombre: "checkbox_tipo_cc", Valor: documentType === 'C' ? 'x' : '' },
      { Nombre: "checkbox_tipo_ce", Valor: documentType === 'E' ? 'x' : '' },
      { Nombre: "checkbox_tipo_nit", Valor: documentType === 'N' ? 'x' : '' },
      { Nombre: "num_doc", Valor: data["comprador_documento"] || '' },
      { Nombre: "fecha_exp", Valor: expireDate },
      { Nombre: "email", Valor: email },
      { Nombre: "direccion", Valor: data['direccion'] || '' },
      { Nombre: "ciudad_municipio_comprador", Valor: city },
      { Nombre: "departamento_comprador", Valor: department },
      { Nombre: "celular_comprador", Valor: phone },
      { Nombre: "tel_fijo_comprador", Valor: data['telefono'] || '' },
      { Nombre: "actividad_eco_comprador", Valor: '' },
      { Nombre: "checkbox_contac_whatsapp", Valor: this.hasValueInProperty(data, 'canal_whatsapp', 'S') },
      { Nombre: "checkbox_contact_email", Valor: this.hasValueInProperty(data, 'canal_correo', 'S') },
      { Nombre: "checkbox_contact_sms", Valor: this.hasValueInProperty(data, 'canal_sms', 'S') },
      { Nombre: "checkbox_contact_llamada", Valor: this.hasValueInProperty(data, 'canal_llamada', 'S') },
    ];

    return fields;
  }

  dataMock(dataRequest = {}) {
    return {
      idProcesoClienteExterno: '1040',
      plantillas: [
        {
          idTipoDocumento: dataRequest[''] || 2,
          serialPlantilla: dataRequest[''] || environment.idTemplateFAU,
          anotaciones: [
            { Nombre: 'fecha', Valor: dataRequest[''] || '07/02/2024' },
            { Nombre: 'checkbox_covifactura', Valor: dataRequest[''] || 'x' },
            { Nombre: 'monto_cupo_solicitar', Valor: dataRequest[''] || '1.000.000' },
            { Nombre: 'nombre_afiliado', Valor: dataRequest[''] || 'xxxx xxxx xxxxx' },
            { Nombre: 'ciudad_municipio_solicitud', Valor: dataRequest[''] || 'Bogota' },
            { Nombre: 'codigo_contrato', Valor: dataRequest[''] || '0001234' },
            { Nombre: 'celular', Valor: dataRequest[''] || 'xxxxxxx' },
            { Nombre: 'nombre_razon_social_comprador', Valor: dataRequest[''] || 'test de prueba pagare' },
            { Nombre: 'numero_doc_comprador', Valor: dataRequest[''] || '5555555' },
            { Nombre: 'direccion_comprador', Valor: dataRequest[''] || 'Cra 50 # 30-50' },
            { Nombre: 'celular_comprador', Valor: dataRequest[''] || '3214567891' },
            { Nombre: 'fecha_expedicion_comprador', Valor: dataRequest[''] || '05/02/1980' },
            { Nombre: 'ciudad_comprador', Valor: dataRequest[''] || 'Bogota' },
            { Nombre: 'telefono_fijo_comprador', Valor: dataRequest[''] || '60178095145' },
            { Nombre: 'email_comprador', Valor: dataRequest[''] || 'comprador@gmail.com' },
            { Nombre: 'departamento_comprador', Valor: dataRequest[''] || 'Bogota' },
            { Nombre: 'actividad_economica_comprador', Valor: dataRequest[''] || 'Inversionista de acciones' },
            { Nombre: 'socio_1', Valor: dataRequest[''] || 'Emerito Cardenas Fuss' },
            { Nombre: 'tipo_doc_socio_1', Valor: dataRequest[''] || 'CC' },
            { Nombre: 'numero_doc_socio_1', Valor: dataRequest[''] || '5298751' },
            { Nombre: 'nombre_autoriza_manifiesto', Valor: dataRequest[''] || 'Pepe Rogelio Merlano' },
            { Nombre: 'tipo_doc_calidad_firmante_natural', Valor: dataRequest[''] || 'CC' },
            { Nombre: 'numero_doc_calidad_firmante', Valor: dataRequest[''] || '10321111111' },
            { Nombre: 'nombres_apellidos_firmante', Valor: dataRequest[''] || 'Julian Jimenez' },
            { Nombre: 'direccion_firmante', Valor: dataRequest[''] || 'Cra 50 # 30-50' },
            { Nombre: 'email_firmante', Valor: dataRequest[''] || 'jcjvirtual@gmail.com' },
            { Nombre: 'numero_doc_firmante', Valor: dataRequest[''] || '10321111111' },
            { Nombre: 'numero_cel_firmante', Valor: dataRequest[''] || '3214113929' },
            { Nombre: 'numero_pagare', Valor: dataRequest[''] || '001' },
            { Nombre: 'vencimiento', Valor: dataRequest[''] || '10/10/2024' },
            { Nombre: 'nombre_autorizacion_pagare', Valor: dataRequest[''] || 'Julian Jimenez' },
            { Nombre: 'checkbox_pagare_persona_natural', Valor: dataRequest[''] || '1' },
            { Nombre: 'nombre_pago_orden_pagare', Valor: dataRequest[''] || 'Covinoc' },
            { Nombre: 'ciudad_pagare', Valor: dataRequest[''] || 'Bogota' },
            { Nombre: 'valor_pago_letra', Valor: dataRequest[''] || 'Un millon de pesos' },
            { Nombre: 'valor_pago_numero', Valor: dataRequest[''] || '1.000.000' },
            { Nombre: 'nombres_apellidos_titular_pagare', Valor: dataRequest[''] || 'Camilo Rojas' },
            { Nombre: 'checkbox_titular_pagare_cc', Valor: dataRequest[''] || '1' },
            { Nombre: 'numero_cel_titular_pagare', Valor: dataRequest[''] || '3212222222' },
            { Nombre: 'numero_doc_titular_pagare', Valor: dataRequest[''] || '1032987564' },
            { Nombre: 'mail_pagare', Valor: dataRequest[''] || 'jcjvirtual@gmail.com' },
            { Nombre: 'direccion_pagare', Valor: dataRequest[''] || 'Cll 80 # 15 -20' }
          ]
        }
      ]
    } as ViewPromissoryNoteRequest;
  }

  handleNatural() {
    const natural: FormStudyJsonModel = {
      clientType: "natural",
      comprador_tipo_documento: "C",
      comprador_documento: "1006697151",
      nombre_empresa: "",
      tipo_doc_rep: "",
      doc_rep: "",
      nombres_rep: "SERGIO ",
      apel1_rep: "OSORIO",
      apel2_rep: "ANGEL",
      fecha_creacion: "",
      canal_whatsapp: "S",
      canal_correo: "N",
      canal_sms: "N",
      canal_llamada: "N",
      tipo_empresa: "",
      fecha_expedicion: "2024-04-08T05:00:00.000Z",
      lista_municipio_expedicion: "",
      celular: "3113811203",
      correo: "sergio.osorio@covinoc.com",
      fecha_nacimiento: "",
      direccion: "Carrera 12 12 1 ",
      telefono: "",
      valor_cupo: 12000,
      tipo_ref_1: "PERSONAL",
      nombre1_ref_1: "felipe",
      nombre2_ref_1: "",
      apellido1_ref_1: "lizarazo",
      apellido2_ref_1: "",
      typeContactRef: "cellphone",
      telefono_ref_1: "3115736275",
      correo_ref_1: "edgar.manchego@covinoc.com",
      lista_cuidad_ref_1: "54003",
      tipo_ref_2: "",
      nombre1_ref_2: "",
      nombre2_ref_2: "",
      apellido1_ref_2: "",
      apellido2_ref_2: "",
      telefono_ref_2: "",
      correo_ref_2: "",
      lista_cuidad_ref_2: "",
      nom1_contacto: "",
      nom2_contacto: "",
      apel1_contacto: "",
      apel2_contacto: "",
      cel_contacto: "",
      lista_municipio: "13006",
      cargo_contacto: "",
      correo_contacto: "",
      StrJArray: [
        {
          tipo_documento_socio: "",
          documento_socio: "",
          nom1_socio: "",
          nom2_socio: "",
          apel1_socio: "",
          apel2_socio: ""
        }
      ],
      recursos_publicos: true,
      reconocimiento_politico: true,
      poder_publico: true,
      administradores_PEP: [
        {
          nombres: "",
          tipo_documento: "",
          documento: "",
          cotiza_bolsa: false
        }
      ],
      vinculos_PEP: [
        {
          nombres: "andres",
          tipo_documento: "C",
          documento: "12345678",
          cotiza_bolsa: "si"
        }
      ],
      transacciones_extranjeria: {
        tipo_operacion: "tes",
        descripcion: {
          nombre_entidad: "sony",
          tipo_producto: "ahorros",
          monto_promedio: 124444,
          moneda: "peso",
          ciudad: "armneia",
          pais: "colombia"
        }
      }
    }
  }

  handleLegal() {
    const legal: FormStudyJsonModel = {
      clientType: "legal",
      comprador_tipo_documento: "",
      comprador_documento: "8600284621",
      nombre_empresa: "sony",
      tipo_doc_rep: "C",
      doc_rep: "1006697151",
      nombres_rep: "SERGIO ",
      apel1_rep: "OSORIO",
      apel2_rep: "ANGEL",
      fecha_creacion: "",
      canal_whatsapp: "S",
      canal_correo: "N",
      canal_sms: "N",
      canal_llamada: "N",
      tipo_empresa: "UNIPERSONAL",
      fecha_expedicion: "2024-04-09T05:00:00.000Z",
      lista_municipio_expedicion: "",
      celular: "3113811203",
      correo: "SERGIO.OSORIO@COVINOC.COM",
      fecha_nacimiento: "",
      direccion: "Carrera 12 12 1 ",
      telefono: "5736275",
      valor_cupo: 1200000,
      tipo_ref_1: "PERSONAL",
      nombre1_ref_1: "felipex",
      nombre2_ref_1: "",
      apellido1_ref_1: "lizarazox",
      apellido2_ref_1: "",
      typeContactRef: "cellphone",
      telefono_ref_1: "3115736275",
      correo_ref_1: "edgar.macnhegox@covinoc.com",
      lista_cuidad_ref_1: "5004",
      tipo_ref_2: "",
      nombre1_ref_2: "",
      nombre2_ref_2: "",
      apellido1_ref_2: "",
      apellido2_ref_2: "",
      telefono_ref_2: "",
      correo_ref_2: "",
      lista_cuidad_ref_2: "",
      nom1_contacto: "felipe",
      nom2_contacto: "",
      apel1_contacto: "lizarazo",
      apel2_contacto: "",
      cel_contacto: "3115736275",
      lista_municipio: "54003",
      cargo_contacto: "developer",
      correo_contacto: "edgar.manchego@covinoc.com",
      StrJArray: [
        {
          tipo_documento_socio: "",
          documento_socio: "",
          nom1_socio: "",
          nom2_socio: "",
          apel1_socio: "",
          apel2_socio: ""
        }
      ],
      recursos_publicos: true,
      reconocimiento_politico: true,
      poder_publico: true,
      administradores_PEP: [
        {
          nombres: "pacho galan",
          tipo_documento: "C",
          documento: "12341234",
          cotiza_bolsa: "si"
        }
      ],
      vinculos_PEP: [
        {
          nombres: "pachgito",
          tipo_documento: "C",
          documento: "1432144323",
          cotiza_bolsa: "si"
        }
      ],
      transacciones_extranjeria: {
        tipo_operacion: "importaciones",
        descripcion: {
          nombre_entidad: "samsung",
          tipo_producto: "nne",
          monto_promedio: 12345,
          moneda: "peso",
          ciudad: "bogota",
          pais: "colombia"
        }
      }
    };
  }

}


