import { Component, Input, OnInit } from '@angular/core';
import { ClientStudyRequest } from '@app/models/clientStudyRequest.model';

@Component({
  selector: 'app-contrapart-knowledge',
  templateUrl: './contrapart-knowledge.component.html',
  styleUrls: ['./contrapart-knowledge.component.scss']
})
export class ContrapartKnowledgeComponent implements OnInit {

  @Input() clientStudyForm: ClientStudyRequest;

  constructor() { }

  ngOnInit() {
  }

}
