import { Component } from '@angular/core';
import { ModalsService } from '../utils/modals.service';
import { SubjectPromissoryService } from '../services/subject.service';

@Component({
  selector: 'app-modal-resend-otp-ok',
  templateUrl: './modal-resend-otp-ok.component.html',
  styleUrls: ['./modal-resend-otp-ok.component.scss']
})
export class ModalResendOtpOkComponent {

  public modalName: string = 'modalResendOtpOk';

  constructor(
    private modals: ModalsService,
    private subjectService: SubjectPromissoryService
  ) {
    
  }

  handleClick() {
    this.subjectService.setcallIssuePromissoryNote(false);
    this.modals.show('modalOtp');
  }

}
