import { Component } from '@angular/core';
import { SubjectPromissoryService } from '../services/subject.service';

@Component({
  selector: 'app-modal-quota-request-error',
  templateUrl: './modal-quota-request-error.component.html',
  styleUrls: ['./modal-quota-request-error.component.scss']
})
export class ModalQuotaRequestErrorComponent {

  public modalName: string = 'modalQuotaRequestError';
  public error: string = '';

  constructor(
    private subjectPromissoryService: SubjectPromissoryService
    
  ) {
    this.subjectPromissoryService.errorQuotaRequest$.subscribe(this.handleErrorQuotaRequest.bind(this))
  }

  handleErrorQuotaRequest(error: string) {
    if(error) this.error = error;
  }

  handleClick() {
    window.location.reload();
  }
}
