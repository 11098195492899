
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {
  public urlApi: string;
  public userToken: string;
  public identity: any;

  private headers: HttpHeaders = new HttpHeaders();

  public documentTypes: any [] = [
    { value: 'N', description: 'Nit' },
    { value: 'C', description: 'Cédula de ciudadanía' },
    { value: 'E', description: 'Cédula de extranjería' },
  ];

  public searchAffiliateBy: any[] = [
    { value: 'document', description: 'Documento' },
    { value: 'socialReason', description: 'Razón social' },
    { value: 'contract', description: 'Contrato' },
    // { value: 'queryPoint', description: 'Punto de Consulta' },
    // { value: 'branchOffice', description: 'Sucursal' },
  ];

  public optionsStateTitleValue: any[] = [
    { value: 'legalize', description: 'Legalizar' },
    { value: 'refuse', description: 'Rechazar' },
    { value: 'to process', description: 'Subsanar' },
  ];

  public dummyData: any[] = [
    { value: 'dato1', description: 'dato1' },
    { value: 'dato2', description: 'dato2' },
  ]

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
  }

  readToken() {
    if (localStorage.getItem("tokenCF")) {
      this.userToken = localStorage.getItem("tokenCF");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getCities() {
    const headers = this.headers.append("Content-Type", "application/json");
    const options = {
      headers
    };
    return this.http.get(`${this.urlApi}/inicializar`, options).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }

  getMunicipalities(departmentId: string) {
    const headers = this.headers.append("Content-Type", "application/json");
    const params = { id_departamento: departmentId }
    const options = {
      headers, params
    };
    return this.http.get(`${this.urlApi}/municipios`, options).pipe(
      map((municipalities: any) => {
        return municipalities['MUNICIPIOS'];
      })
    )
  }

}
