import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalyticsService } from '@app/services/analytics.service';
import { OlimpiaService } from '../services/olimpia.service';
import { ModalsService } from '../utils/modals.service';
import { StartProcessService } from '../services/start-process.service';
import { SubjectPromissoryService } from '../services/subject.service';
import { ViewPromissoryNoteResponse } from '../models/view-promissory-note.model';
import { SelectsService } from '@app/services/selects.service';
import { SelectsResponse } from '../models/selects.model';
import { CovifacturaService } from '../services/covifactura.service';
import { GetInfoResponse } from '../models/get-info.model';


@Component({
  selector: 'app-card-promissory-note-preview',
  templateUrl: './card-promissory-note-preview.component.html',
  styleUrls: ['./card-promissory-note-preview.component.scss']
})
export class CardPromissoryNotePreviewComponent {

  public pdfBase64: string = '';
  public pdfMIME: string = 'data:application/pdf;base64,';
  public errorInLoadPdf: boolean = false;

  constructor(
    private olimpiaService: OlimpiaService,
    public modals: ModalsService,
    private spinner: NgxSpinnerService,
    private startProcess: StartProcessService,
    private subjectService: SubjectPromissoryService,
    private analytics: AnalyticsService,
    private selectsService: SelectsService,
    private covifacturaService: CovifacturaService
  ) {
    this.getAfiliateInfo();

    this.subjectService.callPreviewOfDocument$.subscribe((value: boolean) => {
      if (value) this.handlePreviewDocument();
    })
  }

  getAfiliateInfo() {
    this.spinner.show('loadingAfiliateInfo');
    this.covifacturaService.getInfo().subscribe({
      next: (response: GetInfoResponse) => {
        this.subjectService.setAfiliateInfoResponse(response);
        this.spinner.hide('loadingAfiliateInfo');
        this.loadCities();
      },
      error: (error: any) => {
        console.error('Ocurrió un error al consultar la información del afiliado', error);
        this.spinner.hide('loadingAfiliateInfo');
        this.modals.show('modalAfiliateInfoError');
      }
    });
  }

  loadCities() {
    this.spinner.show('loadingLocations');
    this.selectsService.getCities().subscribe({
      next: (response: SelectsResponse) => {
        this.subjectService.setCitiesResponse(response);
        this.spinner.hide('loadingLocations');
        this.startProcess.startProcess();
      },
      error: (error: any) => {
        console.error('Ocurrió un error al consultar la información de las ciudades', error);
        this.spinner.hide('loadingLocations');
        this.startProcess.startProcess();
      }
    });
  }

  handlePreviewDocument() {
    this.spinner.show('generatingDocumentPreview');
    this.olimpiaService.previewPromissoryNote({})
      .subscribe((response: ViewPromissoryNoteResponse) => {
        console.log('Documento previsualizado', response.data.archivos[0].base64);
        this.pdfBase64 = this.pdfMIME + response.data.archivos[0].base64;
        this.spinner.hide('generatingDocumentPreview');
        this.analytics.trackWebEvent('LinkCupoPrevisualizarFau_Ok');
      }, (error) => {
        this.pdfBase64 = '';
        console.error('Error al obtener la previsualización del documento', error);
        this.spinner.hide('generatingDocumentPreview');
        this.analytics.trackWebEvent('LinkCupoPrevisualizarFau_Error');
      });
  }

  handleContinue() {
    this.subjectService.setcallIssuePromissoryNote(true);
    this.modals.show('modalOtp');
  }

  handleloadComplete(event: any) {
    console.log('El visualizador del pdf, ha cargado el pdf');
  }

  onProgress(event: any) {
    console.log('El visualizador del pdf, esta cargando el pdf');
  }

  onError(event: any) {
    console.log('Ocurrió un error al visualizar el pdf');
    this.errorInLoadPdf = true;
  }

}
