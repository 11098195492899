export class MassiveExportTransactionRequest {
  idContry: string;
  documentType: string;
  documentNumber: string;
  settled: string;
  status: string;

  public static toRequest(
    massiveExportTransactionRequest: MassiveExportTransactionRequest
  ): any {
    return {
      id_pais: massiveExportTransactionRequest.idContry,
      tipo_documento: massiveExportTransactionRequest.documentType,
      documento: massiveExportTransactionRequest.documentNumber,
      radicado: massiveExportTransactionRequest.settled,
      estado: massiveExportTransactionRequest.status,
    };
  }
}
