import { Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { ModalService } from "@services/modal.service";
import { HelperService } from "@services/helper.service";
import { ClientStudyRequest } from "@models/clientStudyRequest.model";
import { Department } from "@models/department.model";
import { City } from "@models/city.model";
import { TabsService } from "@app/services/tabs.service";
import { ToastrService } from "ngx-toastr";
import { SubjectService } from "@app/services/subject.service";
import { SelectsService } from "@app/services/selects.service";
import { ClientStudyNaturalModel } from "@app/models/clientStudyNaturalRequest.model";
import { BackEndService } from "@app/services/back-end.service";
import { NgxSpinnerService } from "ngx-spinner";
declare let $: any;
import * as _moment from "moment";
import { stringify } from "querystring";
import { ReconoserRequest } from '@app/components/validate-identity/validate-identity.component';
import { AnalyticsService } from '@app/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PromissoryNotePreviewService } from '@app/pages/promissory-note/services/promissory-note-preview.service';
import { FormStudyJsonModel } from '@app/pages/promissory-note/models/form-study.model';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: "app-display-natural-person",
  templateUrl: "./display-natural-person.component.html",
  styleUrls: ["./display-natural-person.component.scss"],
})
export class DisplayNaturalPersonComponent implements OnInit, AfterContentInit {
  @ViewChild("formCheck") formCheck;
  /* @Input() departments: Array<Department>; */
  @Input() initialDateFields: Array<String>;
  @Input() addressFields: Array<String>;
  @Input() documentTypesOptions: Array<any>;
  @Input() companyTypeFields: Array<any>;
  @Input() economicActivityFields: Array<any>;
  @Input() expeditionCities: Array<City>;
  @Input() homeCities: Array<City>;
  @Input() lastSaveDate: String;
  @Input() updateRefCities: Subject<boolean>;

  /* File properties */

  public files: File[] = [];
  public mb = 1024 * 1024;
  public maxFileSize = 7 * this.mb;
  public fileSelected: File;

  public subs1: Subscription;
  cancel = false;


  // Form
  @Input() clientStudyForm: ClientStudyNaturalModel;
  @Output()
  requestClientStudy: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  validateChange: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  homeDepartmentChanged: EventEmitter<String> = new EventEmitter<String>();
  @Output()
  expeditionDepartmentChanged: EventEmitter<String> = new EventEmitter<String>();
  @Output()
  isShowXL: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();

  public isRef1Valid = false;
  public isRef2Valid = false;
  public isTermAccepted = false;
  public step: number = 1;
  public stepSubs: Subscription;
  public IsExpeditionRequired: boolean = true;

  public addressFormValid: boolean = false;
  public channelFormValid: boolean = false;

  public pepValid: boolean = false;
  public switchPepValid: boolean = false;

  public publicPepValid: boolean = false;
  public switchPublicPepValid: boolean = false;

  public min: Date = new Date(1940, 0, 1);
  public max: Date = new Date();

  public addressForeignValid: boolean = false;
  public switchAddressForeignValid: boolean = false;

  public commercialReferenceValid: boolean = false;

  public reconoserRequest: ReconoserRequest;

  public legalRepresentativeName = {
    firstName: "",
    secondName: "",
  };

  public option = {
    description: "pointsOfSale",
  };

  public switches = {
    pep: false,
    exposedPerson: false,
    foreignCurrency: false,
  };

  public buyerName = {
    firstName: "",
    secondName: "",
  };

  public dataCounterpartPep = [
    {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    },
  ];

  public dataExposedPerson = [
    {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    },
  ];

  public cities: any;
  public scholarship: any;

  public departments = [];
  public expeditionDepartments = [];
  public department = "";
  public expeditionDepartment = "";
  public homeDepartment = "";
  public municipalities = [];
  public expeditionMunicipalities = [];
  public currentStep = 1;

  public currentStep$: Observable<number> = this.subject.stateCurrentStep$;

  public validatePatternDocument;

  public message = "Recuerde que es necesario acceder a la cámara del dispositivo y tener a la mano el documento de identificación del comprador."

  public expeditionDisabled = false;

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    private tabs: TabsService,
    private toastr: ToastrService,
    public subject: SubjectService,
    public select: SelectsService,
    public backend: BackEndService,
    private spinner: NgxSpinnerService,
    private promissoryNoteService: PromissoryNotePreviewService,
    private analytics: AnalyticsService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.clientStudyForm.comprador_tipo_documento = 'C';
    this.validatePatternDocument = this.helper.validatePatternDocument('C');
    this.subject.stateTypePerson$.subscribe((resp) => {
      this.clientStudyForm.clientType = resp;
    });
    this.clientStudyForm.nombres_rep = `${this.legalRepresentativeName.firstName} ${this.legalRepresentativeName.secondName}`;
    this.getCities();

    // TODO: Eliminar
    if(!environment.production) this.loadDataMock();
  }

  changeIsExpeditionRequired() {
    this.validatePatternDocument = this.helper.validatePatternDocument(this.clientStudyForm.comprador_tipo_documento);
    if (this.clientStudyForm.comprador_tipo_documento === 'E') {
      this.IsExpeditionRequired = false;
    } else {
      this.IsExpeditionRequired = true;
    }
  }


  get isValidAmount() {
    return this.clientStudyForm.valor_cupo as any > 20000000;
  }
  get isLowerAmount() {
    return this.clientStudyForm.valor_cupo as any < 1;
  }

  /* File methods */

  onSelect(event: any) {
    this.fileSelected = event.addedFiles[0];
    let selectedFilesSize = 0;
    let selectedAddedFiles = 0;
    if (event.rejectedFiles[0])

      if (event.rejectedFiles[0].size > this.maxFileSize) {
        this.toastr.error("El tamaño máximo permitido es de 7 megabytes (MB)", "");
      } else {
        this.toastr.error("Formato no válido", "")
      }

    event.addedFiles.forEach(file => {
      selectedAddedFiles += file.size;
    });

    this.files.forEach(file => {
      selectedFilesSize += file.size;
    });

    if (selectedAddedFiles + selectedFilesSize >= this.maxFileSize) {

      this.toastr.error("El tamaño máximo permitido es de 7 megabytes (MB)", "")
      return;
    }

    this.files.push(...event.addedFiles);
    this.uploadFile();
  }

  onRemove(event, one: boolean) {
    if (one) {
      this.files.splice(this.files.indexOf(event), 1);
    } else {
      this.files = [];
    }
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.chargeResult.emit(this.files);
  }

  getCities() {
    this.select.getCities().subscribe((response) => {
      this.cities = this.mapCities(response['CIUDAD-DEPARTAMENTO']);
      this.scholarship = response['ESCOLARIDAD'];
    });
  }

  mapCities(citiesData: any[]): any[] {
    return citiesData.map(city => {
      return {
        ...city,
        ID: city.ID.toString()
      };
    });
  }

  setBuyerName() {
    this.clientStudyForm.nombres_rep = `${this.legalRepresentativeName.firstName} ${this.legalRepresentativeName.secondName}`;
  }

  selectDeparment(selectedDepartment: any) {
    this.spinner.show();
    this.clientStudyForm.lista_municipio = "";

    this.select.getMunicipalities(selectedDepartment).subscribe(
      (municipalities: any) => {
        this.municipalities = municipalities;
      },
      (err) => {
        this.municipalities = [];
      }, () => {
        this.spinner.hide();
      });
  }

  selectExpeditionDeparment(selectedDepartment: any) {
    this.spinner.show();
    this.clientStudyForm.lista_municipio_expedicion = "";
    this.select.getMunicipalities(selectedDepartment).subscribe(
      (municipalities: any) => {
        this.expeditionMunicipalities = municipalities;
      },
      (err) => {
        this.expeditionMunicipalities = [];
      }, () => {
        this.spinner.hide();
      });
  }

  validateYear($event) {
    let date = moment(this.clientStudyForm.fecha_expedicion, "DD/MM/YYYY").format("DD/MM/YYYY")

    if (this.clientStudyForm.fecha_expedicion) {
      return;
    }

    if (!this.clientStudyForm.fecha_expedicion) {
      const dateNow = $event.target.value;
      const parseDate = dateNow.split("/");
      const reverseDate = parseDate.reverse();
      const joinDate = reverseDate.join()

      this.clientStudyForm.fecha_expedicion = new Date(joinDate);
    }

    if (date !== "Invalid date") {
      $event.target.value = moment(this.clientStudyForm.fecha_expedicion).format("DD/MM/YYYY");
      this.clientStudyForm.fecha_expedicion = new Date(date);
    }
  }

  validateBirthdayYear($event) {
    let date = moment(this.clientStudyForm.fecha_nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY")

    if (this.clientStudyForm.fecha_nacimiento) {
      return;
    }

    if (!this.clientStudyForm.fecha_nacimiento) {
      const dateNow = $event.target.value;
      const parseDate = dateNow.split("/");
      const reverseDate = parseDate.reverse();
      const joinDate = reverseDate.join()

      this.clientStudyForm.fecha_nacimiento = new Date(joinDate) as any;
    }

    if (date !== "Invalid date") {
      $event.target.value = moment(this.clientStudyForm.fecha_nacimiento).format("DD/MM/YYYY");
      this.clientStudyForm.fecha_nacimiento = new Date(date) as any;
    }

  }

  validatePreviousRequest() {
    const userCredentials = {
      comprador_tipo_documento: (this.clientStudyForm.comprador_tipo_documento !== 'C' && this.clientStudyForm.comprador_tipo_documento !== 'E') ? 'N' : this.clientStudyForm.comprador_tipo_documento,
      comprador_documento: this.clientStudyForm.comprador_documento
    }
    this.backend.validatePreviousRequest(userCredentials).subscribe((resp: any) => {
      if (resp.ESTADO_CUPO === "ACTIVO") {
        this.subject.changeStateInvalidDataError(true);
        this.subject.changeStateMessageError("Ya tiene un cupo asignado, para mayor información comuníquese con +57 318 7116639, en Bogotá 3534324 o a la línea gratuita 018000946969");
        this.modal.show("errorMessage");
      }
    })
  }

  testData() {
  }

  uploadFileModal() {
    this.modal.show("fileUpload")
  }

  ngAfterContentInit() {
    this.stepSubs = this.tabs.stateStep$.subscribe((resp: any) => {
      this.step = resp;
    });
  }

  acceptTerms() {
    let typePerson =
      this.clientStudyForm.comprador_tipo_documento !== "C" &&
        this.clientStudyForm.comprador_tipo_documento !== "E"
        ? "N"
        : this.clientStudyForm.comprador_tipo_documento;

    const infoUser = {
      typeDocument: typePerson,
      document: this.clientStudyForm.comprador_documento,
    };
    this.backend.getAcceptTerms(infoUser).subscribe((resp) => { });
    this.validatePreviousRequest();
  }

  handleNext(step: number, isShowXL: boolean) {
    if (step == 2) {
      try {
        this.reconoserRequest = {
          celular: this.clientStudyForm.celular,
          email: this.clientStudyForm.correo,
          numDoc: this.clientStudyForm.comprador_documento,
          tipoDoc: this.clientStudyForm.comprador_tipo_documento,
          tipoValidacion: '1'
        };
        this.analytics.trackWebEvent('LinkCupoNatural_Ok');
      } catch (error) {
        this.analytics.trackWebEvent('LinkCupoNatural_Error');
      }
    }
    this.currentStep = step;
    this.subject.changeStep(step);
    this.tabs.changeIsShowXL(isShowXL);
  }

  handlePrev(step: number) {
    this.currentStep = step;
    this.subject.changeStep(step);
  }

  validateIdentity(event: any) {
    if (!event) return;
    if (event.isSuccess) {
      this.modal.show("successValidation");
    } else {
      this.modal.show("failedValidation");
    }
  }

  succesedValidation() {
    this.currentStep = 3;
    this.subject.changeStep(3);
    this.modal.close()
  }

  failedValidation() {
    this.currentStep = 1;
    this.subject.changeStep(1);
    this.modal.close()
  }

  validateIdentityData(event: any) {
    if (!event) {
      this.currentStep = 1;
      this.subject.changeStep(1);
    }
    this.clientStudyForm.comprador_documento = event.numDoc;
    this.legalRepresentativeName.firstName = event.primerNombre;
    this.legalRepresentativeName.secondName = event.segundoNombre;
    this.setBuyerName();
    this.clientStudyForm.apel1_rep = event.primerApellido;
    this.clientStudyForm.apel2_rep = event.segundoApellido;
    if (event.servicios) {
      for (let servicio of event.servicios) {
        if (servicio.servicio == "Biometría" && servicio.tipo == "Documento" 
        && servicio.subTipos == "Reverso" && servicio.barcode && servicio.barcode.fechaExpedicion) {
          if (moment(event.fecha_expedicion).isValid()) {
            this.expeditionDisabled = true;
            this.clientStudyForm.fecha_expedicion = new Date(event.fechaExpedicion);
          }
        }
      }
    }
  }

  handleGoToStep(step: number) {
    this.currentStep = step;
    this.subject.changeStep(step);
  }

  addPep(id: any) {
    const pep = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (this.dataCounterpartPep.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 elementos.", null);
      return;
    }

    this.dataCounterpartPep.push(pep);
  }

  removePep(id: number) {
    const pep = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (id === 0 && this.dataCounterpartPep.length > 0) {
      this.dataCounterpartPep[0] = pep;
      return;
    }

    this.dataCounterpartPep.splice(id, 1);
  }

  addExposedPerson(id: any) {
    const pep = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (this.dataExposedPerson.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 elementos.", null);
      return;
    }

    this.dataExposedPerson.push(pep);
  }

  deletePerson(id: number) {
    const exposedPerson = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (id === 0 && this.dataExposedPerson.length > 0) {
      this.dataExposedPerson[0] = exposedPerson;
      return;
    }

    this.dataExposedPerson.splice(id, 1);
  }

  handleValidAddress($event) {
    this.addressFormValid = $event;
  }

  handleValidChannel($event) {
    this.channelFormValid = $event;
  }

  handleValidForeign($event) {
    this.addressForeignValid = $event;
  }

  handleValidPep($event) {
    setTimeout(() => {
      this.pepValid = $event;
    }, 1);
  }

  handleSwitchPep() {
    if (this.switches.exposedPerson === false) {
      this.switchPepValid = true;
      this.clientStudyForm.vinculos_PEP = [
        {
          nombres: "",
          tipo_documento: "",
          documento: "",
          cotiza_bolsa: "",
        },
      ];
      this.pepValid = false;
    } else {
      this.clientStudyForm.vinculos_PEP = [];
      this.switchPepValid = false;
    }
  }

  handlePublicValidPep($event) {
    setTimeout(() => {
      this.publicPepValid = $event;
    }, 1);
  }

  handleValidCommercialReference($event) {
    setTimeout(() => {
      this.commercialReferenceValid = $event;
    }, 1);
  }

  handleSwitchPublicPep() {
    if (this.switches.pep === false) {
      this.switchPublicPepValid = true;
      this.clientStudyForm.administradores_PEP = [
        {
          nombres: "",
          tipo_documento: "",
          documento: "",
          cotiza_bolsa: "",
        },
      ];
      this.publicPepValid = false;
    } else {
      this.clientStudyForm.administradores_PEP = [];
      this.switchPublicPepValid = false;
    }
  }

  handleSwitchValidForeign() {
    if (this.switches.foreignCurrency === false) {
      this.switchAddressForeignValid = false;
    } else {
      this.switchAddressForeignValid = true;
    }
    if (this.switches.foreignCurrency === true) {
      this.clientStudyForm.transacciones_extranjeria = {
        tipo_operacion: "",
        descripcion: {
          nombre_entidad: "",
          tipo_producto: "",
          monto_promedio: "",
          moneda: "",
          ciudad: "",
          pais: "",
        },
      };
      this.addressForeignValid = false;
    }
  }

  onBlur($event) {
    /* this.valueChanges.emit(); */
  }

  onBlurValidate($event) {
    /* this.validateChange.emit(); */
  }

  nextStep(isShowXL: boolean) {
    this.step += 1;
    this.isShowXL.emit();
    this.tabs.changeIsShowXL(isShowXL);
  }

  emitChanges($event) {
    this.valueChanges.emit();
  }

  setRef1Valid(value: boolean) {
    this.isRef1Valid = value;
  }

  setRef2Valid(value: boolean) {
    this.isRef2Valid = value;
  }

  setAcceptTerms(value: boolean) {
    this.isTermAccepted = value;
  }

  onHomeDepartmentChanged(expeditionDepartmentId) {
    this.homeDepartmentChanged.emit(expeditionDepartmentId);
  }
  onExpeditionDepartmentChanged(expeditionDepartmentId) {
    /* this.expeditionDepartmentChanged.emit(expeditionDepartmentId); */
  }

  /* changeOperationType(OperationType: string) {
    this.clientStudyForm.foreignCurrencyOperationType = OperationType;
  }

  changeTypeAccount(typeAccount) {
    this.clientStudyForm.foreignCurrencyTypeAccount = typeAccount
  } */

  loadDataMock() {
    this.route.queryParams.subscribe(params => {
      const dataMock = params["dataMock"];
      if (dataMock === 'natural') {
        this.clientStudyForm.comprador_tipo_documento = "C";
        this.clientStudyForm.comprador_documento = "1006697151";
        this.clientStudyForm.celular = "3113811203";
        this.clientStudyForm.correo = "sergio.osorio@covinoc.com";
        this.addressFormValid = true;
        this.clientStudyForm.lista_municipio = "11001";
        this.clientStudyForm.valor_cupo = "120000";
        this.channelFormValid = true;

        this.clientStudyForm.nom1_contacto = "Sergio";
        this.clientStudyForm.apel1_contacto = "Osorio";
        this.clientStudyForm.cargo_contacto = "Desarrollador";
        this.clientStudyForm.cel_contacto = "3113811203";
        this.clientStudyForm.correo_contacto = "sergio.osorio@covinoc.com";
        this.handleValidCommercialReference(true);
      }
    });
  }

  handleSubmit() {
    try {
      console.log('///// Desde el handle Submit de Natural, clientStudyForm: ', this.clientStudyForm);
      this.promissoryNoteService.saveDataRequest({ ...this.clientStudyForm } as FormStudyJsonModel);
      this.subject.changeStep(5);
      this.analytics.trackWebEvent('LinkCupoReferenciaComercial_Ok');
      this.trackEventCounterPart();
      return;
    } catch (error) {
      this.analytics.trackWebEvent('LinkCupoReferenciaComercial_Error');
      return;
    }

    this.spinner.show();
    const bodyGenerateOtp = {
      tipo_documento_comprador: this.clientStudyForm.comprador_tipo_documento,
      documento_comprador: this.clientStudyForm.comprador_documento,
      correo: this.clientStudyForm.correo,
      celular: this.clientStudyForm.celular,
    };

    this.backend.generateOtpCode(bodyGenerateOtp).subscribe((resp) => {
      this.subject.changeStateSentCoupon(false);
      this.modal.show("validateOtp");
    }, (err) => {
      const errorMessage = `${err.status || "ANY ERROR"} - ${ err.error.message || err.statusText}`;

      this.subject.changeStateInvalidDataError(true);
      /* this.subject.changeStateMessageError("Ha ocurrido un error al generar el código OTP"); */
      this.subject.changeStateMessageError(errorMessage);
      this.modal.show("errorMessage");
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  trackEventContact() {
    try {
      this.analytics.trackWebEvent('LinkCupoDatosContacto_Ok');
    } catch (error) {
      this.analytics.trackWebEvent('LinkCupoDatosContacto_Error');
    }
  }

  trackEventCounterPart() {
    if (this.clientStudyForm.recursos_publicos) {
      if (this.clientStudyForm.clientType === 'natural') this.analytics.trackWebEvent('LinkCupoNatural_Contraparte_Ok');
      if (this.clientStudyForm.clientType === 'legal') this.analytics.trackWebEvent('LinkCupoJuridica_Contraparte_Ok');
    }
  }
}
