import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { FetchService } from './fetch.service';
import { SaveDocumentCoviresourceRequest, SaveDocumentCoviresourceResponse } from '../models/save-document-coviresource';
import { FormStudyJsonModel } from '../models/form-study.model';
import { GetInfoRequest, GetInfoResponse } from '../models/get-info.model';


@Injectable({
  providedIn: 'root'
})
export class CovifacturaService {

  public apiUrlBuyer: string = '';
  public headers: HttpHeaders = new HttpHeaders();
  private storage: StorageService = new StorageService(true);

  constructor(
    private http: HttpClient,
    private fetch: FetchService
  ) {
    this.headers = this.fetch.setBasicAuthorization(environment.apiNitToken);
    this.apiUrlBuyer = environment.apiUrlBuyer;
  }

  private saveDocumentCoviresourceService(request: SaveDocumentCoviresourceRequest): Observable<SaveDocumentCoviresourceResponse> {
    const options = { headers: this.headers };
    return this.http.post<SaveDocumentCoviresourceResponse>(`${this.apiUrlBuyer}/validar_otp/FirmaElectronicaSolicitudCupo`, request, options);
  }

  saveDocumentCoviresource(radicadoNum: string = '') {
    const request: SaveDocumentCoviresourceRequest = this.buildRequestToSaveDocumentCoviresource(radicadoNum);
    this.saveDocumentCoviresourceService(request).subscribe({
      next: (response: SaveDocumentCoviresourceResponse) => {
        console.log('Se ha almacenado el documento en coviresource', response);
      },
      error: (error: any) => {
        console.error('Ocurrió un error almacenar el documento en coviresource', error)
      }
    });
  }

  private buildRequestToSaveDocumentCoviresource(radicadoNum: string = ''): SaveDocumentCoviresourceRequest {
    const formsData: FormStudyJsonModel = (this.storage.getStorage('preDataAdapater') || {}) as FormStudyJsonModel;
    const documentType: string = formsData.comprador_tipo_documento || 'N'; // Si es persona juridica formsData.comprador_tipo_documento viene vacio

    const request: SaveDocumentCoviresourceRequest = {
      id_unico: this.storage.getStorage('idProcesoClienteExterno') || "-1",
      id_transaccion: this.storage.getStorage('transaccionId') || "-1",
      estadoCoviResourse: false,
      serialDocumentoPlantilla: environment.idTemplateFAU || "-1",
      tipo_doc: documentType,
      documento: formsData.comprador_documento || '-1',
      radicacion: radicadoNum ? radicadoNum : null
    };

    return request;
  }

  getInfo(): Observable<GetInfoResponse> {
    const request: GetInfoRequest = this.buildRequestToGetInfo();
    const options = { headers: this.headers, params: { ...request } };
    return this.http.get<GetInfoResponse>(`${this.apiUrlBuyer}/validar_otp/getInfoFauLink`, options)
  }

  private buildRequestToGetInfo(): GetInfoRequest {
    const infoAfiliate = JSON.parse(localStorage.getItem('CFCDstateCode') || "{}");
    const request: GetInfoRequest = {
      documento: infoAfiliate["afiliado_documento"] || '',
      tipo: 'F',
      tipoDocumento: infoAfiliate["afiliado_tipo_documento"] || ''
    };
    return request;
  }



}
