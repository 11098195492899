import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '@services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // Properties
  public userLogin: FormGroup;
  public hidePassword = false;

  // public dataLogin: LoginModel = {
  //   username: 'edgar.manchego',
  //   password: 'Jdaniels007*',
  // };

  public dataLogin: any = {
    username: '',
    password: '',
  };

  public year = new Date();

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.userLogin = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });

    this.userLogin.setValue(this.dataLogin);
  }

  ngOnInit() {
  }

  pass() {
    this.router.navigateByUrl('/contrasena');
  }

  login(form?: NgForm, dataLogin?: object) {
    if (dataLogin) {
      this.userLogin.setValue(dataLogin);
    }
    this.spinner.show();
    this.auth.login(this.userLogin.value).subscribe(
      (resp: any) => {
        this.router.navigateByUrl('/pages/respaldos');
        this.spinner.hide();
        this.toastr.success(resp['name'], 'Bienvenid@');
      },
      (err) => {
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          text: 'Usuario o contraseña no válidos',
        });
      }
    );
  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }

  toastNotification(titulo: string, descripcion: string) {
    this.toastr.success(titulo, descripcion);
  }
}
