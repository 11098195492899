import { Component, OnInit } from '@angular/core';

interface SpinnerModel {
  name: string;
  textDescription: string;
}

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  public logo: string = 'assets/shared/img-logo-covifactura-color.svg';

  public spinners: SpinnerModel[] = [
    { name: 'startProcess', textDescription: 'Iniciando el proceso...' },
    { name: 'generatingDocumentPreview', textDescription: 'Generando previsualización del documento...' },
    { name: 'sendingDocument', textDescription: 'Emitiendo documento...' },
    { name: 'resendingOtp', textDescription: 'Reenviando código OTP...' },
    { name: 'validatingOtp', textDescription: 'Validando código OTP...' },
    { name: 'requestingQuota', textDescription: 'Solicitando cupo...' },
    { name: 'loadingLocations', textDescription: 'Consultando ubicaciones...' },
    { name: 'loadingAfiliateInfo', textDescription: 'Consultando información del afiliado...' },
  ];

  public color: string = '#162943';

  constructor() { }

  ngOnInit() {
  }

}
