import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  DoCheck,
  AfterViewChecked,
} from "@angular/core";
import { ModalService } from "../services/modal.service";
import { CONSTANTS } from "@config/constants";
import { SubjectService } from "@app/services/subject.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit, AfterViewChecked {
  // public navigation = [
  //   {
  //     idOption: null,
  //     alt: 'Transacciones',
  //     img: 'icon-transactions.svg',
  //     path: '/pages/transacciones',
  //   },
  //   {
  //     idOption: '15',
  //     alt: 'Estudio de cliente',
  //     img: 'icon-client-study.svg',
  //     path: '/pages/estudio-de-cliente',
  //   },
  //   {
  //     idOption: '8',
  //     alt: 'Operaciones',
  //     img: 'icon-operations.svg',
  //     path: '/pages/operaciones',
  //   },
  //   {
  //     alt: 'Reclamaciones',
  //     img: 'icon-claims.svg',
  //     path: '/pages/reclamaciones',
  //   },
  //   {
  //     idOption: '10',
  //     alt: 'Reintegros',
  //     img: 'icon-claims.svg',
  //     path: '/pages/reintegros',
  //   },
  //   {
  //     idOption: '21',
  //     alt: 'Acuerdos de pago',
  //     img: 'icon-agreements.svg',
  //     path: '/pages/acuerdos-de-pago',
  //   },
  //   {
  //     idOption: null,
  //     alt: 'Reportes',
  //     img: 'icon-reports.svg',
  //     path: '/pages/reportes',
  //   },
  //   {
  //     idOption: null,
  //     alt: 'Administración',
  //     img: 'icon-administration.svg',
  //     path: '/pages/administracion/mis-pagos',
  //     subItems: [
  //       {
  //         idOption: '11',
  //         alt: 'Mis pagos',
  //         img: 'icon-my-payments.svg',
  //         path: '/pages/administracion/mis-pagos',
  //       },
  //       {
  //         idOption: '13',
  //         alt: 'Usuarios',
  //         img: 'icon-users.svg',
  //         path: '/pages/administracion/usuarios',
  //       },
  //       {
  //         idOption: '12',
  //         alt: 'Documentos',
  //         img: 'icon-documents.svg',
  //         path: '/pages/administracion/documentos',
  //       },
  //     ],
  //   },
  // ];

  public navigation = <any>[
    // {
    //   idOption: "21",
    //   alt: "Afiliados",
    //   img: "icon-agreements.svg",
    //   path: "/pages/#",
    // },
    // {
    //   alt: "Compradores",
    //   img: "icon-claims.svg",
    //   path: "/pages/#",
    // },
    // {
    //   idOption: "15",
    //   alt: "Cupos",
    //   img: "icon-client-study.svg",
    //   path: "/pages/#",
    // },
    // {
    //   idOption: null,
    //   alt: "Transacciones",
    //   img: "icon-transactions.svg",
    //   path: "/pages/#",
    // },
    {
      idOption: "8",
      alt: "Respaldos",
      img: "icon-operations.svg",
      path: "/pages/respaldos",
      subItems: [
        {
          idOption: '11',
          alt: 'Mis pagos',
          img: 'icon-my-payments.svg',
          path: '/pages/administracion/mis-pagos',
        },
      ]
    },
    // {
    //   idOption: "8",
    //   alt: "Example Page",
    //   img: "icon-operations.svg",
    //   path: "/login",
    // },
    // {
    //   idOption: "8",
    //   alt: "Example Page",
    //   img: "icon-operations.svg",
    //   path: "/login",
    // },

    // {
    //   idOption: null,
    //   alt: "Reportes",
    //   img: "icon-reports.svg",
    //   path: "/pages/reportes",
    // },
    // {
    //   idOption: null,
    //   alt: "Administración",
    //   img: "icon-administration.svg",
    //   path: "/pages/administracion/mis-pagos",
    //   subItems: [
    //     {
    //       idOption: "11",
    //       alt: "Mis pagos",
    //       img: "icon-my-payments.svg",
    //       path: "/pages/administracion/mis-pagos",
    //     },
    //     {
    //       idOption: "13",
    //       alt: "Usuarios",
    //       img: "icon-users.svg",
    //       path: "/pages/administracion/usuarios",
    //     },
    //     {
    //       idOption: "12",
    //       alt: "Documentos",
    //       img: "icon-documents.svg",
    //       path: "/pages/administracion/documentos",
    //     },
    //   ],
    // },
  ];

  public collapseMenu: Observable<boolean> = this.subject.stateMenu$;
  public showSubMenu: boolean = false;
  public permissions: Array<any>;
  public identity: any;
  public constants = CONSTANTS;
  public innerWidth: number;
  public onLaptopBreakpoint: boolean = true;
  public onMobileBreakpoint: boolean = false;
  public showMenu: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.updatedResize();
  }

  constructor(
    private modal: ModalService,
    public subject: SubjectService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.updatedResize();
    this.handlePermissions();



  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  updatedResize() {
    this.innerWidth = window.innerWidth;
    this.onLaptopBreakpoint =
      this.innerWidth > 200 && this.innerWidth < 1600 ? true : false;
    this.onMobileBreakpoint =
      this.innerWidth > 0 && this.innerWidth < 768 ? true : false;
    this.subject.menuCollapse(this.onLaptopBreakpoint);
    this.subject.isMobile(this.onMobileBreakpoint);
  }

  handlePermissions() {

  }

  handleDropdown(item?: string, subItems?: any) {
    this.showSubMenu = item === "Administración" ? true : false;
    if (
      (this.onMobileBreakpoint && !this.showSubMenu) ||
      (this.onMobileBreakpoint && subItems)
    ) {
      this.collapseSidebar();
    }
  }

  enter(path: string) {
    this.collapseMenu.subscribe((show: boolean) => (this.showMenu = show));
    if (this.onLaptopBreakpoint && this.showMenu) {
      this.subject.sendText(path);
    }
  }

  leave() {
    if (this.onLaptopBreakpoint && this.showMenu) {
      this.subject.sendText("");
    }
  }

  showModalLogOut() {
    this.modal.show("logOut");
  }

  collapseSidebar() {
    this.showMenu = !this.showMenu;
    this.subject.menuCollapse(this.showMenu);
  }
}
