import { createReducer, on } from "@ngrx/store";
import { add, edit } from "../actions/todo.action";

const initialState: any[] = [
    // new TodoModel("Todo 1"),
    // new TodoModel("Todo 2"),
    // new TodoModel("Todo 3"),
    // new TodoModel("Todo 4"),
];

export const _todoReducer = createReducer(initialState,
    // on(add, (state, { text }) => [...state, new TodoModel(text)]),
    // on(edit, (state, { completed }) => [...state.map((todo: TodoModel) => todo)])
);

export function todoReducer(state, action) {
    return _todoReducer(state, action);
}