import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ClientStudyRequest } from "@app/models/clientStudyRequest.model";
import { HelperService } from "@app/services/helper.service";
import { SelectsService } from "@app/services/selects.service";
import { SubjectService } from "@app/services/subject.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-partner-information",
  templateUrl: "./partner-information.component.html",
  styleUrls: ["./partner-information.component.scss"],
})
export class PartnerInformationComponent implements OnInit {
  public isInformationCopied: boolean[] = [false];
  public firstName: string;
  public secondName: string;

  public validatePatternDocument: RegExp[] = [];

  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();

  @Input() documentTypesOptions: Array<any>;
  @Input() clientStudyForm: ClientStudyRequest;

  @Output() formPartnerFirstValidate: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild("formPartnerFirst") formPartnerFirst: NgForm;

  public dataPartner = [
    {
      tipo_documento: "",
      numero_documento: "",
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
    },
  ];

  constructor(
    private toastr: ToastrService,
    private helper: HelperService,
    private subject: SubjectService,
    public select: SelectsService
  ) {}

  ngOnInit() {
    this.clientStudyForm.StrJArray[0].tipo_documento_socio = 'C';

    this.subject.stateLegalSecondName$.subscribe((secondName) => {
      this.secondName = secondName;
    });

    this.subject.stateLegalFirstName$.subscribe((firstName) => {
      this.firstName = firstName;
    });
  }

  copyInfo(index: number) {
    this.clientStudyForm.StrJArray[index].nom1_socio = this.firstName;
    this.clientStudyForm.StrJArray[index].nom2_socio = this.secondName;
    this.clientStudyForm.StrJArray[index].apel1_socio = this.clientStudyForm.apel1_rep;
    this.clientStudyForm.StrJArray[index].apel2_socio = this.clientStudyForm.apel2_rep;
    this.clientStudyForm.StrJArray[index].tipo_documento_socio = this.clientStudyForm.tipo_doc_rep;
    this.clientStudyForm.StrJArray[index].documento_socio = this.clientStudyForm.doc_rep;
    this.isInformationCopied[index] = (this.isInformationCopied[index] === false) ? true : true;
    
    this.handleValidateFirstPartner();
  }

  addPartner() {
    const partner = {
      tipo_documento_socio: "C",
      documento_socio: "",
      nom1_socio: "",
      nom2_socio: "",
      apel1_socio: "",
      apel2_socio: "",
    };

    if (this.clientStudyForm.StrJArray.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 socios.", null);
      return;
    }
    this.clientStudyForm.StrJArray.push(partner);
    this.handleValidateFirstPartner();
  }

  deletePartner() {
    const partner = {
      tipo_documento_socio: "",
      documento_socio: "",
      nom1_socio: "",
      nom2_socio: "",
      apel1_socio: "",
      apel2_socio: "",
    };

    if (this.clientStudyForm.StrJArray.length === 1) {
      this.clientStudyForm.StrJArray[0] = partner;
      return;
    }

    this.clientStudyForm.StrJArray.splice(
      this.clientStudyForm.StrJArray.length - 1,
      1
    );
    this.handleValidateFirstPartner();
  }

  handleValidateFirstPartner(documentType?: string, idx?: number) {
    
    if (documentType && idx>=0 ) {
      this.validatePatternDocument[idx] = this.helper.validatePatternDocument(documentType);
    }

    setTimeout(() => {
      const valid = this.formPartnerFirst.form.valid;
      this.formPartnerFirstValidate.emit(valid);
    }, 1);
  }
}
