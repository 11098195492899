import { Component, Input, OnInit } from "@angular/core";
import { ClientStudyRequest } from "@app/models/clientStudyRequest.model";
import { TabsService } from "@app/services/tabs.service";
import { SubjectService } from "@app/services/subject.service";

@Component({
  selector: "app-person-type",
  templateUrl: "./person-type.component.html",
  styleUrls: ["./person-type.component.scss"],
})
export class PersonTypeComponent implements OnInit {
  @Input() clientStudyForm: any;
  @Input() clientStudyFormEmpty: ClientStudyRequest;
  public isInputChecked: boolean;
  public stepTypePerson: string;

  constructor(private tabs: TabsService, private subject: SubjectService) {}

  ngOnInit() {
    this.subject.stateTypePerson$.subscribe((resp) => {
      this.stepTypePerson = resp;
    });
  }

  changeTypePerson(type: string) {
    this.subject.changeTypePerson(type);
    this.subject.changeStateAddress({
      addressType: '',
      addressFirst: '',
      addressSecond: '',
      addressThird: '',
      addressOther: '',
    });
    this.subject.changeStateChannel({
      whatsapp: false,
      sms: false,
      correo: false,
      llamada: false,
    });
    this.subject.changeFirstCommercialDepartment({ID: '', LABEL: ''});
    this.subject.changeFirstCommercialCity({ID: '', LABEL: ''});
    this.subject.changeStateFirstMunicipalities([]);
    
  }
}
