import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Owner } from '@models/owner.model';
import { HelperService } from '@services/helper.service';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';

@Component({
  selector: 'app-owner-information-form',
  templateUrl: './owner-information-form.component.html',
  styleUrls: ['./owner-information-form.component.scss'],
})
export class OwnerInformationFormComponent {
  @Input() clientStudyForm: ClientStudyRequest;
  @Input() documentTypesOptions: Array<any>;
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();

  constructor(public helper: HelperService) {}

  copyInfo() {
    this.clientStudyForm.owners[0].documentType = this.clientStudyForm.legalRepresentativeDocumentType;
    this.clientStudyForm.owners[0].documentNumber = this.clientStudyForm.legalRepresentativeDocumentNumber;
    this.clientStudyForm.owners[0].firstLastName = this.clientStudyForm.legalRepresentativeFistLastName;
    this.clientStudyForm.owners[0].firstName = this.clientStudyForm.legalRepresentativeFirstName;
    this.clientStudyForm.owners[0].secondName = this.clientStudyForm.legalRepresentativeSecondName;
    this.clientStudyForm.owners[0].secondLastName = this.clientStudyForm.legalRepresentativeSecondLastName;
  }

  onBlur($event) {
    this.valueChanges.emit();
  }

  add() {
    const owner: Owner = {
      documentType: '',
      documentNumber: '',
      firstName: '',
      secondName: '',
      firstLastName: '',
      secondLastName: '',
    };
    this.clientStudyForm.owners.push(owner);
  }

  delete(i: number) {
    this.clientStudyForm.owners.splice(i, 1);
  }
}
