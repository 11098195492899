import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-success-content',
  templateUrl: './modal-success-content.component.html',
  styleUrls: ['./modal-success-content.component.scss']
})
export class ModalSuccessContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
