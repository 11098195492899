
export class ClientStudyNaturalModel {
    clientType: string;

    usuario: string;
    id_pais: string;
    tipo_documento: string;
    documento: string;
    /* Refactoring */
    comprador_tipo_documento: string;
    comprador_documento: string;
    nombre_empresa: string;
    tipo_doc_rep: string;
    doc_rep: string;
    nombres_rep: string;
    apel1_rep: string;
    apel2_rep: string;
    fecha_creacion: Date; /* 19900101 */
    inicio_actividad: any;
    act_economica: any;
    canal_whatsapp: string;
    canal_correo: string;
    canal_sms: string;
    canal_llamada: string;
    tipo_empresa: string;
    fecha_expedicion: Date;
    lista_municipio_expedicion: string;
    celular: string;
    correo: string;
    fecha_nacimiento: string;
    direccion: string;
    telefono: string;
    valor_cupo: string;

    tipo_ref_1: string;
    nombre1_ref_1: string;
    nombre2_ref_1: string;
    apellido1_ref_1: string;
    apellido2_ref_1: string;
    typeContactRef: string;
    telefono_ref_1: string;
    correo_ref_1: string;
    lista_cuidad_ref_1: string;

    tipo_ref_2: string;
    nombre1_ref_2: string;
    nombre2_ref_2: string;
    apellido1_ref_2: string;
    apellido2_ref_2: string;
    telefono_ref_2: string;
    correo_ref_2: string;
    lista_cuidad_ref_2: string;

    nom1_contacto: string;
    nom2_contacto: string;
    apel1_contacto: string;
    apel2_contacto: string;
    cel_contacto: string;
    lista_municipio: string;
    cargo_contacto: string;
    correo_contacto: string;

    StrJArray: [{
        tipo_documento_socio: string;
        documento_socio: string;
        nom1_socio: string;
        nom2_socio: string;
        apel1_socio: string;
        apel2_socio: string;
      }
    ];


    /*  */
    
    /* Step 1 */
    firstName: string;
    secondName: string;
    lastFirstName: string;
    secondLastName: string;
    sellerDocumentType: string;
    sellerDocumentNumber: string;
    expeditionDate: Date;
    expeditionDepartment: string;
    expeditionCity: string;
    cellular: string;
    email: string;

    /* Step 2 */

    tellphone: string;
    addressType: string;
    addressFirst: string;
    addressSecond: string;
    addressThird: string;
    addressOther: string;
    homeDepartment: string;
    homeCity: string;
    /* initialDate: string; */
    pointsOfSale: string;
    quotaValue: string;
    modeWP: boolean;
    modeEmail: boolean;
    modeSMS: boolean;
    modePhone: boolean;

    /* Step 3 */

     /* Legal */

    commercialInformation: CommercialLegalInformation[] & CommercialNaturalInformation[];

    /* Informacion switches */

    recursos_publicos?: boolean;
    reconocimiento_politico?: boolean;
    poder_publico?: boolean;
  /* pep?: boolean;
  exposedPerson?: boolean;
  foreignCurrency?: boolean;  Añadir a nivel de formulario*/

  /* Informacion de referencia comercial */

  /* TODO: Investigar para que sirve esto */

  administradores_PEP: ContrapartKnowledgePerson[];

  vinculos_PEP: ContrapartKnowledgePerson[];

  transacciones_extranjeria: {
    tipo_operacion: string;
    descripcion: {
      nombre_entidad: string;
      tipo_producto: string;
      monto_promedio: string;
      moneda: string;
      ciudad: string;
      pais: string;
    }
  }

    foreignCurrencyOperationType?: string;
    foreignCurrencyOtherOperationType?: string;
    foreignCurrencyIdentityName?: string;
    foreignCurrencyTypeAccount?: string;
    foreignCurrencyOtherIdentity?: string;
    foreignCurrencyMonthlyAmount?: string;
    foreignCurrencyCoin?: string;
    foreignCurrencyCity?: string;
    foreignCurrencyCountry?: string;




    /*  */
    
}

export class CommercialLegalInformation {
    commercialLegalSocialReason?: string;
    commercialLegalDepartment?: string;
    commercialLegalCity?: string;
    commercialLegalTelephone?: string;
    commercialLegalEmail?: string;
    commercialNaturalEmail?: string;
}

export class CommercialNaturalInformation {
    commercialNaturalFirstName?: string;
    commercialNaturalSecondName?: string;
    commercialNaturalFirstLastName?: string;
    commercialNaturalSecondLastName?: string;
    commercialNaturalDepartment?: string;
    commercialNaturalCity?: string;
    commercialNaturalTellphone?: string;
    commercialNaturalEmail?: string;
}

export class ContrapartKnowledgePerson {
    nombres: string;
    tipo_documento: string;
    documento: string;
    cotiza_bolsa: string;
}