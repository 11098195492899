import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from './storage.service';
import { OlimpiaService } from './olimpia.service';
import { SubjectPromissoryService } from './subject.service';
import { HelperDocumentsService } from '../utils/helper-documents.service';
import { StartProcessRequest, StartProcessResponse } from '../models/start-process.model';
import { UserInfo } from '../models/user-info.model';
import { ModalsService } from '../utils/modals.service';

@Injectable({
  providedIn: 'root'
})
export class StartProcessService {

  private storage: StorageService = new StorageService(true)

  constructor(
    private olimpiaService: OlimpiaService,
    private spinner: NgxSpinnerService,
    private modals: ModalsService,
    private subjectService: SubjectPromissoryService,
    private helperDocuments: HelperDocumentsService
  ) {
    
  }

  startProcess() {
    this.spinner.show('startProcess');
    const request: StartProcessRequest = this.buildRequestToStartProcess();

    this.olimpiaService.startProcess(request).subscribe({
      next: (response: StartProcessResponse) => {
        console.log('Inicio de proceso exitoso....', response);
        this.storage.setStorage('idProcesoClienteExterno', request.idProcesoClienteExterno);
        this.spinner.hide('startProcess');
        this.subjectService.setCallPreviewOfDocument(true);
      },
      error: (error: any) => {
        console.error('Ocurrió un error al momento de iniciar el proceso: ', error);
        this.spinner.hide('startProcess');
        this.modals.show('modalStartProcessError');
        this.storage.clearStorage();
      }
    })
  }

  buildRequestToStartProcess(): StartProcessRequest {
    const userInfo: UserInfo =  JSON.parse(sessionStorage.getItem('userInfo_ID') || '{}');
    const { primerNombre, segundoNombre, primerApellido, segundoApellido, celular, email, numDoc, tipoDoc } = userInfo;
    const idProcesoClienteExterno: string = `${numDoc}${new Date().getTime()}`; // CC+getTime();
    const names = `${primerNombre} ${segundoNombre}`.trim().replace('  ', ' ');
    const lastnames = `${primerApellido} ${segundoApellido}`.trim().replace('  ', ' ');
    const tipoIdentificacion = this.helperDocuments.findDocumentByLetters(tipoDoc)["number_code"] || -1;

    const request: StartProcessRequest = new StartProcessRequest(idProcesoClienteExterno, names, lastnames, celular, email, numDoc, tipoIdentificacion);
    return request;
  }
}
