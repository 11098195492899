import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-error-message-modal',
  templateUrl: './error-message-modal.component.html',
  styleUrls: ['./error-message-modal.component.scss']
})

export class ErrorMessageModalComponent implements OnInit {

  public errorMessage: string;
  public isInvalidDataError: boolean;
  public linkToCommerce: string;
  public isLinkToCommerce: boolean = true;

  constructor(
    @Inject(
      DOCUMENT) private document: any, 
      public modal: ModalService, 
      private subject: SubjectService, 
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private location: Location
      ) { 
    this.linkToCommerce = localStorage.getItem('CFCDUrlCommerce');
    
  }

  refresh(): void {
    const currentUrl = this.router.url;
    const params = this.activatedRoute.snapshot.queryParams;
    this.location.replaceState(currentUrl, JSON.stringify(params));
    this.subject.changeStep(1);
    window.location.reload();
  }

  ngOnInit() {
    this.subject.stateMessageError$.subscribe((message) => {
      this.errorMessage = message;
    });

    this.subject.stateInvalidDataError$.subscribe((isInvalidDataError) => {
      this.isInvalidDataError = isInvalidDataError;
    });
  }

  goToCommerce() {
    if(this.errorMessage !== "") {
      if(this.errorMessage.includes("Ya tiene una solicitud en proceso")) {
        this.isLinkToCommerce = false;
        
        this.refresh();
        return;
      } else {
        this.isLinkToCommerce = true;
      }
    } else {
      this.isLinkToCommerce = false;
    }
    if(!this.isLinkToCommerce) {
      const linkToCommerce = localStorage.getItem('CFCDUrlCommerce');
      this.document.location.href = linkToCommerce.replace('"', '').replace('"', '');
      this.subject.changeStep(1);
    }
  }
}
