import { IDENTIFICACION_COMERCIO, ID_TIPO_ENDOSO, TIPO_PRODUCTO } from "../constants/olimpia.constants";

export class StartProcessRequest {
    public identificacionComercio:  string = '';
    public idProcesoClienteExterno: string = '';
    public nombres:                 string = '';
    public apellidos:               string = '';
    public celular:                 string = '';
    public correo:                  string = '';
    public identificacion:          string = '';
    public tipoIdentificacion:      number =  0;
    public idTipoEndoso:            number =  0;
    public tipoProducto:            string = '';
    constructor(idProcesoClienteExterno: string, nombres: string, apellidos: string,
        celular: string, correo: string, identificacion: string, tipoIdentificacion: number) {

        this.idProcesoClienteExterno = idProcesoClienteExterno;
        this.nombres = nombres;
        this.apellidos = apellidos;
        this.celular = celular;
        this.correo = correo;
        this.identificacion = identificacion;
        this.tipoIdentificacion = tipoIdentificacion;
        this.identificacionComercio = IDENTIFICACION_COMERCIO;
        this.idTipoEndoso = ID_TIPO_ENDOSO;
        this.tipoProducto = TIPO_PRODUCTO;
    }
}


export class StartProcessResponse {
    public ok!:      boolean;
    public message!: string;
    public data!:    StartProcessResponseModel;
    public status!:  number;
}

export class StartProcessResponseModel {
    public message!:   string[];
    public tipo!:      string;
    public titulo!:    string;
    public status!:    number;
    public detalle!:   string;
    public instancia!: string;
}