import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubjectService } from '@app/services/subject.service';
import { StorageService } from '../services/storage.service';
import { OlimpiaService } from '../services/olimpia.service';
import { IssuePromissoryNoteService } from '../services/issue-promissory-note.service';
import { ModalsService } from '../utils/modals.service';
import { SubjectPromissoryService } from '../services/subject.service';
import { QuotaNaturalService } from '../services/quota-natural.service';
import { QuotaLegalService } from '../services/quota-legal.service';
import { QuotaRequestService } from '../services/quota-request.service';



@Component({
  selector: 'app-card-otp',
  templateUrl: './card-otp.component.html',
  styleUrls: ['./card-otp.component.scss']
})
export class CardOtpComponent  implements OnDestroy {

  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private storage: StorageService = new StorageService(true);
  public modalName: string = 'modalOtp';
  private personType: string = '';

  constructor(
    private spinner: NgxSpinnerService,
    private olimpiaService: OlimpiaService,
    private issuePromissoryService: IssuePromissoryNoteService,
    private modals: ModalsService,
    private subjectPromissoryService: SubjectPromissoryService,
    private subjectService: SubjectService,
    private quotaNaturalService: QuotaNaturalService,
    private quotaLegalService: QuotaLegalService,
    private quotaRequestService: QuotaRequestService
  ) {
    this.subjectPromissoryService.callIssuePromissoryNote$.subscribe(this.handleCallIssuePromissoryNote.bind(this));

    this.subjectService.stateTypePerson$.subscribe(this.handlePersonTypeSubs.bind(this));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleCallIssuePromissoryNote(value: boolean) {
    if (value) this.issuePromissoryService.issuePromissoryNote();
  }

  handlePersonTypeSubs(personType: string) {
    this.personType = personType;
  }

  handlevalidateOtp(otpCode: string) {
    console.log('Se  presionó el botón de validar OTP con la persona de tipo: ', this.personType);
    this.quotaRequestService.SignOtp(otpCode);
    
    // if (this.personType === 'natural') {
    //   console.log('//////////////////////////// validando un otp de una persona natural');      
    //   this.quotaNaturalService.handleSignOtp(otpCode);
    //   return;
    // }

    // if (this.personType === 'legal') {
    //   console.log('//////////////////////////// validando un otp de una persona legal');      
    //   this.quotaLegalService.handleSignOtp(otpCode);
    //   return;
    // }
  }

}
