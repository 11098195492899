import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { BackEndService } from '@app/services/back-end.service';
import { SubjectService } from '@app/services/subject.service';
import { AnalyticsService } from '@app/services/analytics.service';
import { ClientStudyNaturalModel } from '@app/models/clientStudyNaturalRequest.model';
import { ClientStudyRequest } from '@app/models/clientStudyRequest.model';
import { StorageService } from './storage.service';
import { ModalsService } from '../utils/modals.service';
import { OlimpiaService } from './olimpia.service';
import { PromissoryNotePreviewService } from './promissory-note-preview.service';
import { SubjectPromissoryService } from './subject.service';
import { CovifacturaService } from './covifactura.service';
import { SignatureValidateOtpRequest, SignatureValidateOtpResponse } from '../models/signature-validate-otp.model';
import { QuotaRequestResponse } from '../models/quota-request.model';


@Injectable({
  providedIn: 'root'
})
export class QuotaRequestService {


  // 
  public storage: StorageService = new StorageService(true);
  public personType: string = '';
  public codeInfo: any;

  public finalForm: ClientStudyNaturalModel | ClientStudyRequest;

  constructor(
    // @Inject(DOCUMENT) private document: any,
    public modals: ModalsService,
    // public helper: HelperService,
    public backend: BackEndService,
    private olimpiaService: OlimpiaService,
    public subject: SubjectService,
    public spinner: NgxSpinnerService,
    // public toastr: ToastrService,
    private promissoryNoteService: PromissoryNotePreviewService,
    private subjectPromissoryService: SubjectPromissoryService,
    private covifacturaService: CovifacturaService,
    private analytics: AnalyticsService
  ) {
    this.subject.stateTypePerson$.subscribe(this.handlePersonTypeSubs.bind(this));
  }

  handlePersonTypeSubs(personType: string) {
    this.codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));
    this.personType = personType;
  }

  SignOtp(otpCode: string) {
    this.finalForm = this.personType === 'natural'
      ? this.finalForm = { ...this.promissoryNoteService.getDataPreRequest() } as ClientStudyNaturalModel
      : { ...this.promissoryNoteService.getDataPreRequest() } as ClientStudyRequest;

    this.handleValidateOtpCode(otpCode);
  }

  handleValidateOtpCode(otpCode: string) {
    const request: SignatureValidateOtpRequest = this.buildRequestToValidateOtp(otpCode);
    this.spinner.show('validatingOtp');

    this.olimpiaService.validateOtp(request).subscribe({
      next: (resp: SignatureValidateOtpResponse) => {
        this.spinner.hide('validatingOtp');
        this.handleValidateOtpCodeOk(resp);
      },
      error: (err: any) => {
        this.handleValidateOtpCodeInvalid(err);
        this.spinner.hide('validatingOtp');
      }
    })
  }

  buildRequestToValidateOtp(otpCode: string): SignatureValidateOtpRequest {
    const idTransaccion: string = this.storage.getStorage('transaccionId') || '';
    const numeroCelular: string = this.storage.getStorage('preDataAdapater')["celular"] || '-1';
    const otpNumero: string = otpCode;

    const request: SignatureValidateOtpRequest = { idTransaccion, numeroCelular, otpNumero };
    return request;
  }

  handleQuotaRequest(bodySolicitud, counterpartValidate) {
    console.log('Se inicia la solicitud de cupo.....', bodySolicitud, counterpartValidate);
    this.spinner.show('requestingQuota');
    this.covifacturaService.saveDocumentCoviresource();
    this.backend.solicitudCupo(bodySolicitud, counterpartValidate).subscribe(
      (resp: QuotaRequestResponse) => {
        console.log('Response de solicitud de cupo...', resp);
        this.handleQuotaRequestOk(resp);
      },
      (err) => {
        this.handleQuotaRequestError(err);
      },
      () => {
        this.spinner.hide('requestingQuota');
      }
    );
  }

  //  Razón 0:Aprobada	      | Contraparte
  //  Razón 1:Negado	        | Contraparte
  //  Razón 2:Proceso	        | Contraparte
  //  Razón 3:Datos Inválidos	| N/A
  handleQuotaRequestOk(response: QuotaRequestResponse): void {
    this.subjectPromissoryService.setResponseQuotaRequest(response);
    this.covifacturaService.saveDocumentCoviresource(response["ESTUDIO_CUPO "] || '');
    this.analytics.trackWebEvent('LinkCupoSolicitarCupo_Ok');

    const reason: number = response["RAZON "] || -1;
    if (reason === 0) {
      this.modals.show('modalQuotaRequestApproved');
      return;
    }

    if (reason === 1) {
      this.modals.show('modalQuotaRequestDenied');
      return;
    }

    if (reason === 2) {
      this.modals.show('modalQuotaRequestInProcess');
      return;
    }

    // if (reason === 3) {}
    this.modals.show('modalQuotaRequestInvalid');
  }

  handleQuotaRequestError(err: any) {
    console.log('Error en la solicitud de cupo', err);
    // const error = $(err.error)[7];
    // const hasCustomError: boolean = error && error.lastChild && error.lastChild.innerText;
    // const errorMessage = hasCustomError ? error.lastChild.innerText : 'Ocurrió un error al solicitar cupo';

    const errorMessage = `${err.status || "ANY ERROR"} - ${err.error.message || err.statusText}`;

    this.subjectPromissoryService.setErrorQuotaRequest(errorMessage);
    this.modals.show('modalQuotaRequestError');
    this.spinner.hide('requestingQuota');
    this.analytics.trackWebEvent('LinkCupoSolicitarCupo_Error');
  }

  handleValidateOtpCodeOk(resp: SignatureValidateOtpResponse) {
    // this.toastr.success(resp.data.mensaje);
    console.log('//DESDE EL SERVICIO CUSTOM', this.finalForm);
    
    const bodySolicitud = this.backend.parseRequestBody(this.finalForm);
    const counterpartValidate = this.backend.parseCounterpartKnowledge(this.finalForm, resp);

    this.handleQuotaRequest(bodySolicitud, counterpartValidate);
    this.analytics.trackWebEvent('LinkCupoFirmar_Ok');
  }

  handleValidateOtpCodeInvalid(err: any) {
    this.modals.show('modalValidateOtpError');
    this.analytics.trackWebEvent('LinkCupoFirmar_Error');
  }
}
