import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ClientStudyNaturalModel } from "@app/models/clientStudyNaturalRequest.model";
import { ClientStudyRequest } from "@app/models/clientStudyRequest.model";
import { BackEndService } from "@app/services/back-end.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { SubjectService } from "@app/services/subject.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Component({
  selector: "app-modal-counterpart-knowledge",
  templateUrl: "./modal-counterpart-knowledge.component.html",
  styleUrls: ["./modal-counterpart-knowledge.component.scss"],
})
export class ModalCounterpartKnowledgeComponent implements OnInit {
  public OtpForm: any;
  public check: boolean = false;
  public checkedSend: boolean = false;
  public message: string;
  public reason: number;
  public description: string;
  public typePerson: string;
  public requestNumber: string;


  @Input() modalName: string = "";
  @Input() clientStudyForm: ClientStudyRequest;
  @Input() clientStudyNaturalForm: ClientStudyNaturalModel;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public modal: ModalService,
    public helper: HelperService,
    public backend: BackEndService,
    public subject: SubjectService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {

    this.OtpForm = {
      firstNumber: "",
      secondNumber: "",
      thirdNumber: "",
      lastNumber: "",
    };

  }
  public sentOtp: boolean = false;

  ngOnInit() {
    this.sentOtp = false;
    this.subject.stateSentCoupon$.subscribe((resp) => {
      this.check = resp;
    });
    this.check = false;
    this.reason = 0;
  }

  refresh(): void {
    const currentUrl = this.router.url;
    const params = this.activatedRoute.snapshot.queryParams;
    this.location.replaceState(currentUrl, JSON.stringify(params));
    window.location.reload();
  }

  resendOtp() {
    this.checkedSend = true;
    this.subject.stateTypePerson$.subscribe((resp) => {
      if (resp === "legal") {
        this.spinner.show();
        const bodyGenerateOtp = {
          tipo_documento_comprador:
            this.clientStudyForm.comprador_tipo_documento !== "C" &&
              this.clientStudyForm.comprador_tipo_documento !== "E"
              ? "N"
              : this.clientStudyForm.comprador_tipo_documento,
          documento_comprador: this.clientStudyForm.comprador_documento,
          correo: this.clientStudyForm.correo,
          celular: this.clientStudyForm.celular,
        };

        this.backend.generateOtpCode(bodyGenerateOtp).subscribe(
          (resp) => {
            this.checkedSend = false;
            this.subject.changeStateSentCoupon(false);
            this.sentOtp = true;
          },
          (err) => {
            const errorMessage = `${err.status || "ANY ERROR"} - ${ err.error.message || err.statusText}`;
            this.modal.close();
            this.subject.changeStateInvalidDataError(true);
            this.subject.changeStateMessageError(errorMessage);
            this.modal.show("errorMessage");
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
            this.checkedSend = false;
          }
        );
      } else {
        this.spinner.show();
        const bodyGenerateOtp = {
          tipo_documento_comprador:
            this.clientStudyNaturalForm.comprador_tipo_documento !== "C" &&
              this.clientStudyNaturalForm.comprador_tipo_documento !== "E"
              ? "N"
              : this.clientStudyNaturalForm.comprador_tipo_documento,
          documento_comprador: this.clientStudyNaturalForm.comprador_documento,
          correo: this.clientStudyNaturalForm.correo,
          celular: this.clientStudyNaturalForm.celular,
        };

        this.backend.generateOtpCode(bodyGenerateOtp).subscribe(
          (resp) => {
            this.checkedSend = false;
            this.subject.changeStateSentCoupon(false);
            this.sentOtp = true;
          },
          (err) => {
            const errorMessage = `${err.status || "ANY ERROR"} - ${ err.error.message || err.statusText}`;
            this.modal.close();
            this.subject.changeStateInvalidDataError(true);
            this.subject.changeStateMessageError(errorMessage);
            this.modal.show("errorMessage");
            this.spinner.hide();
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
            this.checkedSend = false;
          }
        );
      }
    });
  }

  toSecondFocus() {
    if (this.OtpForm.firstNumber !== '') {
      document.getElementById("secondNumber").focus();
    }

  }

  toThirdFocus() {
    if (this.OtpForm.secondNumber !== '') {
      document.getElementById("thirdNumber").focus();
    }

  }

  toLastFocus() {
    if (this.OtpForm.thirdNumber !== '') {
      document.getElementById("lastNumber").focus();
    }
  }

  resetPage() {
    this.refresh();
  }

  resetForm() {
    this.OtpForm.firstNumber = '';
    this.OtpForm.secondNumber = '';
    this.OtpForm.thirdNumber = '';
    this.OtpForm.lastNumber = '';
  }

  sendToOTP() {
    this.document.location.href = 'https://clientes.covifactura.com/compradores/login';
  }

  closeModal() {
    this.modal.close();
  }

  signOtp() {
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));
    this.spinner.show();

    this.subject.stateTypePerson$.subscribe((typePerson) => {
      const formType = typePerson === "legal" ? this.clientStudyForm : this.clientStudyNaturalForm;
      const tipo_documento_comprador = formType.comprador_tipo_documento !== "C" && formType.comprador_tipo_documento !== "E"
        ? "N"
        : formType.comprador_tipo_documento;

      const body = {
        tipo_documento_comprador,
        documento_comprador: formType.comprador_documento,
        textoOTP: `${this.OtpForm.firstNumber}${this.OtpForm.secondNumber}${this.OtpForm.thirdNumber}${this.OtpForm.lastNumber}`,
        nombres: `${formType.nombres_rep} ${formType.apel1_rep}`,
        aplicativo: `LINK_AFILIADO`,
        tipo_documento: codeInfo.afiliado_tipo_documento,
        documento: codeInfo.afiliado_documento,
        id_pais: codeInfo.id_pais,
      };

      this.backend.validateOtpCode(body).subscribe(
        (responseOtp: any) => {
          if (responseOtp.data.valido === true) {
            this.toastr.success(responseOtp.data.mensaje);

            const bodySolicitud = this.backend.parseRequestBody(formType);
            const counterpartValidate = this.backend.parseCounterpartKnowledge(formType, responseOtp);

            this.backend.solicitudCupo(bodySolicitud, counterpartValidate).subscribe(
              (respQuota) => {
                console.log("POR AQUI ENTRO")
                this.handleResponse(respQuota);
                this.subject.changeStateSentCoupon(true);
              },
              (err) => {
                this.handleError(err);
                this.subject.changeStateSentCoupon(true);
              },
              () => {
                this.spinner.hide();
              }
            );
          } else {
            this.toastr.error("Ocurrió un error al validar el código OTP", "Error al ingresar el código");
            this.spinner.hide();
          }
        },
        (err) => {
          this.resetForm();
          this.toastr.error(err.error.data.mensaje, "Error al ingresar el código");
          this.subject.changeStateInvalidDataError(true);
          this.spinner.hide();
        }
      );
    });
  }

  handleResponse(resp) {
    this.message = resp["MENSAJE_EXTERNO "];
    this.reason = resp["RAZON "];
    this.description = resp["DESCRIPCION"];
    this.requestNumber = resp["ESTUDIO_CUPO "];

    if (this.reason === 0 || this.reason === 1 || this.reason === 2) {
      this.subject.changeStateSentCoupon(true);
    } else if (this.reason === 3) {
      this.modal.close();
      this.subject.changeStateInvalidDataError(true);
      this.subject.changeStateMessageError(this.message);
      this.modal.show("errorMessage");
      this.spinner.hide();
    }
  }

  handleError(err) {
    const errorMessage = `${err.status || "ANY ERROR"} - ${ err.error.message || err.statusText}`
    this.modal.close();
    this.subject.changeStateInvalidDataError(true);
    this.subject.changeStateMessageError(errorMessage);
    this.modal.show("errorMessage");
    this.spinner.hide();
  }

  closeOtpSent() {
    this.sentOtp = false;
  }
}
