import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalsService } from '../utils/modals.service';

export class PaddingModal {
  public general?: string;
  public header?: string;
  public body?: string
  public footer?: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() modalName: string = '';
  @Input() showHeader: boolean = true;
  @Input() showBody: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() showClose: boolean = true;
  @Input() size: string = 'modal-lg';
  @Input() extraClass: string = '';
  @Input() extraClassForBtnClose: string = '';
  @Input() padding: PaddingModal = new PaddingModal();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public modal: ModalsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    // this.close();
  }

  goToStart() {
    this.close();
    this.router.navigateByUrl('');
  }

  close() {
    this.modal.close();
    this.onClose.emit();
  }

  get getSize () {

    return this.size || '';
  }

}
