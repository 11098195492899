import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReconoserRequest, ReconoserResponse } from "@app/components/validate-identity/validate-identity.component";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class ReconoserIdService {
  
    public headers: HttpHeaders = new HttpHeaders({});
    public urlApi = 'https://wsbolp.c0v.co/olimpia/api/v1/recognizedId/iframe';
    public credentials: string = 'CLIENT_APP-19921ef5:23bHloreqW@7';
  
    constructor(private http: HttpClient) { }
  
    getIframeUrl(request: ReconoserRequest): Observable<ReconoserResponse> {
      request.tipoDoc = request.tipoDoc === 'C' ? 'CC' : request.tipoDoc;
      const headers: HttpHeaders = this.getBasicAuthorization();
      const options = { headers };
      return this.http.post<ReconoserResponse>(`${this.urlApi}/validateStatus`, request, options);
    }
  
    getBasicAuthorization(): HttpHeaders {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${this.credentials}`),
      });
  
      return headers;
    }
  }