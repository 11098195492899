import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-start-process-error',
  templateUrl: './modal-start-process-error.component.html',
  styleUrls: ['./modal-start-process-error.component.scss']
})
export class ModalStartProcessErrorComponent {

  public modalName: string = 'modalStartProcessError';

  constructor() { }

  handleClick() {
    window.location.reload();
  }

}
