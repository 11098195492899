import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-list-active',
  templateUrl: './modal-list-active.component.html',
  styleUrls: ['./modal-list-active.component.scss']
})
export class ModalListActiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
