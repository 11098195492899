import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {

  titulos = [
    {
      imagen: "assets/img/icon_tittle.svg",
      alt: "icon student",
      descripcion: "Autodiagnóstico",
    },
    {
      imagen: "assets/img/icon_family.svg",
      alt: "icon student",
      descripcion: "Familiares",
    },
  ];

  familiares = [
    {
      tipo_documento_familiar: "",
      documento_familiar: "",
      nombre_completo_familiar: "",
      parentezco: "",
      presenta_sintomas_familiar: [],
    },
  ];

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }

  addReference() {
    const familiar = {
      tipo_documento_familiar: "",
      documento_familiar: "",
      nombre_completo_familiar: "",
      parentezco: "",
      presenta_sintomas_familiar: [],
    };
    if (this.familiares.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 familiares.", null);
      return;
    }

    this.familiares.push(familiar);
  }

  deleteReference(id: number) {
    const familiar = {
      tipo_documento_familiar: "",
      documento_familiar: "",
      nombre_completo_familiar: "",
      parentezco: "",
      presenta_sintomas_familiar: [],
    };

    if (id === 0 && this.familiares.length > 0) {
      this.familiares[0] = familiar;
      return;
    }
    this.familiares.splice((this.familiares.length - 1), 1);
  }

}
