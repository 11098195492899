import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  isStringNumeric(str: string): boolean {
    // sin decimales o negativos
    return /^\d+$/.test(str);
  }

  parseStringToStringDate(stringDate: string | Date, separator: string = '/'): string {
    if (!stringDate) return '';

    const date = new Date(stringDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day}${separator}${month}${separator}${year}`
  }
}
