import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input() modalName: string = '';
  @Input() showHeader: boolean = true;
  @Input() showBody: boolean = true;
  @Input() showFooter: boolean = true;

  constructor(public modal: ModalService,
    private router: Router) { 
      
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.modal.close();
  }

  goToStart() {
    this.modal.close();
    this.router.navigateByUrl('/pages/transacciones');
  }

}