import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss']
})
export class UploadFileModalComponent implements OnInit {

  constructor(public modal: ModalService) { }

  ngOnInit() {
  }

}
