export class ViewPromissoryNoteRequest {
    public idProcesoClienteExterno!: string;
    public plantillas!:              ITemplateViewPromissoryNote[];
}

export interface ITemplateViewPromissoryNote {
    idTipoDocumento: number;
    serialPlantilla: string;
    anotaciones:     AnotationViewPromissoryNote[];
}

export class AnotationViewPromissoryNote {
    public Nombre: string = '';
    public Valor:  string = '';
}

export class ViewPromissoryNoteResponse {
    public ok!:      boolean;
    public message!: string;
    public data!:    ViewPromissoryNoteResponseModel;
    public status!:  number;
}

export class ViewPromissoryNoteResponseModel {
    public transaccionRegistrada!: boolean;
    public archivos!:              IFile[];
}

export interface IFile {
    base64: string;
}
