import { Component, OnInit, Output, Input, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SubjectService } from '@app/services/subject.service';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-channels',
  templateUrl: './contact-channels.component.html',
  styleUrls: ['./contact-channels.component.scss'],
})
export class ContactChannelsComponent implements OnInit, OnDestroy {

  public channel: any;
  public stateChannelSubscription: Subscription;

  @Input() clientStudyForm: ClientStudyRequest;
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();

  @ViewChild('formChannel') formChannel: NgForm;
  @Output() formChannelValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private subject: SubjectService) {}

  ngOnInit() {
    this.stateChannelSubscription = this.subject.stateChannel$.subscribe((channel) => {      
      this.channel = channel;
    });
  }

  ngOnDestroy() {
    this.stateChannelSubscription.unsubscribe();
  }

  onBlur($event) {
    this.valueChanges.emit();
  }

  changeWhatsapp() {
    this.clientStudyForm.canal_whatsapp = (this.channel.whatsapp === true) ? 'S' : 'N';
    this.subject.changeStateChannel(this.channel);
  }

  changeSms() {
    this.clientStudyForm.canal_sms = (this.channel.sms === true) ? 'S' : 'N';
    this.subject.changeStateChannel(this.channel);
  }

  changeLlamada() {
    this.clientStudyForm.canal_llamada = (this.channel.llamada === true) ? 'S' : 'N';
    this.subject.changeStateChannel(this.channel);
  }

  changeCorreo() {
    this.clientStudyForm.canal_correo = (this.channel.correo === true) ? 'S' : 'N';
    this.subject.changeStateChannel(this.channel);
  }

  handleValidate() {
    const valid = (this.channel.whatsapp === true || this.channel.sms === true || this.channel.llamada === true || this.channel.correo === true);
    this.formChannelValid.emit(valid);
  }
}
