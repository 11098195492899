import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { Files } from '@app/models/files.model';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.component.html',
  styleUrls: ['./modal-upload-file.component.scss']
})
export class ModalUploadFileComponent implements OnInit {

  public files: File[] = [
    
  ];
  public fileData: Files[] = [
  {
    fileDescription: "Fotocopia de la cédula",
    file: []
  },
  {
    fileDescription: "Declaración de renta (última presentada).",
    file: []
  },
  {
    fileDescription: "Declaraciones de IVA del último año.",
    file: []
  },
  {
    fileDescription: "Estados financieros con notas del último año.",
    file: []
  },
  
  ];
  public numberOfFiles: Array<any> = ['', '', '', ''];
  public mb = 1024 * 1024;
  public maxFileSize = 7 * this.mb;
  public fileSelected: File;
  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();
  public subs1: Subscription;
  cancel = false;

  constructor(public toastr: ToastrService, public modal: ModalService) {}

  ngOnInit() {
    
  }

  // DropZone
  onSelect(event, i?) {
    this.fileSelected = event.addedFiles[0];
    let selectedFilesSize = 0;
    let selectedAddedFiles = 0;
    if(event.rejectedFiles[0])
    
    if(event.rejectedFiles[0].size > this.maxFileSize) {
      this.toastr.error("El tamaño máximo permitido es de 7 megabytes (MB)", "");
    } else {
      this.toastr.error("Formato no válido", "")
    }

    event.addedFiles.forEach(file => {
      selectedAddedFiles += file.size;
    });

    if(this.fileData[i]) {
      this.fileData[i].file.forEach(file => {
        selectedFilesSize += file.size;
      });
    }
    
    
    if(selectedAddedFiles + selectedFilesSize >= this.maxFileSize) {
      this.toastr.error("El tamaño máximo permitido es de 7 megabytes (MB)", "")
      return;
    }
    
    if(this.fileData[i]) {
      this.fileData[i].file.push(...event.addedFiles); 
    }
       
    this.uploadFile();
  }

  onRemove(event, one: boolean) {
   if(one) {
      this.files.splice(this.files.indexOf(event), 1);
   } else {
     this.files = [];
   }
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.chargeResult.emit(this.files);
  }
}

