import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages/pages.routing';

import { RouterModule, Routes } from '@angular/router';
import { AuthRoutingModule } from './auth/app.routing';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { TokenGuard } from './guards/token.guard';

const routes: Routes = [
  { path: 'cupo-afiliado', loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule) },
  { path: '**', pathMatch: 'full', redirectTo:  'not-found' },
  { path: 'not-found', component: NotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    PagesRoutingModule,
    AuthRoutingModule,
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
