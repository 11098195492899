import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-afiliate-info-error',
  templateUrl: './modal-afiliate-info-error.component.html',
  styleUrls: ['./modal-afiliate-info-error.component.scss']
})
export class ModalAfiliateInfoErrorComponent {

  public modalName: string = 'modalAfiliateInfoError';

  constructor() { }

  handleClick() {
    window.location.reload();
  }

}
