import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private selectedError = new Subject<any>();
  public eventSelectedError$ = this.selectedError.asObservable();

  private selectedProfileImg = new Subject<string>();
  public eventSelectedProfileImg$ = this.selectedProfileImg.asObservable();

  private stateToggleMenuBS = new BehaviorSubject<boolean>(false);
  public stateMenu$ = this.stateToggleMenuBS.asObservable();

  private stateIsMobileBS$ = new BehaviorSubject<boolean>(false);
  public stateIsMobile$ = this.stateIsMobileBS$.asObservable();

  private stateMassiveOperation = new Subject<any>();
  public stateMassiveOperation$ = this.stateMassiveOperation.asObservable();

  private stateFileModal = new Subject<File[]>();
  public stateFileModal$ = this.stateFileModal.asObservable();

  private stateCurrentStep = new BehaviorSubject<number>(1); //TODO: Dejarlo en 1 - El número 5 es para probar el nuevo módulo
  public stateCurrentStep$ = this.stateCurrentStep.asObservable();

  private stateTypePerson = new BehaviorSubject<string>('natural'); //TODO: Dejarlo en 'natural'
  public stateTypePerson$ = this.stateTypePerson.asObservable();

  private stateSentCoupon = new BehaviorSubject<boolean>(true);
  public stateSentCoupon$ = this.stateSentCoupon.asObservable();

  public stateAddress = new BehaviorSubject<any>({
    addressType: '',
    addressFirst: '',
    addressSecond: '',
    addressThird: '',
    addressOther: '',
  });

  public stateAddress$ = this.stateAddress.asObservable();

  public stateChannel = new BehaviorSubject<any>({
    whatsapp: false,
    sms: false,
    correo: false,
    llamada: false,
  });
  public stateChannel$ = this.stateChannel.asObservable();

  public stateMessageError = new BehaviorSubject<string>('');
  public stateMessageError$ = this.stateMessageError.asObservable();

  public stateInvalidDataError = new BehaviorSubject<boolean>(false);
  public stateInvalidDataError$ = this.stateInvalidDataError.asObservable();

  public stateTermsAndConditions = new BehaviorSubject<any>('');
  public stateTermsAndConditions$ = this.stateTermsAndConditions.asObservable();
  
  public stateInformationOperators = new BehaviorSubject<any>('');
  public stateInformationOperators$ = this.stateInformationOperators.asObservable();

  public stateSourceFunds = new BehaviorSubject<any>('');
  public stateSourceFunds$ = this.stateSourceFunds.asObservable();
  
  public stateElectronicSign = new BehaviorSubject<any>('');
  public stateElectronicSign$ = this.stateElectronicSign.asObservable();

  public stateFirstCommercialDepartment = new BehaviorSubject<any>({ID: '', LABEL: ''});
  public stateFirstCommercialDepartment$ = this.stateFirstCommercialDepartment.asObservable();

  public stateFirstCommercialCity = new BehaviorSubject<any>({ID: '', LABEL: ''});
  public stateFirstCommercialCity$ = this.stateFirstCommercialCity.asObservable();

  public stateFirstMunicipalities = new BehaviorSubject<any>([]);
  public stateFirstMunicipalities$ = this.stateFirstMunicipalities.asObservable();

  public stateLegalFirstName = new BehaviorSubject<string>('');
  public stateLegalFirstName$ = this.stateLegalFirstName.asObservable();

  public stateLegalSecondName = new BehaviorSubject<string>('');
  public stateLegalSecondName$ = this.stateLegalSecondName.asObservable();

  constructor() { }

  chanteStateLegalFirstName(firstName: string) {
    this.stateLegalFirstName.next(firstName);
  }

  chanteStateLegalSecondName(secondName: string) {
    this.stateLegalSecondName.next(secondName);
  }

  changeStateFirstMunicipalities(cities: any) {
    this.stateFirstMunicipalities.next(cities);
  }

  changeFirstCommercialDepartment(department: any) {
    this.stateFirstCommercialDepartment.next(department);
  }

  changeFirstCommercialCity(city: any) {
    this.stateFirstCommercialCity.next(city);
  }
  changestateTermsAndConditions(modalData: any) {
    this.stateTermsAndConditions.next(modalData);
  }
  
  changeStateInformationOperators(modalData: any) {
    this.stateInformationOperators.next(modalData);
  }

  changeStateSourceFunds(modalData: any) {
    this.stateSourceFunds.next(modalData);
  }
  
  changeStateElectronicSign(modalData: any) {
    this.stateElectronicSign.next(modalData);
  }


  changeStateInvalidDataError(isError: boolean) {
    this.stateInvalidDataError.next(isError);
  }

  changeStateMessageError(message: string) {
    this.stateMessageError.next(message);
  }

  changeStateChannel(channel: any) {
    this.stateChannel.next(channel);
  }

  changeStateAddress(address: any) {
    this.stateAddress.next(address);
  }

  changeTypePerson(person: string) {
    
    this.stateTypePerson.next(person);
  }

  changeStateSentCoupon(state: boolean) {
    this.stateSentCoupon.next(state);
  }



  changeStep(step: number) {
    this.stateCurrentStep.next(step);
  }

  sendText(selected: any) {
    this.selectedError.next(selected);
  }

  sendProfileImg(profileImg: any) {
    this.selectedProfileImg.next(profileImg);
  }

  menuCollapse(show: boolean) {
    this.stateToggleMenuBS.next(show);
  }

  isMobile(isMobile: boolean) {
    this.stateIsMobileBS$.next(isMobile);
  }

  returnToPrincipal(state: boolean) {
     this.stateMassiveOperation.next(state);
  }

  addFiles(files: File[]) {
    this.stateFileModal.next(files)
  }

  removeFiles() {
    this.stateFileModal.next();
  }
}
