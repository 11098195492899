export class SelectsResponse {
    BANCOS: BankModel[] = [];
    TELEFONOS: PhoneModel[] = [];
    "CIUDAD-DEPARTAMENTO": CityDepartmentModel[] = [];
    CONSTANTES: Constantsodel[] = [];
    TIPOS_DOCUMENTOS: DocumentTypesModel[] = [];
    ESCOLARIDAD: ScholarshipModel[] = [];
    DEPARTAMENTOS: DepartmentModel[] = [];
    PAIS: CountryModel[] = [];
    id_tx: string = '';
}

export interface BankModel {
    LABEL: string;
    ID: number;
}

export interface PhoneModel {
    TELEFONO: string;
    DESCRIPCION: number;
}

export interface CityDepartmentModel {
    LABEL: string;
    ID: number;
}

export interface Constantsodel {
    BACKOFFICE: string;
    TAMANO: number;
}

export interface DocumentTypesModel {
    LABEL: string;
    ID: number;
}

export interface ScholarshipModel {
    LABEL: string;
    ID: number;
}

export interface DepartmentModel {
    LABEL: string;
    ID: number;
}

export interface CountryModel {
    DESCRIPCION: string;
    ID_PAIS: string;
}