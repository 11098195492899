import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private step: number = 0;

  private stateType = new BehaviorSubject<string>('legal');
  public stateType$ = this.stateType.asObservable();

  private stateStep = new BehaviorSubject<number>(this.step);
  public stateStep$ = this.stateStep.asObservable();

  private isShowXL = new BehaviorSubject<boolean>(false);
  public isShowXL$ = this.isShowXL.asObservable();

  public backupsTabs: any[] = [
    {
      href: '#individual',
      description: 'Radicación',
      icon: 'icon-tab-individual.svg',
    },
    // {
    //   href: '#massive',
    //   description: 'Títulos valor',
    //   icon: 'icon-tab-massive.svg',
    // },
    {
      href: '#reserves',
      description: 'Reintegros',
      icon: 'icon-tab-reserves.svg',
    },
    {
      href: '#historical',
      description: 'Hístoricos',
      icon: 'icon-tab-reserves.svg',
    },
  ];

  constructor() { }

  changePersonType(type: string) {
    this.stateType.next(type);
  }

  changeNextStep() {
    this.stateStep.next(this.step++); 
  }

  changePreviousStep() {
    this.stateStep.next(this.step--)
  }

  changeIsShowXL(isShowXL: boolean) {
    this.isShowXL.next(isShowXL);
  }
}
