import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  // Dates
  public finalDate;
  public initDate;
  public min;
  public max;

  // Properties
  public selectedMoments = [];

  @Output() search: EventEmitter<object> = new EventEmitter();
  @Input() minInput: Date;
  @Input() maxInput: Date;
  @Input() initDateInput: Date;
  @Input() finalDateInput: Date;

  constructor() {}

  ngOnInit() {
    this.min = this.minInput || new Date('2016-01-02');
    this.max = this.maxInput || new Date();
    this.finalDate = this.finalDateInput || new Date();
    this.initDate =
      this.initDateInput ||
      moment(this.finalDate).subtract(15, 'days').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
  }

  selectRange() {
    this.search.emit(this.selectedMoments);
  }
}
