import { MassiveRecord } from './massiveRecord.model';

export class MassiveRequest {
  correctRecords: Array<MassiveRecord>;
  errorRecords: Array<MassiveRecord>;

  public static fromResponse(json: any): MassiveRequest {
    const massiveRequets: MassiveRequest = {
      correctRecords: json['REGISTROS_CORRECTOS'].map((correctRecord) =>
        MassiveRecord.fromResponse(correctRecord)
      ),
      errorRecords: json['REGISTROS_ERROR'].map((correctRecord) =>
        MassiveRecord.fromResponse(correctRecord)
      ),
    };
    return massiveRequets;
  }

  public static toRequest(massiveTransactionRequest: MassiveRequest): any {
    return {
      REGISTROS_CORRECTOS: massiveTransactionRequest.correctRecords.map(
        (correctRecord: MassiveRecord) => MassiveRecord.toRequest(correctRecord)
      ),
      REGISTROS_ERROR: massiveTransactionRequest.errorRecords.map(
        (correctRecord: MassiveRecord) => MassiveRecord.toRequest(correctRecord)
      ),
    };
  }

  public static toVerify(massiveTransactionRequest: MassiveRequest): any {
    const concatedRegisters = massiveTransactionRequest.correctRecords.concat(
      massiveTransactionRequest.errorRecords
    );
    return {
      CARGA: {
        REGISTROS_CORRECTOS: concatedRegisters.map(
          (correctRecord: MassiveRecord) =>
            MassiveRecord.toRequest(correctRecord)
        ),
      },
    };
  }

  public static toSend(massiveTransactionRequest: MassiveRequest): any {
    const concatedRegisters = massiveTransactionRequest.correctRecords;
    return {
      CARGA: {
        REGISTROS_CORRECTOS: concatedRegisters.map(
          (correctRecord: MassiveRecord) => MassiveRecord.toSend(correctRecord)
        ),
      },
    };
  }
}
