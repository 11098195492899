import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  openWhatsapp() {
    window.open(
      'https://covinoc.com/w/?telefono=573187116639&utm_source=COVIFACTURA&utm_medium=WEB&texto=&utm_content=boton_web&plantilla=WEB&utm_term=COVIFACTURA&utm_campaign=COVIFACTURA_WEB&campaign=COVIFACTURA_WEB',
      '_newtab'
    );
  }
}
