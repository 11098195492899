import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateResultsResponseDataModel } from '@app/models/validateResult.model';

@Injectable({
  providedIn: 'root'
})
export class FetchService {

  constructor() { }

  saveUserInfo(userInfo: ValidateResultsResponseDataModel) {
    if (!userInfo) return;

    const celular = userInfo.celular || '';
    userInfo.celular = celular.substring(celular.length - 10);
    sessionStorage.setItem('userInfo_ID', JSON.stringify(userInfo));
  }

  getBasicAuthorizationAnalytics(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('CLIENT_APP-68645ed6:11bHriReiK@6'),
    });

    return headers;
  }
}
