import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '../utils/helper.service';
import { SubjectPromissoryService } from '../services/subject.service';


@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss']
})
export class InputCodeComponent implements OnInit {

  @Input() title: string = 'Firma electrónica';
  @Input() quantityOfDigits: number = 1;
  @Input() buttonText: string = 'Firmar electrónicamente';

  @Output() onValidateCode: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('inputs') inputs!: QueryList<any>;
  public codeForm!: FormGroup;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  get getDigitControls() {
    return (this.codeForm.get('digits') as FormArray)["controls"] || [];
  }

  constructor(
    private fb: FormBuilder,
    private helper: HelperService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private subjectService: SubjectPromissoryService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.buildForm();
    this.subjectService.resetInputsOfCodeOtp$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.handleResetForm.bind(this));
    this.cdRef.detectChanges();
  }

  buildForm() {
    const digitsControls = Array.from({ length: this.quantityOfDigits }, () => this.fb.control('', []));
    this.codeForm = this.fb.group({
      digits: this.fb.array(digitsControls)
    });
  }

  handleSubmitForm() {
    const code: string = (this.codeForm.value.digits as Array<string>).join('') || '';
    this.onValidateCode.emit(code);
    console.log('Enviando evento de validación de código', code);
  }

  handleKeyPress(index: number, field: AbstractControl, event: any) {
    const arrayElements: any[] = this.inputs.toArray() || [];
    const couldMoveForward: boolean = index >= 0 && index < arrayElements.length - 1;
    const isPasteCommand: boolean = event.ctrlKey && event.key === 'v';
    const isValidValueFromKey: boolean = this.helper.isStringNumeric(event.key);

    console.log('Presionando una tecla', event, isValidValueFromKey);

    // Validación para ingreso de solo dígitos o comando especificos
    if (!isValidValueFromKey && !isPasteCommand) {
      console.log('Tecla inválida');
      event.preventDefault();
      return;
    }

    field.setValue(event.key);
    if (couldMoveForward) this.focusElement(arrayElements[index + 1])
  }

  handleDelete(index: number, field: AbstractControl, event: any): void {
    const couldMoveBackwards: boolean = index > 0;
    const isBackspaceKey: boolean = event.key === 'Backspace';
    const arrayElements: any[] = this.inputs.toArray() || [];

    if (isBackspaceKey) {
      field.setValue('');
      if (couldMoveBackwards) this.focusElement(arrayElements[index - 1]);
    }
  }

  handlePaste(event: ClipboardEvent) {
    const pastedText: string = event.clipboardData.getData('text').trim() || '';
    const isValidText: boolean = this.helper.isStringNumeric(pastedText);
    console.log('El usuario esta tratando de pegar esto: ', pastedText, isValidText);

    if (!isValidText) {
      console.log('Se esta tratando de pegar un valor incorrecto');
      event.preventDefault();
      this.codeForm.patchValue({
        digits: Array.from({ length: this.quantityOfDigits }, () => '')
      });
      return;
    }

    const newDigits: string[] = [...pastedText.substring(0, this.quantityOfDigits)];
    this.codeForm.patchValue({
      digits: newDigits
    });

    this.focusNInput(this.inputs.toArray().length - 1 || 0);
  }

  focusElement(element: any, delay: number = 10): void {
    if (!element) return;
    element.nativeElement.focus();
  }

  focusNInput(index: number): void {
    const arrayElements: any[] = this.inputs.toArray() || [];
    const element = arrayElements[index];
    if (element) this.focusElement(element);
  }

  handleResetForm(value: boolean) {
    if (value && this.codeForm) {
      this.codeForm.reset();
      this.focusNInput(0);
    }
  }
}
