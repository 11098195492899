import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affiliate-id-information',
  templateUrl: './affiliate-id-information.component.html',
  styleUrls: ['./affiliate-id-information.component.scss']
})
export class AffiliateIdInformationComponent implements OnInit, OnDestroy {

  public affiliate: any;
  public subs1: Subscription = new Subscription();

  @Input() show: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs1.unsubscribe();
  }

}

/*
1. Version de angular - Herramientas de desarrollo
2. Arquitectura (archivos, carpetas, clases, etc) Patron de diseño.
3. Guia de estilo
4. Documentación
5. Repositorio (gitflow)
6. Comentarios
7. Bugs (Pruebas Unitarias)
8. Ambiente de desarrollo
9. Ambiente de producción
10. Lenguajes, librerias y frameworks


// Integración de servicios
1. Verbos HTTP
2. Postman con Data
3. Swagger
4. Respuestas - estructuras y formatos
*/
