import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input
} from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { ModalService } from "@app/services/modal.service";

@Component({
  selector: "modal-failed-validation",
  templateUrl: "./modal-failed-validation.html",
  styleUrls: ["./modal-failed-validation.scss"]
})
export class ModalFailedValidationComponent implements OnInit {
  public url: SafeResourceUrl;
  public titleModal: any = "Validación fallida";

  @Output() continueButton: EventEmitter<any> = new EventEmitter<any>();

  constructor(public modal: ModalService) {}

  openModal(nameModal: string){
    this.modal.show(nameModal, {title: this.titleModal});
  }

  ngOnInit() {}

  buttonClick(){
    this.continueButton.emit();
  }
}
