import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { AppRoutingModule } from "@app/app-routing.module";
import { ModalComponent } from "./modal/modal.component";
import { ModalSuccessContentComponent } from "./modal-success-content/modal-success-content.component";
import { ModalListActiveComponent } from "./modal-list-active/modal-list-active.component";
import { ModalUploadFileComponent } from "./modal-upload-file/modal-upload-file.component";
import { ModalLegalPersonalComponent } from "./modal-legal-personal/modal-success-content.component";
import { ModalInformationOperatorsComponent } from "./modal-information-operators/modal-information-operators.component";
import { ModalSourceFundsComponent } from "./modal-source-funds/modal-source-funds.component";
import { ModalAcceptElectronicSignComponent } from "./modal-accept-electronic-sign/modal-accept-electronic-sign.component";
import { ModalCounterpartKnowledgeComponent } from "./modal-counterpart-knowledge/modal-counterpart-knowledge.component";
import { ErrorMessageModalComponent } from "./error-message-modal/error-message-modal.component";
import { UploadFileModalComponent } from "./upload-file-modal/upload-file-modal.component";
import { ModalSuccessValidationComponent } from "./modal-success-validation/modal-success-validation";
import { ModalFailedValidationComponent } from "./modal-failed-validation/modal-failed-validation";

@NgModule({
  declarations: [
    ModalComponent,
    ModalSuccessContentComponent,
    ModalListActiveComponent,
    ModalUploadFileComponent,
    ModalLegalPersonalComponent,
    ModalInformationOperatorsComponent,
    ModalSourceFundsComponent,
    ModalAcceptElectronicSignComponent,
    ModalCounterpartKnowledgeComponent,
    ErrorMessageModalComponent,
    UploadFileModalComponent,
    ModalSuccessValidationComponent,
    ModalFailedValidationComponent,
  ],
  imports: [CommonModule, AppRoutingModule, SharedModule],
  exports: [
    ModalComponent,
    ModalSuccessContentComponent,
    ModalListActiveComponent,
    ModalUploadFileComponent,
    ModalLegalPersonalComponent,
    ModalInformationOperatorsComponent,
    ModalSourceFundsComponent,
    ModalAcceptElectronicSignComponent,
    ModalCounterpartKnowledgeComponent,
    ErrorMessageModalComponent,
    ModalSuccessValidationComponent,
    ModalFailedValidationComponent,
  ],
})
export class ModalModule {}
