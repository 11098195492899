import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "../app-routing.module";
import { SharedModule } from "../shared/shared.module";
import { PaginationComponent } from "./layout/pagination/pagination.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { BackButtonComponent } from "./back-button/back-button.component";
import { DateRangeComponent } from "./date-range/date-range.component";
import { ErrorMessageComponent } from './error-message/error-message.component';
import { EmbedTableauComponent } from './embed-tableau/embed-tableau.component';
import { AffiliateIdInformationComponent } from './affiliate-id-information/affiliate-id-information.component';
import { StepperComponent } from './stepper/stepper.component';
import { BgImageComponent } from './bg-image/bg-image.component';
import { CardComponent } from './card/card.component';
import { UploadFileComponent } from './upload-file/upload-file.component'
import { WarningMessageComponent } from './warning-message/warning-message.component';
import { ValidateIdentityComponent } from "./validate-identity/validate-identity.component";

@NgModule({
  declarations: [
    PaginationComponent,
    BackButtonComponent,
    NotFoundComponent,
    DateRangeComponent,
    ErrorMessageComponent,
    EmbedTableauComponent,
    AffiliateIdInformationComponent,
    StepperComponent,
    BgImageComponent,
    CardComponent,
    UploadFileComponent,
    WarningMessageComponent,
    ValidateIdentityComponent,
  ],
  imports: [CommonModule, AppRoutingModule, SharedModule],
  exports: [
    PaginationComponent,
    BackButtonComponent,
    NotFoundComponent,
    DateRangeComponent,
    ErrorMessageComponent,
    AffiliateIdInformationComponent,
    EmbedTableauComponent,
    StepperComponent,
    BgImageComponent,
    CardComponent,
    UploadFileComponent,
    WarningMessageComponent,
    ValidateIdentityComponent,
  ]
})
export class ComponentsModule {}
