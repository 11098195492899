import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterUpperCase'
})
export class FirstLetterUpperCasePipe implements PipeTransform {

  transform(value: string): string {    
    if(value === "BOGOTA D.C") {
      return "Bogota D.C"
    } else {
      return this.convertToCapitalize(value);
    }
    
  }

  convertToCapitalize(value: string): string {
    let department = value.split('(');
    
    let wordConverted = [];
    wordConverted.push(department[0].substr(0,1).toUpperCase() +  department[0].substr(1).toLowerCase());
    wordConverted.push('(' + department[1].substr(0,1).toUpperCase() +  department[1].substr(1).toLowerCase());
    return wordConverted.join(' '); 
  }

}
