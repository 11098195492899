import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { CONSTANTS } from '@config/constants';
import { HelperService } from '@app/services/helper.service';
import { NgForm } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent implements OnInit, OnDestroy {
  @Input() clientStudyForm: ClientStudyRequest;
  @ViewChild('formAddress') formAddress: NgForm;
  @Output() formAddressValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public address: any;
  public stateAddressSubscription: Subscription;
  public regex = "^(?=\w+\d)([A-Za-z]*)\w{​​​​​1,}​​​​​$"
  public titleAddress: string;
  public constants = CONSTANTS;

  public addressFields = [
    'Calle',
    'Carrera',
    'Avenida',
    'Avenida Carrera',
    'Avenida Calle',
    'Circular',
    'Circunvalar',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Vía',
    'Otra',
  ];

  constructor(public helper: HelperService, public subject: SubjectService) {
  }

  ngOnInit() {
    this.stateAddressSubscription = this.subject.stateAddress$.subscribe((address) => {
      this.address = address
    });
    this.subject.stateTypePerson$.subscribe(typePerson => {
      this.titleAddress = (typePerson === 'legal') ? 'Dirección comercial' : 'Dirección de residencia'
    })
  }

  ngOnDestroy() {
    this.stateAddressSubscription.unsubscribe();
  }

  setAddress() {
    if (this.address.addressType === 'Otra') {
      this.address.addressFirst = '';
      this.address.addressSecond = '';
      this.address.addressThird = '';
    }

    if (this.address.addressType !== 'Otra' && this.address.addressOther && this.address.addressOther !== '') {
      this.address.addressOther = '';
    }

    this.subject.changeStateAddress(this.address);
    this.clientStudyForm.direccion = `${this.address.addressType} ${this.address.addressFirst} ${this.address.addressSecond} ${this.address.addressThird} ${this.address.addressOther}`;
  }

  handleValidate() {
    const valid = this.formAddress.form.valid;
    this.formAddressValid.emit(valid);
  }
}
