import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterContentInit,
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { ModalService } from "@services/modal.service";
import { HelperService } from "@services/helper.service";
import { ClientStudyRequest } from "@models/clientStudyRequest.model";
import { City } from "@models/city.model";
import { NgForm } from '@angular/forms';
import { CONSTANTS } from "@config/constants";
import { TabsService } from "@app/services/tabs.service";
import { ToastrService } from "ngx-toastr";
import { SubjectService } from "@app/services/subject.service";
import { SelectsService } from "@app/services/selects.service";
import { BackEndService } from "@app/services/back-end.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthNitService } from "@app/services/auth-nit.service";
import { debounceTime } from "rxjs/operators";
declare let $: any;
import * as _moment from "moment";
import { ReconoserRequest } from "@app/components/validate-identity/validate-identity.component";
import { AnalyticsService } from "@app/services/analytics.service";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { PromissoryNotePreviewService } from "@app/pages/promissory-note/services/promissory-note-preview.service";
import { FormStudyJsonModel } from "@app/pages/promissory-note/models/form-study.model";


const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: "app-display-legal-person",
  templateUrl: "./display-legal-person.component.html",
  styleUrls: ["./display-legal-person.component.scss"],
})
export class DisplayLegalPersonComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @ViewChild("formCheck") formCheck: NgForm;
  /* @Input() departments: Array<Department>; */
  @Input() initialDateFields: Array<String>;
  @Input() addressFields: Array<String>;
  @Input() documentTypesOptions: Array<any>;
  @Input() companyTypeFields: Array<any>;
  @Input() economicActivityFields: Array<any>;
  @Input() expeditionCities: Array<City>;
  @Input() homeCities: Array<City>;
  @Input() lastSaveDate: String;
  @Input() updateRefCities: Subject<boolean>;

  // Form
  @Input() clientStudyForm: ClientStudyRequest;
  @Output()
  requestClientStudy: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  validateChange: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  homeDepartmentChanged: EventEmitter<String> = new EventEmitter<String>();
  @Output()
  expeditionDepartmentChanged: EventEmitter<String> = new EventEmitter<String>();
  @Output()
  isShowXL: EventEmitter<boolean> = new EventEmitter<boolean>();

  public message= "Recuerde que es necesario acceder a la cámara del dispositivo y tener a la mano el documento de identificación del comprador.";
  public isRef1Valid = false;
  public isRef2Valid = false;
  public isTermAccepted = false;
  public constants = CONSTANTS;
  public step: number;
  public stepSubs: Subscription;
  public addressFormValid: boolean = false;
  public channelFormValid: boolean = false;

  public pepValid: boolean = false;
  public switchPepValid: boolean = false;

  public publicPepValid: boolean = false;
  public switchPublicPepValid: boolean = false;

  public addressForeignValid: boolean = false;
  public switchAddressForeignValid: boolean = false;

  public partnerValid: boolean = true;
  public patternDate = /^(?:3[01]|[12][0-9]|0?[1-9])([\/])(0?[1-9]|1[1-2])\1\d{​​​4}​​​$/g;
  public commercialReferenceValid: boolean = false;

  public validatePatternDocument;
  public cumple: boolean = false;
  responseData:any;

  public legalRepresentativeName = {
    firstName: "",
    secondName: "",
  };

  public switches = {
    pep: false,
    exposedPerson: false,
    foreignCurrency: false,
  };

  public reconoserRequest: ReconoserRequest;

  public option = {
    description: "pointsOfSale",
  };

  public dataCounterpartPep = [
    {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    },
  ];

  public dataExposedPerson = [
    {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    },
  ];

  public departments = [];
  public expeditionDepartments = [];
  public department = "";
  public expeditionDepartment = "";
  public municipalities = [];
  public expeditionMunicipalities = [];

  public cities: any;

  public isInformationCopied: boolean = false;

  public currentStep = 1;

  public min: Date = new Date(1940, 0, 1);
  public max: Date = new Date();

  public currentStep$: Observable<number> = this.subject.stateCurrentStep$;
  public expeditionDisabled = false;

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    private tabs: TabsService,
    private toastr: ToastrService,
    public subject: SubjectService,
    public select: SelectsService,
    public backend: BackEndService,
    private spinner: NgxSpinnerService,
    private authNitService: AuthNitService,
    private promissoryNoteService: PromissoryNotePreviewService,
    private analytics: AnalyticsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.clientStudyForm.tipo_doc_rep = 'C';
    this.validatePatternDocument = this.helper.validatePatternDocument('C');
    this.subject.stateTypePerson$.subscribe((resp) => {
      this.clientStudyForm.clientType = resp;
    });
    this.clientStudyForm.nombres_rep = `${this.legalRepresentativeName.firstName} ${this.legalRepresentativeName.secondName}`;
    this.getCities();
    this.valueDataNumberChange();
    this.clientStudyForm.tipo_empresa = "UNIPERSONAL";

    if(!environment.production) this.loadDataMock();
  }

  valueDataNumberChange() {
    this.cumple = false;
    let str = this.clientStudyForm.comprador_documento;
    let nit = str.split('');
    let lastDigit = nit[nit.length - 1];
    //console.log(lastDigit);
    nit.pop();
    var shortNit = nit.join("");
    if (shortNit.length >= 8) {
      this.authNitService
        .validatedNit(shortNit)
        .pipe(debounceTime(700))
        .subscribe({
          next: (response) => {
            if (!response["ok"]) return;
            else this.responseData = response;
            //console.log("nit corto" + shortNit);
            if (
              this.responseData["data"] &&
              this.responseData["data"] == lastDigit
            )
              this.cumple = true;
            else this.cumple = false;
          },
          complete: () => { },
          error: (error) => {
            console.log("NIT inválido", error);
          },
        });
    } else {
      this.cumple = false;
    }
  }

  changeIsExpeditionRequired() {
    this.validatePatternDocument = this.helper.validatePatternDocument(this.clientStudyForm.tipo_doc_rep);
  }

  getCities() {
    this.select.getCities().subscribe((response) => {
      this.cities = this.mapCities(response['CIUDAD-DEPARTAMENTO']);
    });
  }

  mapCities(citiesData: any[]): any[] {
    return citiesData.map(city => {
      return {
        ...city,
        ID: city.ID.toString()
      };
    });
  }

  setBuyerName() {
    this.clientStudyForm.nombres_rep = `${this.legalRepresentativeName.firstName} ${this.legalRepresentativeName.secondName}`;
  }

  selectDeparment(selectedDepartment: any) {
    this.spinner.show();
    this.clientStudyForm.lista_municipio = "";
    this.select.getMunicipalities(selectedDepartment).subscribe(
      (municipalities: any) => {
        this.municipalities = municipalities;
      },
      (err) => {
        this.municipalities = [];
      }, () => {
        this.spinner.hide();
      });
  }

  selectExpeditionDeparment(selectedDepartment: any) {
    this.spinner.show();
    this.clientStudyForm.lista_municipio_expedicion = "";
    this.select.getMunicipalities(selectedDepartment).subscribe(
      (municipalities: any) => {
        this.expeditionMunicipalities = municipalities;
      },
      (err) => {
        this.expeditionMunicipalities = [];
      }, () => {
        this.spinner.hide();
      });
  }

  testData() {
  }

  testDataContrapart($event) {
  }

  acceptTerms() {
    let typePerson = (this.clientStudyForm.comprador_tipo_documento !== 'C' && this.clientStudyForm.comprador_tipo_documento !== 'E') ? 'N' : this.clientStudyForm.comprador_tipo_documento;
    
    const infoUser = { typeDocument: typePerson, document: this.clientStudyForm.comprador_documento };
    this.backend.getAcceptTerms(infoUser).subscribe((resp) => {});
    this.validatePreviousRequest();
  }

  ngOnDestroy() {
    this.stepSubs.unsubscribe();
  }

  ngAfterContentInit() {
    this.stepSubs = this.tabs.stateStep$.subscribe((resp: any) => {
      this.step = resp;
    });
  }

  handleNext(step: number, isShowXL: boolean) {
    if (step == 2) {
      try {
        this.reconoserRequest = {
          celular: this.clientStudyForm.celular,
          email: this.clientStudyForm.correo,
          numDoc: this.clientStudyForm.doc_rep,
          tipoDoc: this.clientStudyForm.tipo_doc_rep,
          tipoValidacion: '1'
        };
        this.analytics.trackEvent('LinkCupoJuridica_Ok');
      } catch (error) {
        this.analytics.trackEvent('LinkCupoJuridica_Error');
      }
    }
    this.currentStep = step;
    this.subject.changeStep(step);
    this.tabs.changeIsShowXL(isShowXL);
  }

  validateIdentity(event: any) {
    if (!event) return;
    if (event.isSuccess) {
      this.modal.show("successValidation");
    } else {
      this.modal.show("failedValidation");
    }
  }

  validateIdentityData(event: any) {
    console.log(event);
    if (!event) {
      this.currentStep = 1;
      this.subject.changeStep(1);
    }
    this.clientStudyForm.doc_rep = event.numDoc;
    if (event.servicios) {
      for (let servicio of event.servicios) {
        if (servicio.servicio == "Biometría" && servicio.tipo == "Documento" 
        && servicio.subTipos == "Reverso" && servicio.barcode && servicio.barcode.fechaExpedicion) {
          this.expeditionDisabled = true;
          this.clientStudyForm.fecha_expedicion = new Date(servicio.barcode.fechaExpedicion);
        }
      }
    }
    this.legalRepresentativeName.firstName = event.primerNombre;
    this.legalRepresentativeName.secondName = event.segundoNombre;
    this.setBuyerName();
    this.clientStudyForm.apel1_rep = event.primerApellido;
    this.clientStudyForm.apel2_rep = event.segundoApellido;
    this.clientStudyForm.correo = event.email;
  }

  succesedValidation() {
    this.currentStep = 3;
    this.subject.changeStep(3);
    this.modal.close()
  }

  failedValidation() {
    this.currentStep = 1;
    this.subject.changeStep(1);
    this.modal.close()
  }

  handlePrev(step: number) {
    this.currentStep = step;
    this.subject.changeStep(step);
  }

  handleGoToStep(step: number) {
    this.currentStep = step;
    this.subject.changeStep(step);
  }

  addPep(id: any) {
    const pep = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (this.dataCounterpartPep.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 elementos.", null);
      return;
    }

    this.dataCounterpartPep.push(pep);
  }

  removePep(id: number) {
    const pep = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (id === 0 && this.dataCounterpartPep.length > 0) {
      this.dataCounterpartPep[0] = pep;
      return;
    }

    this.dataCounterpartPep.splice(id, 1);
  }

  addExposedPerson(id: any) {
    const pep = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (this.dataExposedPerson.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 elementos.", null);
      return;
    }

    this.dataExposedPerson.push(pep);
  }

  deletePerson(id: number) {
    const exposedPerson = {
      nombre: "",
      tipo_documento: "",
      numero_documento: "",
      persona_juridica: "",
    };

    if (id === 0 && this.dataExposedPerson.length > 0) {
      this.dataExposedPerson[0] = exposedPerson;
      return;
    }

    this.dataExposedPerson.splice(id, 1);
  }

  handleValidChannel($event) {
    this.channelFormValid = $event;
  }

  handleValidForeign($event) {
    this.addressForeignValid = $event;
  }

  handleValidAddress($event) {
    this.addressFormValid = $event;
  }

  handleSwitchValidForeign() {
    if (this.switches.foreignCurrency === false) {
      this.switchAddressForeignValid = false;
    } else {
      this.switchAddressForeignValid = true;
    }

    if (this.switches.foreignCurrency === true) {
      this.clientStudyForm.transacciones_extranjeria = {
        tipo_operacion: "",
        descripcion: {
          nombre_entidad: "",
          tipo_producto: "",
          monto_promedio: "",
          moneda: "",
          ciudad: "",
          pais: "",
        },
      };
      this.addressForeignValid = false;
    }
  }

  handleValidPep($event) {
    setTimeout(() => {
      this.pepValid = $event;
    }, 1);
  }

  handleValidTypeCompany() {
    this.partnerValid =
      this.clientStudyForm.tipo_empresa === "SOCIEDAD"
        ? this.partnerValid
        : true;
  }

  handleSwitchPep() {
    if (this.switches.exposedPerson === false) {
      this.switchPepValid = true;
      this.clientStudyForm.vinculos_PEP = [
        {
          nombres: "",
          tipo_documento: "",
          documento: "",
          cotiza_bolsa: "",
        },
      ];
      this.pepValid = false;
    } else {
      this.clientStudyForm.vinculos_PEP = [];
      this.switchPepValid = false;
    }
  }

  handlePublicValidPep($event) {    
    setTimeout(() => {
      this.publicPepValid = $event;
    }, 1);
  }

  handleSwitchPublicPep() {
    if (this.switches.pep === false) {
      this.switchPublicPepValid = true;
      this.clientStudyForm.administradores_PEP = [
        {
          nombres: "",
          tipo_documento: "",
          documento: "",
          cotiza_bolsa: "",
        },
      ];
      this.publicPepValid = false;
    } else {
      this.clientStudyForm.administradores_PEP = [];
      this.switchPublicPepValid = false;
    }
  }

  handleValidPartner($event) {
    setTimeout(() => {
      this.partnerValid = $event;
    }, 1);
  }

  handleValidCommercialReference($event) {
    setTimeout(() => {
      this.commercialReferenceValid = $event;
    }, 1);
  }

  get validateAddress() {
    return (
      this.clientStudyForm.addressType !== "" &&
      (this.clientStudyForm.addressFirst !== "" ||
        this.clientStudyForm.addressSecond !== "" ||
        this.clientStudyForm.addressThird !== "" ||
        this.clientStudyForm.addressOther !== "")
    );
  }

  nextStep(isShowXL: boolean) {
    this.tabs.changeNextStep();
    this.tabs.changeIsShowXL(isShowXL);
  }

  onBlur($event) {
    /* this.valueChanges.emit(); */
  }

  validateYear($event) {
    let date = moment(this.clientStudyForm.fecha_expedicion, "DD/MM/YYYY").format("DD/MM/YYYY")

    if(this.clientStudyForm.fecha_expedicion) {
      return;
    }
    
    if(!this.clientStudyForm.fecha_expedicion) {
      const dateNow = $event.target.value;
      const parseDate = dateNow.split("/");
      const reverseDate = parseDate.reverse();
      const joinDate = reverseDate.join()
      
      this.clientStudyForm.fecha_expedicion = new Date(joinDate);
    }
    
    if(date !== "Invalid date") {
      $event.target.value = moment(this.clientStudyForm.fecha_expedicion).format("DD/MM/YYYY");
      this.clientStudyForm.fecha_expedicion = new Date(date);
    }      
  }

  validatePreviousRequest() {
    const userCredentials = {
      comprador_tipo_documento: (this.clientStudyForm.comprador_tipo_documento !== 'C' && this.clientStudyForm.comprador_tipo_documento !== 'E') ? 'N' : this.clientStudyForm.comprador_tipo_documento,
      comprador_documento: this.clientStudyForm.comprador_documento
    }
    this.backend.validatePreviousRequest(userCredentials).subscribe((resp: any) => {
      if(resp.ESTADO_CUPO === "ACTIVO") {
        this.subject.changeStateInvalidDataError(true);
        this.subject.changeStateMessageError("Ya tiene un cupo asignado, para mayor información comuníquese con +57 318 7116639, en Bogotá 3534324 o a la línea gratuita 018000946969");
        this.modal.show("errorMessage");
      }       
    })
  }

  onBlurValidate($event) {
    this.validateChange.emit();
  }

  emitChanges($event) {
    this.valueChanges.emit();
  }

  setRef1Valid(value: boolean) {
    this.isRef1Valid = value;
  }

  setRef2Valid(value: boolean) {
    this.isRef2Valid = value;
  }

  setAcceptTerms(value: boolean) {
    this.isTermAccepted = value;
  }

  changeLegalFirstName(firstName: string) {
    this.subject.chanteStateLegalFirstName(firstName);
  }

  changeLegalSecondName(secondName: string) {
    this.subject.chanteStateLegalSecondName(secondName);
  }

  setNamesLegal() {
    this.changeLegalFirstName(this.legalRepresentativeName.firstName || '');
    this.changeLegalSecondName(this.legalRepresentativeName.secondName || '');
  }

  copyInfo() {
    this.clientStudyForm.nom1_contacto = this.legalRepresentativeName.firstName + " " + this.legalRepresentativeName.secondName;
    this.clientStudyForm.nom2_contacto = this.legalRepresentativeName.secondName
    this.clientStudyForm.apel1_contacto = this.clientStudyForm.apel1_rep + " " + this.clientStudyForm.apel2_rep;
    this.clientStudyForm.apel2_contacto = this.clientStudyForm.apel2_rep;
    this.clientStudyForm.correo_contacto = this.clientStudyForm.correo;
    this.clientStudyForm.cel_contacto = this.clientStudyForm.celular;
    this.isInformationCopied = (this.isInformationCopied === false) ? true : true;
    /* this.clientStudyForm.contactCellular = this.clientStudyForm.cellular;
    this.clientStudyForm.contactEmail = this.clientStudyForm.email;
    this.clientStudyForm.contactFirstName =
      this.clientStudyForm.legalRepresentativeFirstName;
    this.clientStudyForm.contactSecondName =
      this.clientStudyForm.legalRepresentativeSecondName;
    this.clientStudyForm.contactFirstLastName =
      this.clientStudyForm.legalRepresentativeFistLastName;
    this.clientStudyForm.contactSecondLastName =
      this.clientStudyForm.legalRepresentativeSecondLastName; */
  }

  onHomeDepartmentChanged(expeditionDepartmentId) {
    this.homeDepartmentChanged.emit(expeditionDepartmentId);
  }
  onExpeditionDepartmentChanged(expeditionDepartmentId) {
    this.expeditionDepartmentChanged.emit(expeditionDepartmentId);
  }

  handleSubmit() {
    try {
      console.log('///// Desde el handle Submit de legal, clientStudyForm: ', this.clientStudyForm);
      this.promissoryNoteService.saveDataRequest({ ...this.clientStudyForm } as FormStudyJsonModel);
      this.subject.changeStep(5);
      this.analytics.trackWebEvent('LinkCupoReferenciaComercial_Ok');
      this.trackEventCounterPart();
      return;
    } catch (error) {
      this.analytics.trackWebEvent('LinkCupoReferenciaComercial_Error');
      return;
    }

    this.spinner.show();
    const bodyGenerateOtp = {
      tipo_documento_comprador: (this.clientStudyForm.comprador_tipo_documento !== 'C' && this.clientStudyForm.comprador_tipo_documento !== 'E') ? 'N' : this.clientStudyForm.comprador_tipo_documento,
      documento_comprador: this.clientStudyForm.comprador_documento,
      correo: this.clientStudyForm.correo,
      celular: this.clientStudyForm.celular
    }

    this.backend.generateOtpCode(bodyGenerateOtp).subscribe(resp => {
      this.subject.changeStateSentCoupon(false);
      this.modal.show('validateOtp');
    }, (err) => {
      const errorMessage = `${err.status || "ANY ERROR"} - ${ err.error.message || err.statusText}`;
      
      this.subject.changeStateInvalidDataError(true);
      /* this.subject.changeStateMessageError("Ha ocurrido un error al generar el código OTP"); */
      this.subject.changeStateMessageError(errorMessage);
      this.modal.show("errorMessage");
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });

    /* this.modal.show('errorMessage'); */

    
  }

  trackEventContact(){
    try {
      this.analytics.trackWebEvent('LinkCupoDatosContacto_Ok');
      this.trackEventCompanyType();
    } catch (error) {
      this.analytics.trackWebEvent('LinkCupoDatosContacto_Error');
    }
  }

  trackEventCompanyType(): void {
    const { tipo_empresa } = this.clientStudyForm;
    switch (tipo_empresa) {
      case 'UNIPERSONAL':
        this.analytics.trackWebEvent('LinkCupoTipoEmpresa_Unipersonal_Ok');
        break;
      case 'SOCIEDAD':
        this.analytics.trackWebEvent('LinkCupoTipoEmpresa_Sociedad_Ok');
        break;
      default:
        console.log('No se reconoció el tipo de empresa para registarar el evento');
        break;
    }
  }

  trackEventCounterPart() {
    if (this.clientStudyForm.recursos_publicos) {
      if (this.clientStudyForm.clientType === 'natural') this.analytics.trackWebEvent('LinkCupoNatural_Contraparte_Ok');
      if (this.clientStudyForm.clientType === 'legal') this.analytics.trackWebEvent('LinkCupoJuridica_Contraparte_Ok');
    }
  }

  loadDataMock() {
    this.route.queryParams.subscribe(params => {
      const dataMock = params["dataMock"];
      if (dataMock === 'legal') {
        this.clientStudyForm.comprador_documento = "8600284621";
        this.clientStudyForm.doc_rep = "1006697151";
        this.clientStudyForm.celular = "3113811203";
        this.clientStudyForm.correo = "sergio.osorio@covinoc.com"

        this.clientStudyForm.nombre_empresa = "Covinoc SA Testing";
        this.clientStudyForm.lista_municipio = "11001";
        this.clientStudyForm.valor_cupo = "120000";

        this.clientStudyForm.nom1_contacto = "Sergio";
        this.clientStudyForm.apel1_contacto = "Osorio";
        this.clientStudyForm.cargo_contacto = "Desarrollador";
        this.clientStudyForm.cel_contacto = "3113811203";
        this.clientStudyForm.correo_contacto = "sergio.osorio@covinoc.com";
        this.handleValidCommercialReference(true);
      }
    });
  }

}
