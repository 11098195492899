import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BackEndService } from '@app/services/back-end.service';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-modal-accept-electronic-sign',
  templateUrl: './modal-accept-electronic-sign.component.html',
  styleUrls: ['./modal-accept-electronic-sign.component.scss']
})
export class ModalAcceptElectronicSignComponent implements OnInit, AfterViewInit {

  @Input() bodyModal: any;
  public electronicSign: any;

  constructor(public modal: ModalService, public backend: BackEndService, public subject: SubjectService) { }

  ngOnInit() {
    this.subject.stateElectronicSign$.subscribe((resp) => {
      this.electronicSign = resp;
    })
  }

  ngAfterViewInit() {
  }

}
