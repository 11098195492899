export class MassiveTransactionRequest {
  idContry: string;
  documentType: string;
  documentNumber: string;
  userName: string;
  check: string;

  public static toRequest(
    massiveTransactionRequest: MassiveTransactionRequest
  ): any {
    return {
      id_pais: massiveTransactionRequest.idContry,
      tipo_documento: massiveTransactionRequest.documentType,
      documento: massiveTransactionRequest.documentNumber,
      usuario: massiveTransactionRequest.userName,
      verificar: massiveTransactionRequest.check,
    };
  }
}
