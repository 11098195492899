import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // properties
  private urlApi: string;
  public headers: HttpHeaders;
  public identity: any;
  public userToken: any;
  public permissions: Array<any>;

  constructor(private http: HttpClient) {
    this.urlApi = environment.apiUrl;
    this.readToken();
  }

  login(user: any) {
    this.isAutenticated();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers };
    const userLogin = {
        user: user.username,
        password: user.password,
    };

    return this.http.post(`${this.urlApi}/backings/login`, userLogin, options).pipe(
      map((resp: any) => {
        // Expires
        const expiraToken = new Date(resp.expira);
        localStorage.setItem('expiresCF', expiraToken.getTime().toString());
        localStorage.setItem('identity', JSON.stringify(resp));
        localStorage.setItem(
          'user',
          JSON.stringify({
            user: userLogin.user,
            token: resp['token'],
          })
        );
        this.saveToken(resp['token']);
        return resp;
      })
    );
  }

  recoverPassword(username: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const recoverPassword: any = {
      username,
    };
    const options = {
      params: recoverPassword,
      headers,
    };

    return this.http.get(`${this.urlApi}/envio_olvide_contrasena`, options);
  }

  private saveToken(token: string) {
    this.userToken = token;
    localStorage.setItem('tokenCF', token);
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  logout() {
    localStorage.removeItem('tokenCF');
  }

  getIdentity() {
    return (this.identity = 
      JSON.parse(localStorage.getItem('user')
    ));
  }

  isAutenticated(): boolean {
    const today = new Date().getTime();
    const expira = Number(localStorage.getItem('expiresCF'));
    if (
      localStorage.getItem('tokenCF') === undefined ||
      localStorage.getItem('tokenCF') === null ||
      expira < today
    ) {
      return false;
    } else {
      return this.userToken;
    }
  }

  // Examples:
  post(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }

  put(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }
}
