import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public documentTypes: Array<DocumentType>;
  public departments: Array<any>;
  public banks: Array<any>;
  public regexOnlyText: any = /^[a-zA-Z\s]*$/;

  constructor(private toastr: ToastrService, private router: Router) {}

  createArray(cantElements: number) {
    return Array.from(new Array(cantElements), (x, i) => i + 1);
  }

  currencyInputChanged(value: any) {
    if (!value) {
      return;
    }
    const num = value;
    return Number(num.replace(/[\$,\. ]/g, ""));
  }

  logOut() {
    this.router.navigateByUrl("/login");
    localStorage.clear();
  }

  onlyText($event) {
    if (typeof $event.key === 'string' && $event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/) === -1) {
      $event.preventDefault();
    }
  }

  onlyTextAddress($event) {
    if (typeof $event.key === 'string' && $event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\-\s\d]*$/) === -1) {
      $event.preventDefault();
    }
  }

  onlyAlphaNumeric($event) {
    let letters = /^[A-Za-z]+$/;
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13 ||
      $event.key.match(letters)
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      typeof $event.key === 'string' &&
      $event.key.search(/\d\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  pasteOnlyNumber($event) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(/[^0-9]*/g, "");
  }

  markForm(form?: NgForm, toast?: boolean, time?: number, textToast?: string) {
    const timeOut = time || 12000;
    const markForm = form.form.controls;
    const message =
      textToast || "Los campos marcados con rojo son obligatorios.";

    setTimeout(() => {
      Object.keys(markForm).forEach((control, index) => {
        form.form.controls[control].markAsTouched();
      });
      if (toast) {
        this.toastr.warning(message, null, {
          timeOut,
          positionClass: "toast-bottom-right",
        });
      }
    }, 500);
  }

  onlyNumber($event: any) {
    const arrayCodes = [8, 9, 37, 39, 91, 86];
    if (arrayCodes.includes($event.keyCode)) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      typeof $event.key === 'string' && 
      $event.key.search(/\d\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  onlyNumberAmount($event) {
    
    const arrayCodes = [8, 9, 37, 39, 91, 86];
    if (arrayCodes.includes($event.keyCode)) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      typeof $event.key === 'string' &&
      $event.key.search(/^\d{1,3}(\.\d{1,3})?$/) === -1
    ) {
      $event.preventDefault();
    }
  }

  pasteOnlyNumberAmount($event) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(/[\d\.*\$*{1,1}\b]/, "");
  }

  onlyAlphaNumericAddress($event) {
    let letters = /^[A-Za-z]+$/;
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13 ||
      $event.key.match(letters)
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d+\b/) === -1
    ) {
      $event.preventDefault();
    }
    
    
  }

  pasteOnlyText($event: any) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    
    $event.preventDefault();
    return pastedText.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/g, "");
    //return pastedText.replace(/[^a-zA-Z]/g, "");
  }

  pasteOnlyTextAddress($event: any) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    
    $event.preventDefault();
    return pastedText.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\-\s]*$/g, "");
    //return pastedText.replace(/[^a-zA-Z]/g, "");
  }

  

  maxLength(event, maxLength) {
    const value = event.target["value"];
    if (value.length + 1 > maxLength) {
      {
        if (
          event.which !== 46 &&
          event.which !== 8 &&
          event.which !== 37 &&
          event.which !== 39 &&
          event.which !== 9
        ) {
          event.preventDefault();
        }
      }
    }
  }

  maskDate($event): void {
    let dateText = $event.target.value;
    let value = dateText.replace(/\D/g, '');
    if (value.length >= 5) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
      //console.log(`Fecha: ${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`);
      
    } else if (value.length >= 3) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    $event.target.value = value.slice(0, 10);
    
    // return v.slice(0, 10);
  }

  getAmountOfPages(registers: any, pageLimit: number) {
    return registers.length > 0
      ? Math.ceil(parseInt(registers[0].numberRecords || 1) / pageLimit)
      : 1;
  }

  base64ToBinary(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  refresh(actualComponent: string) {
    this.router
      .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([actualComponent]);
      });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  validatePatternDocument(documentType: string): RegExp {
    switch (documentType) {
      case "E":
        return new RegExp("^(\\d{0,8})$");
      case "N":
        return new RegExp("^(\\d{0,9}|\\d{10})$");
      default:
        return new RegExp("^(\\d{0,8}|\\d{10})$");
    }
  }
}
