import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateResultsRequest, ValidateResultsResponse } from '@app/models/validateResult.model';

@Injectable({
  providedIn: 'root'
})
export class PromissoryNoteServiceService {

  private apiUrl: string = "https://wsbolp.c0v.co/olimpia/api/v1";

  public headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('CLIENT_APP-19921ef5:23bHloreqW@7'),
  });

  constructor(private http: HttpClient) { }

  validateResults(processCoded: string) {
    const body: ValidateResultsRequest = new ValidateResultsRequest(processCoded);
    const options = { headers: this.headers };
    return this.http.post<ValidateResultsResponse>(`${this.apiUrl}/recognizedId/api/validateResults`, body, options);
  }
}
