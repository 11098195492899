import { Component, Output, Input, EventEmitter } from '@angular/core';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  public pages = [];
  public selectedPage = 0;
  @Output() idSelectedPage: EventEmitter<object> = new EventEmitter();
  @Input() cantPages: number;

  constructor(public helper: HelperService) {
    this.pages = this.helper.createArray(this.cantPages || 4);
  }

  reset() {
    this.selectedPage = 0;
  }

  ngOnChanges(changes: any) {
    this.pages = this.helper.createArray(this.cantPages || 4);
  }

  selectPage(idx: number) {
    this.selectedPage = idx;
    
    this.idSelectedPage.emit({
      currentPage: Number(this.selectedPage) + 1,
    });
  }

  nextPage() {
    if (this.selectedPage >= this.pages.length - 1) {
      return;
    }
    this.selectedPage++;
    this.idSelectedPage.emit({
      currentPage: this.selectedPage + 1,
    });
  }

  previousPage() {
    if (!this.selectedPage) {
      return;
    }
    this.selectedPage--;
    this.idSelectedPage.emit({
      currentPage: this.selectedPage + 1,
    });
  }

  limitChange() {
    this.idSelectedPage.emit({
      currentPage: this.selectedPage + 1,
    });
  }
}
