import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BackEndService } from '@app/services/back-end.service';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-modal-source-funds',
  templateUrl: './modal-source-funds.component.html',
  styleUrls: ['./modal-source-funds.component.scss']
})
export class ModalSourceFundsComponent implements OnInit, AfterViewInit {

  @Input() bodyModal: any;
  public sourceFunds: any;

  constructor(public modal: ModalService, public backend: BackEndService, public subject: SubjectService) { }

  ngOnInit() {
    this.subject.stateSourceFunds$.subscribe((resp) => {
      this.sourceFunds = resp;
    })
  }

  ngAfterViewInit() {
  }

}
