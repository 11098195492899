import { Component } from '@angular/core';
import { SubjectPromissoryService } from '../services/subject.service';
import { QuotaRequestResponse } from '../models/quota-request.model';

@Component({
  selector: 'app-modal-quota-request-invalid',
  templateUrl: './modal-quota-request-invalid.component.html',
  styleUrls: ['./modal-quota-request-invalid.component.scss']
})
export class ModalQuotaRequestInvalidComponent {

  public modalName: string = 'modalQuotaRequestInvalid';
  public externalMessage: string = '';

  constructor(
    private subjectPromissorySubject: SubjectPromissoryService
  ) {
    this.subjectPromissorySubject.responseQuotaRequest$.subscribe(this.handleQuotaRequestResponse.bind(this));
  }

  handleQuotaRequestResponse(response: QuotaRequestResponse) {
    console.log('Respuesta del subject', response);
    if(response["MENSAJE_EXTERNO "]) this.externalMessage = response["MENSAJE_EXTERNO "];
  }

  handleClick() {
    window.location.reload();
  }
}
