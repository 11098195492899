import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-close',
  templateUrl: './button-close.component.html',
  styleUrls: ['./button-close.component.scss']
})
export class ButtonCloseComponent {

  @Input() type: 'close' | 'back' = 'close';
  @Input() extraClass: string = '';

  public srcIcon: any = {
    close: 'assets/img/icons/icon-x-close-new.svg',
    back: 'assets/img/icons/icon-back-button.svg'
  }

  get getAlt() {
    return this.type === 'close' ? 'Icono de cerrar': 'Icono de regresar' 
  }

  constructor() { }
}
