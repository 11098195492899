import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embed-tableau',
  templateUrl: './embed-tableau.component.html',
  styleUrls: ['./embed-tableau.component.scss']
})
export class EmbedTableauComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
