export class GetInfoRequest {
    documento: string = '';
    tipoDocumento: string = '';
    tipo: GetInfoType = 'F';
}

export type GetInfoType = '' | 'F';

export class GetInfoResponse {
    ok!: boolean;
    message!: string;
    data!: GetInfoResponseModel;
    status!: number;
}

export interface GetInfoResponseModel {
    codigoConsulta: string;
    contrato: string;
    nombreAfiliado: string;
    municipio: string;
    celular: string;
}