import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '@guards/auth.guard';
import { RolGuard } from '@app/guards/rol.guard';
import { AffiliateQuotaComponent } from './affiliate-quota/affiliate-quota.component';
import { TokenGuard } from '@app/guards/token.guard';
import { ValidateIdentityComponent } from '@app/components/validate-identity/validate-identity.component';

const routes: Routes = [
  {
    path: 'cupo-afiliado',
    component: PagesComponent,
    
    children: [
      {
        path: '',
        component: AffiliateQuotaComponent,
      },
      { path: '**', pathMatch: 'full', redirectTo: 'not-found' },      
    ],
  }
  /* {
    path: 'pages',
    
    canActivate: [AuthGuard, RolGuard],
    children: [
      {
        path: 'cupo',
        component: AffiliateQuotaComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: AffiliateQuotaComponent, canActivate: [AuthGuard] },
        ]
      },
      { path: '**', pathMatch: 'full', redirectTo: '/not-found' },
    ],
  }, */
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
