import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

// LIBERIAS
import { ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';

// COMPONENTS
import { CardPromissoryNotePreviewComponent } from './card-promissory-note-preview/card-promissory-note-preview.component';
import { CardOtpComponent } from './card-otp/card-otp.component';
import { OtpResendComponent } from './otp-resend/otp-resend.component';
import { InputCodeComponent } from './input-code/input-code.component';
import { ButtonComponent } from './button/button.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ModalComponent } from './modal/modal.component';
import { ButtonCloseComponent } from './button-close/button-close.component';
import { ModalResendOtpOkComponent } from './modal-resend-otp-ok/modal-resend-otp-ok.component';
import { ModalValidateOtpErrorComponent } from './modal-validate-otp-error/modal-validate-otp-error.component';
import { ModalResendOtpErrorComponent } from './modal-resend-otp-error/modal-resend-otp-error.component';
import { ModalQuotaRequestDeniedComponent } from './modal-quota-request-denied/modal-quota-request-denied.component';
import { ModalQuotaRequestApprovedComponent } from './modal-quota-request-approved/modal-quota-request-approved.component';
import { ModalQuotaRequestInProcessComponent } from './modal-quota-request-in-process/modal-quota-request-in-process.component';
import { ModalQuotaRequestInvalidComponent } from './modal-quota-request-invalid/modal-quota-request-invalid.component';
import { ModalQuotaRequestErrorComponent } from './modal-quota-request-error/modal-quota-request-error.component';
import { ModalAfiliateInfoErrorComponent } from './modal-afiliate-info-error/modal-afiliate-info-error.component';
import { ModalOtpSendErrorComponent } from './modal-otp-send-error/modal-otp-send-error.component';
import { ModalStartProcessErrorComponent } from './modal-start-process-error/modal-start-process-error.component';


@NgModule({
  declarations: [
    CardPromissoryNotePreviewComponent, CardOtpComponent, 
    OtpResendComponent, InputCodeComponent, ButtonComponent, 
    SpinnerComponent, ModalComponent, ButtonCloseComponent, 
    ModalValidateOtpErrorComponent, ModalResendOtpOkComponent, 
    ModalResendOtpErrorComponent, ModalQuotaRequestDeniedComponent, 
    ModalQuotaRequestApprovedComponent, ModalQuotaRequestInProcessComponent, 
    ModalQuotaRequestInvalidComponent, ModalQuotaRequestErrorComponent, ModalAfiliateInfoErrorComponent, ModalOtpSendErrorComponent, ModalStartProcessErrorComponent
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ], 
  exports: [CardPromissoryNotePreviewComponent, CardOtpComponent],
  providers: [CurrencyPipe]
})
export class PromissoryNoteModule { }
