
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { format } from 'date-fns';
import { UtilsWindowService } from './utils-window.service';
import { FetchService } from './fetch.service';
declare let gtag: any;

// Version Angular <= 7
enum eventValidTypes {
  LinkCupoHome_Ok = 'LinkCupoHome_Ok',
  LinkCupoNatural_Ok = 'LinkCupoNatural_Ok',
  LinkCupoNatural_Error = 'LinkCupoNatural_Error',
  LinkCupoJuridica_Ok = 'LinkCupoJuridica_Ok',
  LinkCupoJuridica_Error = 'LinkCupoJuridica_Error',
  LinkCupoValidacionIdentidad_Ok = 'LinkCupoValidacionIdentidad_Ok',
  LinkCupoValidacionIdentidad_Error = 'LinkCupoValidacionIdentidad_Error',
  LinkCupoDatosContacto_Ok = 'LinkCupoDatosContacto_Ok',
  LinkCupoDatosContacto_Error = 'LinkCupoDatosContacto_Error',
  LinkCupoReferenciaComercial_Ok = 'LinkCupoReferenciaComercial_Ok',
  LinkCupoReferenciaComercial_Error = 'LinkCupoReferenciaComercial_Error',
  LinkCupoPrevisualizarFau_Ok = 'LinkCupoPrevisualizarFau_Ok',
  LinkCupoPrevisualizarFau_Error = 'LinkCupoPrevisualizarFau_Error',
  LinkCupoGenerarOtp_Ok = 'LinkCupoGenerarOtp_Ok',
  LinkCupoGenerarOtp_Error = 'LinkCupoGenerarOtp_Error',
  LinkCupoReenviarOtp_Ok = 'LinkCupoReenviarOtp_Ok',
  LinkCupoReenviarOtp_Error = 'LinkCupoReenviarOtp_Error',
  LinkCupoFirmar_Ok = 'LinkCupoFirmar_Ok',
  LinkCupoFirmar_Error = 'LinkCupoFirmar_Error',
  LinkCupoSolicitarCupo_Ok = 'LinkCupoSolicitarCupo_Ok',
  LinkCupoSolicitarCupo_Error = 'LinkCupoSolicitarCupo_Error',
  LinkCupoTipoEmpresa_Unipersonal_Ok = 'LinkCupoTipoEmpresa_Unipersonal_Ok',
  LinkCupoTipoEmpresa_Sociedad_Ok = 'LinkCupoTipoEmpresa_Sociedad_Ok',
  LinkCupoNatural_Contraparte_Ok = 'LinkCupoNatural_Contraparte_Ok',
  LinkCupoJuridica_Contraparte_Ok = 'LinkCupoJuridica_Contraparte_Ok'
};

// Version Angular >= 8
// const eventValidTypes = [
//   /*1*/'LinkCupoHome_Ok',
//   /*2*/'LinkCupoNatural_Ok',
//   /*3*/'LinkCupoNatural_Error',
//   /*4*/'LinkCupoJuridica_Ok',
//   /*5*/'LinkCupoJuridica_Error',
//   /*6*/'LinkCupoValidacionIdentidad_Ok',
//   /*7*/'LinkCupoValidacionIdentidad_Error',
//   /*8*/'LinkCupoDatosContacto_Ok',
//   /*9*/'LinkCupoDatosContacto_Error',
//   /*10*/'LinkCupoReferenciaComercial_Ok',
//   /*11*/'LinkCupoReferenciaComercial_Error',
//   /*12*/'LinkCupoPrevisualizarFau_Ok',
//   /*13*/'LinkCupoPrevisualizarFau_Error',
//   /*14*/'LinkCupoGenerarOtp_Ok',
//   /*15*/'LinkCupoGenerarOtp_Error',
//   /*16*/'LinkCupoReenviarOtp_Ok',
//   /*17*/'LinkCupoReenviarOtp_Error',
//   /*18*/'LinkCupoFirmar_Ok',
//   /*19*/'LinkCupoFirmar_Error',
//   /*20*/'LinkCupoSolicitarCupo_Ok',
//   /*21*/'LinkCupoSolicitarCupo_Error',
//  // Custom events
//   /*22*/'LinkCupoTipoEmpresa_Unipersonal_Ok',
//   /*23*/'LinkCupoTipoEmpresa_Sociedad_Ok',
//   /*24*/'LinkCupoNatural_Contraparte_Ok',
// ] as const;
// type eventNameTypes = typeof eventValidTypes[number]; Version Angular >= 8

type eventNameTypes = keyof typeof eventValidTypes;
export type EventTypes = eventNameTypes;

class AnalyticsModel {
  event?: eventNameTypes;
  action?: string = '';
  event_category?: string = '';
  event_label?: string = '';
  event_value?: any = 0 | 1;
  platform?: string = '';
  browser?: string = '';
  os?: string = '';
  idSession?: string = '';
  source?: string = '';
  status?: string = '';
  ip?: string = '';
};

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public applicationEvents: AnalyticsModel[] = [
    /*01*/ { event: 'LinkCupoHome_Ok', action: 'Iniciar', event_category: 'Home', event_label: 'Iniciar', event_value: 1 },
    /*02*/ { event: 'LinkCupoNatural_Ok', action: 'TipoPersona', event_category: 'Home', event_label: 'TipoPersona', event_value: 1 },
    /*02*/ { event: 'LinkCupoNatural_Error', action: 'TipoPersona', event_category: 'Home', event_label: 'TipoPersona', event_value: 1 },
    /*04*/ { event: 'LinkCupoJuridica_Ok', action: 'TipoPersona', event_category: 'Home', event_label: 'TipoPersona', event_value: 1 },
    /*05*/ { event: 'LinkCupoJuridica_Error', action: 'TipoPersona', event_category: 'Home', event_label: 'TipoPersona', event_value: 1 },
    /*06*/ { event: 'LinkCupoValidacionIdentidad_Ok', action: 'Validar', event_category: 'Validacion', event_label: 'ValidarIdentidad', event_value: 1 },
    /*07*/ { event: 'LinkCupoValidacionIdentidad_Error', action: 'Validar', event_category: 'Validacion', event_label: 'ValidarIdentidad', event_value: 0 },
    /*08*/ { event: 'LinkCupoDatosContacto_Ok', action: 'Validar', event_category: 'Validacion', event_label: 'ValidarDatosContacto', event_value: 1 },
    /*09*/ { event: 'LinkCupoDatosContacto_Error', action: 'Validar', event_category: 'Validacion', event_label: 'ValidarDatosContacto', event_value: 0 },
    /*10*/ { event: 'LinkCupoReferenciaComercial_Ok', action: 'Validar', event_category: 'Validacion', event_label: 'ValidarReferenciaComercial', event_value: 1 },
    /*11*/ { event: 'LinkCupoReferenciaComercial_Error', action: 'Validar', event_category: 'Validacion', event_label: 'ValidarReferenciaComercial', event_value: 0 },
    /*12*/ { event: 'LinkCupoPrevisualizarFau_Ok', action: 'Previsualizar', event_category: 'Previsualizar', event_label: 'PrevisualizarFau', event_value: 1 },
    /*13*/ { event: 'LinkCupoPrevisualizarFau_Error', action: 'Previsualizar', event_category: 'Previsualizar', event_label: 'PrevisualizarFau', event_value: 0 },
    /*14*/ { event: 'LinkCupoGenerarOtp_Ok', action: 'Generar', event_category: 'OTP', event_label: 'GenerarOtp', event_value: 1 },
    /*15*/ { event: 'LinkCupoGenerarOtp_Error', action: 'Generar', event_category: 'OTP', event_label: 'GenerarOtp', event_value: 0 },
    /*16*/ { event: 'LinkCupoReenviarOtp_Ok', action: 'Reenviar', event_category: 'OTP', event_label: 'ReenviarOtp', event_value: 1 },
    /*17*/ { event: 'LinkCupoReenviarOtp_Error', action: 'Reenviar', event_category: 'OTP', event_label: 'ReenviarOtp', event_value: 0 },
    /*18*/ { event: 'LinkCupoFirmar_Ok', action: 'Firmar', event_category: 'Firma', event_label: 'Firmar', event_value: 1 },
    /*19*/ { event: 'LinkCupoFirmar_Error', action: 'Firmar', event_category: 'Firma', event_label: 'Firmar', event_value: 0 },
    /*20*/ { event: 'LinkCupoSolicitarCupo_Ok', action: 'Solicitar', event_category: 'Cupo', event_label: 'SolicitarCupo', event_value: 1 },
    /*21*/ { event: 'LinkCupoSolicitarCupo_Error', action: 'Solicitar', event_category: 'Cupo', event_label: 'SolicitarCupo', event_value: 0 },
    // Custom events
    /*22*/ { event: 'LinkCupoTipoEmpresa_Unipersonal_Ok', action: 'TipoEmpresa', event_category: 'TipoEmpresa', event_label: 'TipoEmpresa', event_value: 1 }, // Esto aplica para persona juridica
    /*23*/ { event: 'LinkCupoTipoEmpresa_Sociedad_Ok', action: 'TipoEmpresa', event_category: 'TipoEmpresa', event_label: 'TipoEmpresa', event_value: 1 }, // Esto aplica para persona juridica
    /*24*/ { event: 'LinkCupoNatural_Contraparte_Ok', action: 'TipoPersona', event_category: 'Contraparte', event_label: 'TipoPersona', event_value: 1 },
    /*25*/ { event: 'LinkCupoJuridica_Contraparte_Ok', action: 'TipoEmpresa', event_category: 'Contraparte', event_label: 'TipoEmpresa', event_value: 1 },
    
  ];


  public analytics!: AnalyticsModel;
  public isNative: boolean = false;
  private trackerIdProduction: string = 'XXXXXX';
  private trackerIdTesting: string = 'XXXXXX';
  // private trackerIdUAProduction: string = 'XXXXXX';
  public currentRoute!: string;
  protected headers: HttpHeaders = new HttpHeaders();
  private urlApiAnalytics: string = '';
  private source: string = '0';


  constructor(
    protected router: Router,
    private http: HttpClient,
    private utilsWindow: UtilsWindowService,
    private fetch: FetchService
  ) {
    this.headers = this.fetch.getBasicAuthorizationAnalytics();
    this.urlApiAnalytics = environment.urlApiAnalytics;
    this.getSource();
    this.initializeWebAnalytics(this.router);
    this.setOperativeSystem();
    // this.trackWebEvent("loginOK"); // Ejemplo de implemetación de evento
  }

  initializeWebAnalytics(router: Router) {
    const navEndEvents$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents$.subscribe((event: any) => {
      this.currentRoute = event.url;
      gtag('config', this.trakerIdByEnv, {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  setOperativeSystem() {
    const operativeSystem: string = this.utilsWindow.getUA() || '';
    // this.store.dispatch(setOperativeSystem({ operativeSystem }));
    console.log('operativeSystem ->', operativeSystem);
  }

  get trakerIdByEnv(): string {
    const trackerId: string = (environment.production === true) ? this.trackerIdProduction : this.trackerIdTesting;
    console.log('analytics environment ->', environment.name, environment.production, trackerId);
    return trackerId;
  }

  trackEvent(eventName: EventTypes, information?: AnalyticsModel) {
    this.trackWebEvent(eventName, information);
  }

  trackWebEvent(eventName: EventTypes, information?: AnalyticsModel): void {
    const { event, action, event_category, event_label, event_value } = this.setEvent(eventName, information);
    (window as any).gtag("event", event, { action, event_category, event_label, event_value });
    this.setTagOnCovinocService({ event, action, event_category, event_label, event_value });
  }

  setEvent(eventName: EventTypes, information?: AnalyticsModel): AnalyticsModel {
    let event = this.applicationEvents.find(event => event.event === eventName) || new AnalyticsModel();
    if (information !== undefined) {
      const { action, event_category, event_label, event_value } = information;
      event.action = action || event.action;
      event.event_value = event_value || event.event_value;
      event.event_label = event_label || event.event_label;
      event.event_category = event_category || event.event_category;
    }

    return event;
  }

  getSource() {
    const params = {};
    const options = { headers: this.headers, params };

    this.http.get(`${this.urlApiAnalytics}/sources/all?pageSize=10`, options)
      .subscribe((response: any) => {
        const sourceSearched = response.data.find((source: any) => source.name === 'LINK_SOLICITUD_CUPO');
        this.source =  (sourceSearched && sourceSearched.id) ?  sourceSearched.id : 0;
      });
  }

  setTagOnCovinocService(bodyTag: AnalyticsModel) {
    const status: string = bodyTag.event || '';
    const ip: string = '';

    bodyTag.platform = navigator.userAgent;
    bodyTag.browser = this.utilsWindow.getBrowser();
    bodyTag.os = this.utilsWindow.getUA();
    bodyTag.idSession = new Date().getTime().toString();
    bodyTag.source = this.source;
    bodyTag.status = status.includes('Ok') ? 'success' : 'error';
    bodyTag.ip = ip;
    // console.log('bodyTag ->', bodyTag);

    const params = {};
    const options = { headers: this.headers, params };

    this.http.post(`${this.urlApiAnalytics}/tags/save`, bodyTag, options)
      .subscribe((response: any) => console.log(response));
  }

}

/* 
private analyticsService: AnalyticsService
 Implementado (✅ - ❌) -- Evento
         ✅                this.trackWebEvent("LinkCupoHome_Ok");
         ✅                this.trackWebEvent("LinkCupoNatural_Ok​");
         ✅                this.trackWebEvent("LinkCupoNatural_Error​");
         ✅                this.trackWebEvent("LinkCupoJuridica_Ok​");
         ✅                this.trackWebEvent("LinkCupoJuridica_Error​");
         ✅                this.trackWebEvent("LinkCupoValidacionIdentidad_Ok​");
         ✅                this.trackWebEvent("LinkCupoValidacionIdentidad_Error​");
         ✅                this.trackWebEvent("LinkCupoDatosContacto_Ok​");
         ✅                this.trackWebEvent("LinkCupoDatosContacto_Error​");
         ✅                this.trackWebEvent("LinkCupoReferenciaComercial_Ok");
         ✅                this.trackWebEvent("LinkCupoReferenciaComercial_Error​");
         ✅                this.trackWebEvent("LinkCupoPrevisualizarFau_Ok​");
         ✅                this.trackWebEvent("LinkCupoPrevisualizarFau_Error");
         ✅                this.trackWebEvent("LinkCupoGenerarOtp_Ok");
         ✅                this.trackWebEvent("LinkCupoGenerarOtp_Error");
         ✅                this.trackWebEvent("LinkCupoReenviarOtp_Ok");
         ✅                this.trackWebEvent("LinkCupoReenviarOtp_Error");
         ✅                this.trackWebEvent("LinkCupoFirmar_Ok");
         ✅                this.trackWebEvent("LinkCupoFirmar_Error");
         ✅                this.trackWebEvent("LinkCupoSolicitarCupo_Ok");
         ✅                this.trackWebEvent("LinkCupoSolicitarCupo_Error");
         ✅                this.trackWebEvent("LinkCupoTipoEmpresa_Unipersonal_Ok");
         ✅                this.trackWebEvent("LinkCupoTipoEmpresa_Sociedad_Ok");
         ✅                this.trackWebEvent("LinkCupoNatural_Contraparte_Ok");
         ✅                this.trackWebEvent("LinkCupoJuridica_Contraparte_Ok");

*/


