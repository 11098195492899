export class ValidateResultsRequest {
    procesoConvenioGuid: string;
    constructor(procesoConvenioGuid: string) {
        this.procesoConvenioGuid = procesoConvenioGuid;
    }
}

export class ValidateResultsResponse {
    ok: boolean = false;
    message: boolean = false;
    data: ValidateResultsResponseModel = {} as ValidateResultsResponseModel;
    status: number = 0;
}

export class ValidateResultsResponseModel {
    code: string = '';
    data: ValidateResultsResponseDataModel = {} as ValidateResultsResponseDataModel;
    codeName: string = '';
}

export class ValidateResultsResponseDataModel {
    guidConv: string = '';
    procesoConvenioGuid: string = '';
    primerNombre: string = '';
    segundoNombre: string = '';
    primerApellido: string = '';
    segundoApellido: string = '';
    tipoDoc: string = '';
    numDoc: string = '';
    email: string = '';
    celular: string = '';
    asesor: string = '';
    sede: string = '';
    nombreSede: string = '';
    codigoCliente: string = '';
    estadoProceso: number = 0;
    estadoForense: string = '';
    motivoForense: string = '';
    motivoOpcionalForense: string = '';
    observacionesForense: string = '';
    estadoDescripcion: string = '';
    scoreProceso: string = '';
    aprobado: boolean = false;
    cancelado: boolean = false;
    motivoCancelacion: string = '';
    motivoId: number = 0;
    finalizado: boolean = false;
    encontradoEnFuente: boolean = false;
    fuentesAbiertas: string = '';
    emailAge: any[] = [];
    comparacionRostroDocumento: string = '';
    scoreRostroDocumento: string = '';
    fechaRegistro: string = '';
    fechaFinalizacion: string = '';
    servicios: Services[] = [];
}

export class Services {
    servicio: string = '';
    tipo: string = '';
    subTipos: string = '';
    score: number = 0;
    documentIsValid: string = '';
    barcode: string = '';
    fecha: string = '';
    terminado: boolean = false;
}