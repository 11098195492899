import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-counterpart-table-form',
  templateUrl: './counterpart-table-form.component.html',
  styleUrls: ['./counterpart-table-form.component.scss']
})
export class CounterpartTableFormComponent implements OnInit {

  @Input() clientStudyForm: any;

  dataCounterpartPep: any;

  public validatePatternDocument;

  constructor(public helper: HelperService, public tabs: TabsService) { }

  ngOnInit() {
    this.dataCounterpartPep[0].tipo_documento = 'C';
    this.validatePatternDocument = this.helper.validatePatternDocument('C');
  }
  handleValidateCounterPartPersonValidate() {
    this.validatePatternDocument = this.helper.validatePatternDocument(this.dataCounterpartPep.tipo_documento);
  }

}
