import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalyticsService } from '@app/services/analytics.service';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { OlimpiaService } from './olimpia.service';
import { PromissoryNotePreviewService } from './promissory-note-preview.service';
import { HelperDocumentsService } from '../utils/helper-documents.service';
import { ModalsService } from '../utils/modals.service';
import { IDocument, ISignatory, IssuePromissoryNoteRequest, IssuePromissoryNoteResponse, ItemplateFields } from '../models/issue-promissory-note.model';
import { IDENTIFICACION_COMERCIO, ID_TIPO_DOCUMENTO_PLANTILLA, REQUIERE_ATDP } from '../constants/olimpia.constants';
import { UserInfo } from '../models/user-info.model';
import { AnotationViewPromissoryNote, ViewPromissoryNoteRequest } from '../models/view-promissory-note.model';

@Injectable({
  providedIn: 'root'
})
export class IssuePromissoryNoteService {
  private storage: StorageService = new StorageService(true);

  constructor(
    private spinner: NgxSpinnerService,
    private olimpiaService: OlimpiaService,
    private promissoryNotePreview: PromissoryNotePreviewService,
    private helperDocuments: HelperDocumentsService,
    private modals: ModalsService,
    private analytics: AnalyticsService
  ) {
  }

  issuePromissoryNote() {
    this.spinner.show('sendingDocument');
    const request: IssuePromissoryNoteRequest = this.buildRequestToIssuePromissoryNote();
    this.olimpiaService.issuePromissoryNote(request).subscribe({
      next: (response: IssuePromissoryNoteResponse) => {
        console.log('Se emitió el documento con éxito', response);
        this.spinner.hide('sendingDocument');
        this.storage.setStorage('transaccionId', response.data.transaccionId || -1);
        this.analytics.trackWebEvent('LinkCupoGenerarOtp_Ok');
      },
      error: (error: any) => {
        console.error('Ocurrió un error al emitir el documento: ', error);
        this.spinner.hide('sendingDocument');
        this.analytics.trackWebEvent('LinkCupoGenerarOtp_Error');
        this.modals.show('modalOtpSendError');
      },
    });
  }

  buildRequestToIssuePromissoryNote(): IssuePromissoryNoteRequest {
    const resquest: IssuePromissoryNoteRequest = {
      idProcesoClienteExterno: this.storage.getStorage('idProcesoClienteExterno'), //Número aleatorio que se generó al momento de iniciar proceso
      identificacionComercio: IDENTIFICACION_COMERCIO,
      requiereATDP: REQUIERE_ATDP,
      documentos: this.buildDocumentsToIssuePromissoryNote(),
      firmantes: this.buildSignatureToIssuePromissoryNote(),
    };
    return resquest;
  }

  buildDocumentsToIssuePromissoryNote(): IDocument[] {
    const templateFields: ItemplateFields[] = this.transformPreviewFieldsToFieldsToIssuePromissoryNote();
    const documents: IDocument[] = [
      {
        idTipoDocumento: ID_TIPO_DOCUMENTO_PLANTILLA,
        serialPlantilla: environment.idTemplateFAU,
        camposPlantilla: templateFields
      }
    ];

    return documents;
  }

  buildSignatureToIssuePromissoryNote(): ISignatory[] {
    const userInfo: UserInfo =  JSON.parse(sessionStorage.getItem('userInfo_ID') || '{}');
    const { primerNombre, segundoNombre, primerApellido, segundoApellido, email, celular, numDoc, tipoDoc } = userInfo;
    const fullName = `${primerNombre} ${segundoNombre} ${primerApellido} ${segundoApellido}`.trim().replace('  ', ' ');
    const lastNames = `${primerApellido} ${segundoApellido}`.trim().replace('  ', ' ')

    const signature: ISignatory[] = [
      {
        nombre: fullName,
        apellido: lastNames,
        identificacion: numDoc,
        tipoIdentificacion: this.helperDocuments.findDocumentByLetters(tipoDoc).number_code || -1,
        correo: email,
        celular: celular,
        campoFirma: 'firma_fau'
      }
    ];

    return signature;
  }

  transformPreviewFieldsToFieldsToIssuePromissoryNote(): ItemplateFields[] {
    const previewedData: ViewPromissoryNoteRequest = this.promissoryNotePreview.getDataRequest();
    const previewFields: AnotationViewPromissoryNote[] = previewedData.plantillas[0].anotaciones || [];

    const fieldsToIssuePromissoryNote: ItemplateFields[] = previewFields.map(
      (previewField: AnotationViewPromissoryNote) => ({ CampoNombre: previewField.Nombre, CampoValor: previewField.Valor })
    );
    return fieldsToIssuePromissoryNote;
  }

}
