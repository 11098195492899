import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FetchService } from './fetch.service';
import { PromissoryNotePreviewService } from './promissory-note-preview.service';
import { StartProcessRequest, StartProcessResponse } from '../models/start-process.model';
import { ViewPromissoryNoteRequest, ViewPromissoryNoteResponse } from '../models/view-promissory-note.model';
import { IssuePromissoryNoteRequest, IssuePromissoryNoteResponse } from '../models/issue-promissory-note.model';
import { SignatureValidateOtpRequest, SignatureValidateOtpResponse } from '../models/signature-validate-otp.model';
import { ResendOtpRequest, ResendOtpResponse } from '../models/resend-otp.model';


@Injectable({
  providedIn: 'root'
})
export class OlimpiaService {

  public apiOlimpiaUrl: string = '';
  public headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private fetch: FetchService,
    private promissoryNotePreviewService: PromissoryNotePreviewService
  ) {
    this.headers = this.fetch.setBasicAuthorization(environment.apiOlimpiaUrlAuth);
    this.apiOlimpiaUrl = environment.apiOlimpiaUrl;
  }

  // Paso 1
  startProcess(body: StartProcessRequest): Observable<StartProcessResponse> {
    const options = { headers: this.headers };
    return this.http.post<StartProcessResponse>(`${this.apiOlimpiaUrl}/myBills/startProcess`, body, options);
  }

  // Paso 2
  previewPromissoryNote(request: any): Observable<ViewPromissoryNoteResponse> {
    const options = { headers: this.headers };

    this.promissoryNotePreviewService.updateExternalCustomerProcessInDataRequest();
    const requestPreviewDocument: ViewPromissoryNoteRequest = this.promissoryNotePreviewService.getDataRequest();

    return this.http.post<ViewPromissoryNoteResponse>(`${this.apiOlimpiaUrl}/myBills/viewPromissoryNote`, requestPreviewDocument, options);
  }

  // Paso 3
  issuePromissoryNote(body: IssuePromissoryNoteRequest): Observable<IssuePromissoryNoteResponse> {
    const options = { headers: this.headers };
    return this.http.post<IssuePromissoryNoteResponse>(`${this.apiOlimpiaUrl}/myBills/issuePromissoryNote`, body, options);
  }

  // Paso 4
  validateOtp(request: SignatureValidateOtpRequest): Observable<SignatureValidateOtpResponse> {
    const options = { headers: this.headers };
    return this.http.post<SignatureValidateOtpResponse>(`${this.apiOlimpiaUrl}/myBills/signatureValidateOTP`, request, options);
  }

  // Paso 4.5
  resendOtp(request: ResendOtpRequest): Observable<ResendOtpResponse> {
    const options = { headers: this.headers };
    return this.http.post<ResendOtpResponse>(`${this.apiOlimpiaUrl}/myBills/resendOTP`, request, options);
  }

}
