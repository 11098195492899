import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  public lastOpenModalName: string = '';

  public modal: any = {
    genericErrorMessage: false,
    modalStartProcessError: false,
    modalOtp: false,
    modalOtpSendOk: false,
    modalOtpSendError: false,
    modalValidateOtpError: false,
    modalResendOtpOk: false,
    modalResendOtpError: false,
    modalQuotaRequestApproved: false,
    modalQuotaRequestDenied: false,
    modalQuotaRequestInProcess: false,
    modalQuotaRequestInvalid: false,
    modalQuotaRequestError: false,
    modalAfiliateInfoError: false
  };

  public payload: any = {};

  constructor(
    // public store: Store<AppState>
  ) {

  }

  get propModal() {
    return this.modal;
  }

  get payloadModal() {
    return this.payload;
  }

  show(modalName?: string, payload?: any) {
    console.log(modalName);
    this.close();
    if (modalName) {
      // this.store.dispatch(setIsAnyModalOpen({openModal: { isOpen: true, modalName }}));
      this.modal[modalName] = true;
      this.lastOpenModalName = modalName;
      this.payload = payload;
      this.disabledScroll();
    }
  }

  close(data: any = null) {
    this.enableScroll();
    // this.store.dispatch(setIsAnyModalOpen({openModal: { isOpen: false, modalName: '' }}));
    for (const property in this.modal) {
      if (property && this.modal[property] === true) {
        this.modal[property] = false;
        this.modal[this.lastOpenModalName] = false;
        // console.log('close modal -->', property);
      }
    }
  }

  disabledScroll() {
    if (this.modal['myInformation'] === true) {
      const element = document.querySelector<HTMLElement>('body');
      element!.style.overflow = 'hidden';
    }
  }

  enableScroll() {
    const element = document.querySelector<HTMLElement>('body');
    element!.style.overflow = 'auto';
  }
}
