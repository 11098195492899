import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { QuotaRequestResponse } from '../models/quota-request.model';
import { SelectsResponse } from '../models/selects.model';
import { GetInfoResponse } from '../models/get-info.model';

@Injectable({
  providedIn: 'root'
})
export class SubjectPromissoryService {
  // Subject para reiniciar el input del código del OTP cuando se realiza un reenvio
  private resetInputsOfCodeOtp: Subject<boolean> = new Subject<boolean>();
  public resetInputsOfCodeOtp$ = this.resetInputsOfCodeOtp.asObservable();

  // BehaviorSubject que se utiliza para saber en que momento se debe de llamar el servicio de previsualizar documento (viewPromissoryNote)
  private callPreviewOfDocument: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public callPreviewOfDocument$ = this.callPreviewOfDocument.asObservable();

  // BehaviorSubject que se utiliza para saber en que momento se debe de llamar el servicio de emitir pagaré en el modal de OTP (IssuePromissoryNote)
  private callIssuePromissoryNote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public callIssuePromissoryNote$ = this.callIssuePromissoryNote.asObservable();

  // BehaviorSubject que se utiliza para almacenar la respuesta del servicio de solicitar cupo (pr_solicitud_cupo)
  private responseQuotaRequest: BehaviorSubject<QuotaRequestResponse> = new BehaviorSubject<QuotaRequestResponse>({} as QuotaRequestResponse);
  public responseQuotaRequest$ = this.responseQuotaRequest.asObservable();

  // BehaviorSubject que se utiliza para almacenar el error del servicio de solicitar cupo (pr_solicitud_cupo)
  private errorQuotaRequest: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorQuotaRequest$ = this.errorQuotaRequest.asObservable();

  // BehaviorSubject que se utiliza para almacenar listado de Ciudad(Departamento) del servicio (/inicializar`)
  private citiesResponse: BehaviorSubject<SelectsResponse> = new BehaviorSubject<SelectsResponse>({} as SelectsResponse);
  public citiesResponse$ = this.citiesResponse.asObservable();

  // BehaviorSubject que se utitiliza para almacenar la información extra que se debe de mostrar en el FAU
  private afiliateInfoResponse: BehaviorSubject<GetInfoResponse> = new BehaviorSubject<GetInfoResponse>({} as GetInfoResponse);
  public afiliateInfoResponse$ = this.afiliateInfoResponse.asObservable();

  constructor() { }

  forceResetInputsOfCodeOtp(newValue: boolean) {
    this.resetInputsOfCodeOtp.next(newValue);
  }

  setCallPreviewOfDocument(newValue: boolean) {
    this.callPreviewOfDocument.next(newValue);
  }

  setcallIssuePromissoryNote(newValue: boolean) {
    this.callIssuePromissoryNote.next(newValue);
  }

  setResponseQuotaRequest(newResponse: QuotaRequestResponse) {
    this.responseQuotaRequest.next(newResponse);
  }

  setErrorQuotaRequest(newValue: string) {
    this.errorQuotaRequest.next(newValue);
  }

  setCitiesResponse(newValue: SelectsResponse) {
    this.citiesResponse.next(newValue);
  }

  setAfiliateInfoResponse(newValue: GetInfoResponse) {
    this.afiliateInfoResponse.next(newValue)
  }

}
