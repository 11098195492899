import { Injectable } from '@angular/core';

export interface IDoc {
  letters: string;
  number_code: number;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class HelperDocumentsService {
  // CODES - Documentación oficial:
  // 104: Cedula de Ciudadanía
  // 106: NIT
  // 107: Cedula de Extranjería
  // 108: Tarjeta de Identidad
  // 109: Permiso Especial [PP, RUMV, PEP - Por el momento se asocian a estos]


  // NOTA: Los documentos que se manejan en el proyecto.
  private arrayDocuments: IDoc[] = [
    { letters: 'CC', number_code: 104, label: 'Cédula de ciudadanía' },
    { letters: 'CE', number_code: 107, label: 'Cédula extranjería' },
    { letters: 'PP', number_code: 109, label: 'Pasaporte' },
    { letters: 'RUMV', number_code: 109, label: 'Permiso por protección temporal' },
    { letters: 'PEP', number_code: 109, label: 'Permiso especial de permanencia' },
  ];

  constructor() { }

  findDocumentByLetters(letters: string): IDoc | undefined {
    return this.arrayDocuments.find((doc: IDoc) => doc.letters === letters);
  }

  findDocumentByNumberCode(number_code: number): IDoc | undefined {
    return this.arrayDocuments.find((doc: IDoc) => doc.number_code === number_code);
  }
}
