import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@app/services/analytics.service';
import { SubjectService } from '@app/services/subject.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-affiliate-quota',
  templateUrl: './affiliate-quota.component.html',
  styleUrls: ['./affiliate-quota.component.scss']
})
export class AffiliateQuotaComponent implements OnInit, AfterViewInit {
  public showXlForm: any = false;
  public stateStep: any;
  public stateTypePerson: any;

  constructor(
    private tabs: TabsService, 
    private subject: SubjectService,
    private analytics: AnalyticsService
  ) { 
    
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.analytics.trackWebEvent('LinkCupoHome_Ok');
    }, 3000);
  }

  ngOnInit() {
    this.tabs.isShowXL$.subscribe(resp => {
      
      this.showXlForm = resp;
    });
    this.subject.stateCurrentStep$.subscribe(resp => {
      this.stateStep = resp;
    });
    this.subject.stateTypePerson$.subscribe(resp => {
      this.stateTypePerson = resp;
    })
  }


  handleNext(step: number) {
  }

  handlePrev(step: number) {
  }

  handleGoToStep(step: number) {
  }

}
