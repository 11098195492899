// GLOBAL
export const IDENTIFICACION_COMERCIO: string = '860028462'; // Covinoc SA


// Start Process
export const ID_TIPO_ENDOSO: number = 0;
export const TIPO_PRODUCTO: string = 'pagare';


// Promissory-note-preview y Issue-promissory-note
export const REQUIERE_ATDP: boolean = false;
export const ID_TIPO_DOCUMENTO_PLANTILLA: number = 2; // 2 = FAUs y pagarés


// --- ELIMINAR ---
export const ID_PROCESO_CLIENTE_EXTERNO_TESTING: string = '121212';