import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  public files: File[] = [];
  public mb = 1024 * 1024;
  public maxFileSize = 7 * this.mb;
  public fileSelected: File;
  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();
  public subs1: Subscription;
  cancel =false;

  constructor(public toastr: ToastrService) {}

  // DropZone
  onSelect(event) {
    this.fileSelected = event.addedFiles[0];
    let selectedFilesSize = 0;
    let selectedAddedFiles = 0;
    if(event.rejectedFiles[0])
    
    if(event.rejectedFiles[0].size > this.maxFileSize) {
      this.toastr.error("El tamaño máximo permitido es de 7 megabytes (MB)", "");
    } else {
      this.toastr.error("Formato no válido", "")
    }

    event.addedFiles.forEach(file => {
      selectedAddedFiles += file.size;
    });

    this.files.forEach(file => {
      selectedFilesSize += file.size;
    });
    
    if(selectedAddedFiles + selectedFilesSize >= this.maxFileSize) {
      
      this.toastr.error("El tamaño máximo permitido es de 7 megabytes (MB)", "")
      return;
    }

    this.files.push(...event.addedFiles);    
    this.uploadFile();
  }

  onRemove(event, one: boolean) {
   if(one) {
      this.files.splice(this.files.indexOf(event), 1);
   } else {
     this.files = [];
   }
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.chargeResult.emit(this.files);
  }
  
}