import { Component, OnDestroy } from '@angular/core';
import { ModalsService } from '../utils/modals.service';
import { SubjectPromissoryService } from '../services/subject.service';

@Component({
  selector: 'app-modal-resend-otp-error',
  templateUrl: './modal-resend-otp-error.component.html',
  styleUrls: ['./modal-resend-otp-error.component.scss']
})
export class ModalResendOtpErrorComponent implements OnDestroy {

  public modalName: string = 'modalResendOtpError';

  constructor(
    private modals: ModalsService,
    private subjectService: SubjectPromissoryService
  ) {
    
  }
  ngOnDestroy(): void {
    this.subjectService.setcallIssuePromissoryNote(false);
    this.modals.show('modalOtp');
  }
}
