import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import * as _moment from 'moment';
import { environment } from 'src/environments/environment';
import { ClientStudyRequest } from '@app/models/clientStudyRequest.model';
import { ClientStudyNaturalModel } from '@app/models/clientStudyNaturalRequest.model';
import { map } from 'rxjs/operators';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable({
  providedIn: 'root'
})
export class BackEndService {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  // properties
  private urlApi: string;
  private urlApiBuyer: string;
  private tokenApi: string;

  public identity: any;
  public token: any;
  public DatosUsuario: any;
  public Headers: any;
  public tokenInfo: any;
  public codeInfo: any;
  public ip: any;
  public id_documento: Array<any> = [1,2,3,4];

  constructor(public http: HttpClient, @Inject(DOCUMENT) document: any) {
    this.urlApi = environment.urlApi;
    this.urlApiBuyer = environment.apiUrlBuyer;
    this.tokenApi = environment.apiNitToken;
    this.DatosUsuario = JSON.parse(localStorage.getItem('identityCVE'));
    this.tokenInfo = localStorage.getItem('CFCDToken');
    this.codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));
    /* this.codeInfo = {
      id_pais: "1",
      afiliado_tipo_documento: "N",
      afiliado_documento: "8605312875"
    }; */

    this.Headers = new HttpHeaders();
    this.Headers = this.Headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.Headers = this.Headers.set('token', localStorage.getItem('tokenCVE'));
  }

  getquery(query: string, afiliado: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('token', localStorage.getItem('tokenCVE'));
    this.DatosUsuario = JSON.parse(localStorage.getItem('identityCVE'));

    const options = { params: afiliado, headers };
    return this.http.get(`${this.urlApi}/${query}`, options);
  }

  validatePreviousRequest(userCredentials: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));

    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));

    const params = {
      id_pais: codeInfo.id_pais,
      tipo_documento: codeInfo.afiliado_tipo_documento,
      documento: codeInfo.afiliado_documento,
      comprador_tipo_documento: userCredentials.comprador_tipo_documento,
      comprador_documento: userCredentials.comprador_documento
    }

    const options = { headers, params: params };

    return this.http.get(`${this.urlApi}/pr_validar_solicitud`, options);
  }

  getLinkToCommerce() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));

    const params = {
      id_pais: codeInfo.id_pais,
      afiliado_tipo_documento: codeInfo.afiliado_tipo_documento,
      afiliado_documento: codeInfo.afiliado_documento
    }
  
    const options = { headers, params: params };
  
    return this.http.get(`${this.urlApi}/sitio_web_afiliado`, options);
  }

  validateCounterpartKnowledge(body: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));

    const params = {
      id_pais: codeInfo.id_pais,
      tipo_documento: codeInfo.afiliado_tipo_documento,
      documento: codeInfo.afiliado_documento
    }

    const options = { params, headers };

    return this.http.post(`${this.urlApi}/pr_conocimiento_contraparte`, body, options);
  }

  generateOtpCode(body: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));

    const params = {
      id_pais: codeInfo.id_pais,
      tipo_documento: codeInfo.afiliado_tipo_documento,
      documento: codeInfo.afiliado_documento
    }

    const options = { params, headers };

    return this.http.post(`${this.urlApi}/pr_generar_otp`, body, options);
  }
  
/*   validateOtpCode(body: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));

    const params = {
      id_pais: codeInfo.id_pais,
      tipo_documento: codeInfo.afiliado_tipo_documento,
      documento: codeInfo.afiliado_documento
    }

    const options = { params, headers };

    return this.http.post(`${this.urlApi}/buyerInt/pr_validar_otp`, body, options);
  } */

  validateOtpCode(body: any) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenApi)
    });
    const options = { headers };

    return this.http.post(`${this.urlApiBuyer}/validar_otp`, body, options);
  }

  digitalSign(body: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));

    const params = {
      id_pais: codeInfo.id_pais,
      tipo_documento: codeInfo.afiliado_tipo_documento,
      documento: codeInfo.afiliado_documento
    }

    const options = { params, headers };

    return this.http.post(`${this.urlApi}/pr_doc_firma_digital`, body, options);
  }

  getFacturaPdf(params: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('Authorization', 'Q0xJRU5UX0FQUC0zODQ0MWVkMjozMGJBc2lsZWlBQDA=');
    const url = 'http://200.41.76.26:8080/CoviLiquidaWeb/servicios';
    const mediaType = 'application/pdf';
    const options = { params, headers };
    return this.http.get(`${url}/pdf_netsuite_factura`, options);
  }

  getUserIpAddress() {
    return this.http.get('https://api.ipify.org?format=json').pipe(
      map((data: any) => {
        localStorage.setItem('ipAddress', data.ip);
      },
        error => {
          console.error('Error al obtener la IP:', error);
        }
      ));
  }

  getAffiliateCode(code: string) {
    const userIp = localStorage.getItem('ipAddress')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const data = {
      id_pais: code ? code.substr(0, 1) : '',
      afiliado_tipo_documento: code ? code.substr(1, 1) : '',
      afiliado_documento: code ? code.substr(2) : '',
      ip: userIp
    }
  
    const options = { headers, params: data };
  
    return this.http.get(`${this.urlApi}/token_link_afiliado`, options);
  }

  solicitudCupo(data: any, body?: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    headers = headers.set('token', localStorage.getItem('CFCDToken'));
  
    const options = { headers, params: data };
  
    return this.http.post(`${this.urlApi}/pr_solicitud_cupo`, body, options);
  }

  parseRequestBody(body: ClientStudyRequest | ClientStudyNaturalModel) {
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));
    
    const data = {
      tipo_documento: codeInfo.afiliado_tipo_documento, 
      documento: codeInfo.afiliado_documento,
      id_pais: codeInfo.id_pais,
    }

    body.comprador_tipo_documento = (body.comprador_tipo_documento !== 'C' && body.comprador_tipo_documento !== 'E') ? 'N' : body.comprador_tipo_documento;
    
    body.usuario = `${data.id_pais}${data.tipo_documento}${data.documento}`,
    body.id_pais = data.id_pais,
    body.tipo_documento = data.tipo_documento,
    body.documento = data.documento,


    body.fecha_creacion = moment().format('YYYYMMDD');
    //body.fecha_expedicion = ;
    //body.fecha_nacimiento = moment(body.fecha_nacimiento).format('YYYYMMDD');
    //body.StrJArray = [{tipo_documento_socio:"C", documento_socio:"1088342566", nom1_socio:"HECTOR", nom2_socio:"FRANCISCO", apel1_socio:"MARIN", apel2_socio:"SOTO"}];
    console.log(body);
    
    const bodySolicitud = {
      StrJArray: JSON.stringify(this.parsePartnerInfo(body.StrJArray)),
      usuario: body.usuario,
      id_pais: body.id_pais,
      tipo_documento: body.tipo_documento,
      documento: body.documento,
      comprador_tipo_documento: body.comprador_tipo_documento,
      comprador_documento: body.comprador_documento,
      nombre_empresa: body.nombre_empresa,
      tipo_doc_rep: body.tipo_doc_rep,
      doc_rep: body.doc_rep,
      nombres_rep: body.nombres_rep,
      apel1_rep: body.apel1_rep,
      apel2_rep: body.apel2_rep,
      fecha_creacion: body.fecha_creacion,
      canal_whatsapp: body.canal_whatsapp,
      canal_correo: body.canal_correo,
      canal_sms: body.canal_sms,
      canal_llamada: body.canal_llamada,
      tipo_empresa: body.tipo_empresa,
      fecha_expedicion: moment(body.fecha_expedicion).format('YYYYMMDD'),
      lista_municipio_expedicion: body.lista_municipio,
      celular: body.celular,
      correo: body.correo,
      fecha_nacimiento: (body.comprador_tipo_documento !== 'C' && body.comprador_tipo_documento !== 'E') ? ("19970216") : (moment(body.fecha_nacimiento).format('YYYYMMDD') || "19970216"),
      direccion: body.direccion,
      telefono: body.telefono || '0',
      valor_cupo: body.valor_cupo,
      

      tipo_ref_1: body.tipo_ref_1,
      nombre1_ref_1: body.nombre1_ref_1,
      nombre2_ref_1: body.nombre2_ref_1 || '',
      apellido1_ref_1: body.apellido1_ref_1,
      apellido2_ref_1: body.apellido2_ref_1 || '',
      telefono_ref_1: body.telefono_ref_1,
      correo_ref_1: body.correo_ref_1,
      lista_cuidad_ref_1: body.lista_cuidad_ref_1,

      tipo_ref_2: body.tipo_ref_2,
      nombre1_ref_2: body.nombre1_ref_2,
      nombre2_ref_2: body.nombre2_ref_2,
      apellido1_ref_2: body.apellido1_ref_2,
      apellido2_ref_2: body.apellido2_ref_2,
      telefono_ref_2: body.telefono_ref_2 || '0',
      correo_ref_2: body.correo_ref_2,
      lista_cuidad_ref_2: body.lista_cuidad_ref_2 || '0',

      nom1_contacto: body.nom1_contacto,
      nom2_contacto: body.nom2_contacto || '',
      apel1_contacto: body.apel1_contacto || '',
      apel2_contacto: body.apel2_contacto || '',
      cel_contacto: body.cel_contacto,
      cargo_contacto: body.cargo_contacto,
      correo_contacto: body.correo_contacto,
      lista_municipio: body.lista_municipio,
    }

    if(bodySolicitud.nombre1_ref_2 === '') {
      bodySolicitud.tipo_ref_2 === ''
    }

    if(bodySolicitud.comprador_tipo_documento === 'C') {
      delete bodySolicitud.StrJArray;
    }
    
    if(bodySolicitud.fecha_nacimiento === 'Invalid date') {
      bodySolicitud.fecha_nacimiento = "19970216";
    }
    

    /* const bodyRequest = {...body} */
    return {...bodySolicitud};
  }

  parsePartnerInfo(partner: any) {
    if(partner.legth === 1) {
      if(partner[0].documento_socio === '') {
        return [];
      } else {
        return partner
      }
    } else {
      return partner;
    }
  }



  parseCounterpartKnowledge(bodyRequest: ClientStudyRequest | ClientStudyNaturalModel, requestPayload: any ) {
    const body: any = {
      recursos_publicos: bodyRequest.recursos_publicos,
      tipo_documento_comprador: bodyRequest.comprador_tipo_documento,
      documento_comprador: bodyRequest.comprador_documento,
      radicacion: requestPayload["ESTUDIO_CUPO "],
      reconocimiento_politico: bodyRequest.reconocimiento_politico,
      poder_publico: bodyRequest.poder_publico,
      administradores_PEP: this.parsePep(bodyRequest.administradores_PEP),
      vinculos_PEP: this.parsePep(bodyRequest.vinculos_PEP)
    };
    
    if(bodyRequest.transacciones_extranjeria.tipo_operacion !== "") {
      body.transacciones_extranjeria = bodyRequest.transacciones_extranjeria
    }

    return body;
  }

  parsePep(pep: any) {
    if(pep.length === 1) {
      if(pep[0].nombres === '' && pep[0].tipo_documento === '' && pep[0].documento === '') {        
        return [];
      } else {
        return this.parseBlankSpaces(pep);
      }
    } else {
      return this.parseBlankSpaces(pep);
    }
  }

  parseBlankSpaces(pep) {
    pep.forEach(pep => pep.nombres = pep.nombres.split(' ').filter(n => n).join(' '));

    return pep;
  }

  parseArrayToString() {

  }

  /* http://200.41.76.20:8080/ServiciosCovifactura/servicios/pr_exportar_documento_legal?tipo_documento=N&documento=8600284621&id_pais=1&id_documento=1&aplicativo=LINK_AFILIADO */
  getTermsAndConditions(code: string): any {
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));
    let headers = new HttpHeaders();
    
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));

    let data = {};

    if(codeInfo) {
      data = {
        tipo_documento: codeInfo.afiliado_tipo_documento, 
        documento: codeInfo.afiliado_documento,
        id_pais: codeInfo.id_pais,
        id_documento: code,
        aplicativo: 'LINK_AFILIADO'
      }
    }
    const httpOptions: any = {observe: 'response', params: data, headers, responseType: 'text'}
    
    return this.http.get(`${this.urlApi}/pr_exportar_documento_legal`, httpOptions);
  
   /*  const data = {
      tipo_identificacion: code,
    }; */
  }

  getAcceptTerms(userData: any) {
    const today = moment().format("YYYYMMDDHHmmss");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('token', localStorage.getItem('CFCDToken'));
    const codeInfo = JSON.parse(localStorage.getItem('CFCDstateCode'));
    
    const data = {
      id_pais: codeInfo.id_pais,
      tipo_documento: codeInfo.afiliado_tipo_documento, 
      documento: codeInfo.afiliado_documento,
      usuario: `${codeInfo.id_pais}${codeInfo.afiliado_tipo_documento}${codeInfo.afiliado_documento}`,
      tipo_documento_usuario: userData.typeDocument,
      documento_usuario: userData.document,
      id_documento: JSON.stringify(this.id_documento),
      ip: '',
      fecha_ingreso: today,
      pagina: document.location.href,
      aplicativo: 'LINK_AFILIADO'
    }
  
    const options = { headers, params: data };
  
    return this.http.get(`${this.urlApi}/pr_aceptacion_documentos_legales`, options);
  }

  /* getIpAddress() {
    return this.http.get("https://api.ipify.org/?format=json");  
  }

  getIP() {  
    this.getIpAddress().subscribe((res:any)=>{  
      this.ip = res.ip;  
    });  
  }   */

  GetReporteCartera(afiliado: object) {
    return this.http.get('pr_reporte_cartera_afiliado', afiliado);
  }

  GetReporteDeudores(afiliado: object) {
    return this.getquery('pr_reporte_deudores_afiliado', afiliado);
  }

  // Cambiar Password
  CambiarPassword(nuevo: any) {
    return this.getquery('pr_buscar', nuevo);
  }


}
