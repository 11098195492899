import { Component, OnInit, HostListener, DoCheck } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { filter } from 'rxjs/operators';
import { BackEndService } from './services/back-end.service';
import { ModalService } from './services/modal.service';
import { SubjectService } from './services/subject.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare let $: any;
declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, DoCheck {
  public identity: any;
  public currentRoute: string;
  public idUser: any;
  public enviroment: string = '';

  navigation = [
    { alt: 'Transacciones', img: 'icon-transactions.svg', path: '/transacciones' },
    { alt: 'Estudio de cliente', img: 'icon-client-study.svg', path: '/estudio-de-cliente' },
    { alt: 'Operaciones', img: 'icon-operations.svg', path: '/operaciones' },
    { alt: 'Reintegros', img: 'icon-claims.svg', path: '/reintegros' },
    // { alt: 'Reclamaciones', img: 'icon-claims.svg', path: '/reclamaciones' },
    { alt: 'Acuerdos de pago', img: 'icon-agreements.svg', path: '/acuerdos-de-pago' },
    { alt: 'Reportes', img: 'icon-reports.svg', path: '/reportes' },
    { alt: 'Administración', img: 'icon-administration.svg', path: '/administracion' },
  ];

  public analytics: any;
  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  constructor(public router: Router, private backendService: BackEndService, private modal: ModalService, private route: ActivatedRoute, private subject: SubjectService, private spinner: NgxSpinnerService) {
    // Analytics
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      this.currentRoute = event.url;
      gtag('config', '', {
        page_path: event.urlAfterRedirects
      });
    });
  }

  ngOnInit() {
    this.analytics = JSON.parse(localStorage.getItem('identity')) || {};
    this.enviroment = environment.production === true ? 'Prod ->' : 'Dev ->';
    console.info( this.enviroment, new Date(2024, 4, 9, 12), '---> Ajuste de campos en PDF del FAU (nuevo template), ajuste css en padding de los selects, capitalizado el tipo de persona mobile y ajuste de contenedor de iframe para pantallas grandes'); // Version
    this.subject.changeStateInvalidDataError(false);
    this.tokenVerify();
    this.backendService.getUserIpAddress().subscribe();
  }

  getTerms() {
    this.backendService.getTermsAndConditions('1').subscribe((resp: any) => {
      this.subject.changestateTermsAndConditions(resp.body);
    });

    this.backendService.getTermsAndConditions('2').subscribe((resp: any) => {
      this.subject.changeStateInformationOperators(resp.body);
    });

    this.backendService.getTermsAndConditions('3').subscribe((resp: any) => {
      this.subject.changeStateSourceFunds(resp.body);
    });

    this.backendService.getTermsAndConditions('4').subscribe((resp: any) => {
      this.subject.changeStateElectronicSign(resp.body);
    });
  }


  ngDoCheck() {
    this.identity = JSON.parse(localStorage.getItem('identity')) || {};
    this.analytics = this.identity;
  }

  tokenVerify() {
    this.spinner.show();
    const userIp = localStorage.getItem('ipAddress');
    this.route.queryParams.pipe(
      filter(params => params.codigo)
    )
    .subscribe((params) => {
      this.idUser = params.codigo;
      this.backendService.getAffiliateCode(this.idUser).subscribe((resp: any) => {
        const data = {
          id_pais: this.idUser.substr(0, 1),
          afiliado_tipo_documento: this.idUser.substr(1, 1),
          afiliado_documento: this.idUser.substr(2),
          ip: userIp
        }
        
        localStorage.setItem('CFCDstateCode', JSON.stringify(data)); 
        localStorage.setItem('CFCDToken', resp.TOKEN);

        this.backendService.getLinkToCommerce().subscribe((resp: any) => {
          localStorage.setItem('CFCDUrlCommerce', resp.URL_AFILIADO);
        })

        this.getTerms()

      }, (error) => {
        this.subject.changeStateInvalidDataError(false);
        this.modal.show('errorMessage');
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      })
    });
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  noAuth() {
    const authRoutes = ['/login', '/forgot'];
    return authRoutes.includes(this.currentRoute);
  }

  logOut() {
    (window as any).gtag('event', 'cerrar_sesion',
      { event_category: `${this.analytics.tipo_documento} ${this.analytics.documento}`, event_label: '' });
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}

