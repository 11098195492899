export const environment = {
  app: 'CoviFactura',
  
  // Antiguos datos del ambiente de desarrollo de Olimpia (Toca utilizar producción ya que Olimpia deshabilitó el ambiente de desarrollo de ellos)
  // apiOlimpiaUrlAuth: 'CLIENT_APP-19921ef5:42bJskuidB@0',
  // apiOlimpiaUrl: 'https://desarrollo07.c0v.co/coviolimpiaintegrationservice/api/v1',
  // idTemplateFAU: '47f5f78e-54ad-4966-97dc-38509ccdfe8f',
  
  apiOlimpiaUrlAuth: 'CLIENT_APP-19921ef5:23bHloreqW@7',
  apiOlimpiaUrl: 'https://wsbolp.c0v.co/olimpia/api/v1',
  idTemplateFAU: 'c4ca3bbc-f721-4a8b-8e73-4531788d7e94',
  urlApi: 'https://desarrollo11.c0v.co/api/covifacturapi/servicios',
  apiUrl: 'https://dev.node.covi.pw/respaldos/api/v1',
  apiUrlNit: 'https://admincovi.c0v.co',
  apiUrlBuyer: 'https://dev.node.covi.pw/buyerInt',
  apiNitToken: 'CLIENT_APP-312128avrut:aVesqt325@mqs',
  apiUrlPayzen: 'http://200.41.76.26:8080/PayzenCorporativo/servicios',
  urlEndpoint: 'https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos',
  urlApiAnalytics: 'http://192.168.13.60:8585/api/v1',
  name: 'dev',
  urlCovifactura: 'http://desarrollo.covinoc.com:8088/compradores/login',
  production: false,
};