import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContrapartKnowledgePerson } from '@app/models/clientStudyNaturalRequest.model';
import { HelperService } from '@app/services/helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-counterpart-knowledge',
  templateUrl: './counterpart-knowledge.component.html',
  styleUrls: ['./counterpart-knowledge.component.scss']
})
export class CounterpartKnowledgeComponent implements OnInit {

  @Input() person: ContrapartKnowledgePerson[];
  @Input() documentTypesOptions: Array<any>;
  @Output() testData: EventEmitter<any> = new EventEmitter<any>();

  @Output() formCounterPartPersonValidate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('formCounterPartPerson') formCounterPartPerson: NgForm;

  public validatePatternDocument: RegExp[] = [];

  constructor(private toastr: ToastrService, public helper: HelperService) { }

  ngOnInit() {
    this.person[0].tipo_documento = 'C';
  }

  addPerson() {

    const person = {
      nombres: '',
      tipo_documento: 'C',
      documento: '',
      cotiza_bolsa: '',
    }
    

    if (this.person.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 elementos.", null);
      return;
    }

    this.person.push(person);
    this.handleValidateCounterPartPersonValidate();
  }

  deletePerson(id: number) {
    const person = {
      nombres: '',
      tipo_documento: '',
      documento: '',
      cotiza_bolsa: '',
    }
    
    if (this.person.length > 1) {
      this.person.splice((this.person.length - 1), 1);;
    } else {
      
      this.person[id].nombres = ''
      this.person[id].tipo_documento = ''
      this.person[id].documento = ''
      this.person[id].cotiza_bolsa = ''
    }

    this.handleValidateCounterPartPersonValidate();
  }

  handleValidateCounterPartPersonValidate(documentType?: string, idx?: number) {
    if (documentType && idx>=0 ) {
      this.validatePatternDocument[idx] = this.helper.validatePatternDocument(documentType);
    }
    
    setTimeout(() => {
      const valid = this.formCounterPartPerson.form.valid;
      this.formCounterPartPersonValidate.emit(valid);
    }, 1);
  }

}
