import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { City } from '@app/models/city.model';
import { ClientStudyNaturalModel } from '@app/models/clientStudyNaturalRequest.model';
import { ClientStudyRequest } from '@app/models/clientStudyRequest.model';
import { HelperService } from '@app/services/helper.service';

@Component({
  selector: 'app-foreign-currency',
  templateUrl: './foreign-currency.component.html',
  styleUrls: ['./foreign-currency.component.scss']
})
export class ForeignCurrencyComponent implements OnInit {

  public otherTypeAccount: string = '';
  public otherTypeOperation: string = '';

  @Input() clientStudyForm: ClientStudyRequest | ClientStudyNaturalModel;
  @Input() homeCities: Array<City>;

  @ViewChild('formForeignCurrency') formForeignCurrency: NgForm;
  @Output() formForeignCurrencyValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public helper: HelperService) { }

  ngOnInit() {
    const typeProduct = this.clientStudyForm.transacciones_extranjeria.descripcion.tipo_producto;
    if(typeProduct !== 'ahorros' && typeProduct !== 'corriente' && typeProduct !== '') {
      this.otherTypeAccount = typeProduct;
    }
    const operationType = this.clientStudyForm.transacciones_extranjeria.tipo_operacion;
    if(operationType !== 'transferencia' && operationType !== 'inversion' && operationType !== 'exportaciones' && operationType !== 'importaciones' && operationType !== '') {
      this.otherTypeOperation = operationType;
    }

  }

  changeToOtherTypeAccount() {
    this.clientStudyForm.transacciones_extranjeria.descripcion.tipo_producto = this.otherTypeAccount;
  }
  
  changeToOtherTypeOperation() {
    this.clientStudyForm.transacciones_extranjeria.tipo_operacion = this.otherTypeOperation;
  }

  changeToTypeAcount() {
    this.otherTypeAccount = '';
  }

  changeToTypeOperation() {
    this.otherTypeOperation = '';
  }

  changeTypeAccount(typeAccount) {
    this.clientStudyForm.transacciones_extranjeria.descripcion.tipo_producto = typeAccount
  }

  changeOperationType(OperationType: string) {
    this.clientStudyForm.transacciones_extranjeria.tipo_operacion = OperationType;
  }

  handleValidate() {
    const valid = this.formForeignCurrency.form.valid;
    this.formForeignCurrencyValid.emit(valid);
  }

}
