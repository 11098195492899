import { ElementRef, HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsWindowService {


  public minimunHeigth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private minimunHeigth: Observable<boolean> = this.minimunHeigth$.asObservable();

  private inputIsFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public inputIsFocused$: Observable<boolean> = this.inputIsFocused.asObservable();

  private scrollToTop: Subject<number> = new Subject<number>();
  public scrollToTop$: Observable<number> = this.scrollToTop.asObservable();

  // Declare height and width variables
  public scrHeight!: number;
  public scrWidth!: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    const value = this.scrHeight > 695 ? true : false;
    this.minimunHeigth$.next(value);
  };

  @HostListener('click', ['"hello!"', '$event']) onMouseEnter(greeting: string, event: any) { }


  constructor() { }

  setInputFocus(value: boolean) {
    setTimeout(() => {
      this.inputIsFocused.next(value);
    }, 1000);
  }

  setScrollToTop(position: number) {
    this.scrollToTop.next(position);
  }

  scrollToTopWindow(top?: number, left?: number) {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollTotopElement(element: ElementRef) {
    if (!element) return;
    element.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  getBrowser(): string {
    let userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      return "chrome";
    }

    if (userAgent.match(/firefox|fxios/i)) {
      return "firefox";
    }

    if (userAgent.match(/safari/i)) {
      return "safari";
    }

    if (userAgent.match(/opr\//i)) {
      return "opera";
    }

    if (userAgent.match(/edg/i)) {
      return "edge";
    }

    return "No browser detection";

  }

  getUA(): string {
    let device = "Unknown";
    const ua: any = {
      "Generic Linux": /Linux/i, "Android": /Android/i, "BlackBerry": /BlackBerry/i,
      "Bluebird": /EF500/i, "Chrome OS": /CrOS/i, "Datalogic": /DL-AXIS/i,
      "Honeywell": /CT50/i, "iPad": /iPad/i, "iPhone": /iPhone/i, "iPod": /iPod/i,
      "macOS": /Macintosh/i, "Windows": /IEMobile|Windows/i, "Zebra": /TC70|TC55/i,
    }
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
  }

  getIp(): string {
    let currentUrl: string = document.location.protocol + "//" + document.location.hostname + ":" + document.location.port;
    // console.log(currentUrl);
    return currentUrl;
  }
}
