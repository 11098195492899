export class EmailMassiveTransactionRequest {
  idContry: string;
  documentType: string;
  documentNumber: string;
  userName: string;

  public static toRequest(
    emailMassiveTransactionRequest: EmailMassiveTransactionRequest
  ): any {
    return {
      id_pais: emailMassiveTransactionRequest.idContry,
      tipo_documento: emailMassiveTransactionRequest.documentType,
      documento: emailMassiveTransactionRequest.documentNumber,
      usuario: emailMassiveTransactionRequest.userName,
    };
  }
}
