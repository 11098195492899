import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-otp-send-error',
  templateUrl: './modal-otp-send-error.component.html',
  styleUrls: ['./modal-otp-send-error.component.scss']
})
export class ModalOtpSendErrorComponent {


  public modalName: string = 'modalOtpSendError';

  constructor() {
  }

}
