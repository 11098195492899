import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  // Properties
  formPass: FormGroup;
  userForm = {
    username: '',
  };

  // Toggles
  public toggleFormPass = true;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.setForm();
  }

  ngOnInit() {}

  setForm() {
    this.formPass = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
    this.formPass.controls['username'].setValidators([Validators.required]);

    this.formPass.setValue(this.userForm);
  }

  recoverPassword() {
    this.spinner.show();

    this.auth.recoverPassword(this.formPass.value.username).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.toggleFormPass = false;
      },
      (err) => {
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Error:',
          text: 'Intente mas tarde',
        });
      }
    );
  }

  pass() {
    this.router.navigateByUrl('pages/transacciones');
  }

  // Swal.fire({
  //   type: 'error',
  //   title: 'Error al cambiar contraseña',
  //   text: 'Error de contraseña'
  //   });
}
