import { Inject, Injectable } from '@angular/core';

export type KeyStorageType = 'idProcesoClienteExterno' | 'transaccionId' | 'dataAdapter' | 'preDataAdapater';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public useSessionStorage: boolean = false;
  public selectedStorage!: Storage;

  constructor(@Inject(Boolean) useSession?: boolean) {
    this.handleUseSessionStorage((useSession) || false);
  }

  handleUseSessionStorage(useSession: boolean) {
    this.useSessionStorage = useSession || this.useSessionStorage;
    this.selectedStorage = this.useSessionStorage ? sessionStorage : localStorage;
  }

  setStorage(key: KeyStorageType, value: any, returnValue?: boolean): void | any {
    this.selectedStorage.setItem(key, JSON.stringify(value));
    return returnValue && this.getStorage(key);
  }

  getStorage(key: KeyStorageType): JSON | any {
    const itemOfStorage = this.selectedStorage.getItem(key);

    if (!itemOfStorage) {
      return null;
    }

    try {
      const parsedItem = JSON.parse(itemOfStorage);
      return parsedItem;
    } catch (error) {
      return itemOfStorage;
    }
  }

  getStorageOld(key: string): JSON | any {
    return JSON.parse(this.selectedStorage.getItem(key) || '{}');
  }

  clearStorage() {
    this.selectedStorage.clear();
  }
}