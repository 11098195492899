import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalyticsService } from '@app/services/analytics.service';
import { StorageService } from '../services/storage.service';
import { ModalsService } from '../utils/modals.service';
import { OlimpiaService } from '../services/olimpia.service';
import { SubjectPromissoryService } from '../services/subject.service';
import { ResendOtpRequest, ResendOtpResponse } from '../models/resend-otp.model';



@Component({
  selector: 'app-otp-resend',
  templateUrl: './otp-resend.component.html',
  styleUrls: ['./otp-resend.component.scss']
})
export class OtpResendComponent {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private storage: StorageService = new StorageService(true);

  constructor(
    private spinner: NgxSpinnerService,
    private modals: ModalsService,
    private olimpiaService: OlimpiaService,
    private subjectService: SubjectPromissoryService,
    private analytics: AnalyticsService
  ) {

  }

  ngOnInit(): void {
    // this.store.select('resendOtp').pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(this.handleResendOtpSubs.bind(this));
  }

  resendOtp() {
    this.spinner.show('resendingOtp');
    const request: ResendOtpRequest = this.buildRequestToResendOtp();
    this.olimpiaService.resendOtp(request).subscribe(({
      next: (response: ResendOtpResponse) => {
        // this.modals.show('otpResendOk');
        this.spinner.hide('resendingOtp');
        this.subjectService.forceResetInputsOfCodeOtp(true);
        this.modals.show('modalResendOtpOk');
        this.analytics.trackWebEvent('LinkCupoReenviarOtp_Ok');
      },
      error: (error: any) => {
        console.error('Ocurrió un error al reenviar el código OTP: ', error);
        // this.modals.show('otpResendError');
        this.spinner.hide('resendingOtp');
        this.modals.show('modalResendOtpError');
        this.analytics.trackWebEvent('LinkCupoReenviarOtp_Error');
      },
    }));
  }

  buildRequestToResendOtp(): ResendOtpRequest {
    const transaccionId: number = this.storage.getStorage('transaccionId') || ''; // Debe ser obtenido de la respuesta del servicio de issuePromissoryNote
    const numeroCelular: string = this.storage.getStorage('preDataAdapater')["celular"] || '-1';
    const request: ResendOtpRequest = { transaccionId, numeroCelular };
    return request;
  }

}
