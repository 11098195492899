import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-quota-request-approved',
  templateUrl: './modal-quota-request-approved.component.html',
  styleUrls: ['./modal-quota-request-approved.component.scss']
})
export class ModalQuotaRequestApprovedComponent {

  public modalName: string = 'modalQuotaRequestApproved';

  constructor() {
    
  }

  handleClick() {
    window.open(environment.urlCovifactura, '_blank');
  }
}
