import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-quota-request-in-process',
  templateUrl: './modal-quota-request-in-process.component.html',
  styleUrls: ['./modal-quota-request-in-process.component.scss']
})
export class ModalQuotaRequestInProcessComponent {

  public modalName: string = 'modalQuotaRequestInProcess';

  constructor() {
    
  }

  handleClick() {
    window.location.reload();
  }

}
