export class ClientStudyResponse {
  responseType: number;
  approvedQuota?: number;
  errorModalText?: string;
  code?: string;

  public static fromResponse(json: any): ClientStudyResponse {
    const clientStudyResponse: ClientStudyResponse = {
      responseType: json['RAZON '] ? parseInt(json['RAZON ']) : 3,
      approvedQuota: json['ESTUDIO_CUPO '] ? parseFloat(json['CUPO']) : 0,
      errorModalText: json['MENSAJE_EXTERNO ']
        ? decodeURIComponent(escape(json['MENSAJE_EXTERNO ']))
        : '',
      code: json['ESTUDIO_CUPO '] || '',
    };
    return clientStudyResponse;
  }
}
