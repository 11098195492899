import {
  Component,
  OnInit,
  Output,
  EventEmitter} from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ModalService } from "@app/services/modal.service";

@Component({
  selector: "modal-success-validation",
  templateUrl: "./modal-success-validation.html",
  styleUrls: ["./modal-success-validation.scss"]
})
export class ModalSuccessValidationComponent implements OnInit {
  public url: SafeResourceUrl;
  public titleModal: any = "Validación exitosa";

  @Output() continueButton: EventEmitter<any> = new EventEmitter<any>();

  constructor(public modal: ModalService) {}

  openModal(nameModal: string){
    this.modal.show(nameModal, {title: this.titleModal});
  }

  ngOnInit() {}

  buttonClick(){
    this.continueButton.emit();
  }
}
