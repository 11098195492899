import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContrapartKnowledgePerson } from '@app/models/clientStudyNaturalRequest.model';
import { HelperService } from '@app/services/helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-counterpart-knowledge-pep',
  templateUrl: './counterpart-knowledge-pep.component.html',
  styleUrls: ['./counterpart-knowledge-pep.component.scss']
})
export class CounterpartKnowledgePepComponent implements OnInit {

  @Input() person: ContrapartKnowledgePerson[];
  @Input() documentTypesOptions: Array<any>;
  @Output() testData: EventEmitter<any> = new EventEmitter<any>();

  @Output() formPepFirstValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formPepSecondValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formPepThirdValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formPepFourthValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formPepFifthValidate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('formPepFirst') formPepFirst;
  @ViewChild('formPepSecond') formPepSecond;
  @ViewChild('formPepThird') formPepThird;
  @ViewChild('formPepFourth') formPepFourth;
  @ViewChild('formPepFifth') formPepFifth;

  public validatePatternDocument;
  public document_type: string;

  constructor(private toastr: ToastrService, public helper: HelperService) { }

  ngOnInit() {
    this.person[0].tipo_documento = 'C';
    this.validatePatternDocument = this.helper.validatePatternDocument('C');
  }

  addPerson() {

    const person = {
      nombres: '',
      tipo_documento: '',
      documento: '',
      cotiza_bolsa: '',
    }

    if (this.person.length >= 5) {
      this.toastr.info("Puede agregar máximo 5 elementos.", null);
      return;
    }

    this.person.push(person);
    this.testData.emit();
  }

  deletePerson(id: number) {
    const person = {
      nombres: '',
      tipo_documento: '',
      documento: '',
      cotiza_bolsa: '',
    }
    
    if (id === 0 && this.person.length > 0) {
      this.person[0] = person;
      return;
    }

    this.person.splice(id, 1);
  }
  handleValidateCounterPartPersonValidate() {
    this.validatePatternDocument = this.helper.validatePatternDocument(this.document_type);
  }

}
